/*
 *   File : signup.js
 *   Author : https://evoqins.com
 *   Description : Signup Container
 *   Version : 1.0.0
*/

// import packages
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom/dist";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

// import components
import { CustomHeader } from "../../Components/Navigation";
import { CustomPINInput, CustomPhoneInput } from "../../Components/FormElements";
import { AuthSlider } from "../../Components/Slider";
import { GmailLogin, PrimaryButton } from "../../Components/Buttons";
import { Icon } from "../../Components/Icon";

// import helper
import { _getMaskedNumber, _maskEmail, useCountdown } from "../../Helper/helper";
import APIService from "../../Services/api-service";
import SocialSignup from "./social-signup";
import { Modal } from "bootstrap";
import { EmailConfirmationModal } from "../../Components/Modal";
import store from "../../Store";


const Signup = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { seconds, resetCountdown } = useCountdown(30);

    const [phone, setPhone] = useState("");
    const [phoneError, setPhoneError] = useState("");

    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");

    const [signupStep, setSignupStep] = useState(1);

    const [apiLoader, setApiLoader] = useState(false);
    const [email, setEmail] = useState("");

    const [name, setName] = useState("");


    const [refreshToken, setRefreshToken] = useState("");

    const [flow, setFlow] = useState(null);

    const [isMobileVerified, setIsMobileVerified] = useState(false);
    const [isEmailVerified, setIsEmailVerified] = useState(null)

    const [emailVerified, setEmailVerified] = useState(false);

    useEffect(() => {

        const handleNavigation = () => {
            const modalElement = document.querySelector('.modal');
            const modalBackdrop = document.querySelector('.modal-backdrop');

            if (modalElement) {
                // Hide or remove the modal
                modalElement.style.display = 'none'; // You can also use `modalElement.remove();` if you want to completely remove it
            }

            if (modalBackdrop) {
                modalBackdrop.remove();
            }

            document.body.removeAttribute('style');
            document.body.removeAttribute('class');
        };

        handleNavigation();

        // Add event listener for the 'popstate' event
        window.addEventListener('popstate', handleNavigation);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('popstate', handleNavigation);
        };

    }, []);

    useEffect(() => {

        const handleNavigation = () => {
            navigate("/")
        };

        handleNavigation();

        // Add event listener for the 'popstate' event
        window.addEventListener('reload', handleNavigation);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('reload', handleNavigation);
        };
        // eslint-disable-next-line
    }, []);



    useEffect(() => {
        if (location.state != null) {
            if (location.state.phone_number) {
                setPhone(location.state.phone_number);
            }
            setSignupStep(location.state.step);
            if (location.state.email) {
                setEmail(location.state.email);
            }
            if (location.state.name) {
                setName(location.state.name);
            }
        }
        else {
            setSignupStep(1)
        }
    }, [location.state]);

    useEffect(() => {
        if (signupStep === 2) {
            resetCountdown();
        }
        // eslint-disable-next-line
    }, [signupStep]);

    useEffect(() => {
        if (emailVerified === true) {
            const modal = new Modal(document.getElementById("email-confirmation"));
            modal.show();
        }
    }, [emailVerified])

    // phone value handler
    function _handlePhoneNumber(input_value) {
        setPhone(input_value);
        setPhoneError("");
    }

    // otp value handler
    function _handleOTP(input_value) {
        if (input_value.length <= 4) {
            setOtp(input_value);
        }
        setOtpError("");
    }

    // phone validation 
    function _handleLogin() {
        let valid = true;
        if (phone === "") {
            setPhoneError("Phone number cannot be empty");
            valid = false;
        }
        if (phone !== "" && phone.length !== 10) {
            setPhoneError("Invalid phone number");
            valid = false;
        }
        if (valid === true) {
            _sendOTP(null);
        }
    }

    // otp validation
    function _submitOTP(is_email) {
        let valid = true;
        if (otp === "") {
            setOtpError("OTP cannot be empty");
            valid = false;
        }
        if (otp !== "" && otp.length !== 4) {
            setOtpError("Invalid OTP");
            valid = false;
        }

        if (valid === true) {
            if (is_email) {
                _verifyOTP(false);
            } else {
                _verifyOTP(true)
            }

        }
    }

    // resend OTP
    function _handleResend() {
        _sendOTP("RESEND")
        resetCountdown();
        setOtp("");
    }

    // API -send signup otp
    const _sendOTP = async (type) => {

        setApiLoader(true);
        const url = "/auth/get-signin-otp"
        const request = {
            "country_code": "+91",
            "phone_number": phone,
            "type": type
        }
        await APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                setFlow(true);
                if (response.flow === "LOGIN") {
                    if (type) {
                        toast.success("OTP resent successfully", {
                            type: "success",
                        });
                    } else {
                        toast.success("OTP sent successfully", {
                            type: "success",
                        });
                    }
                    setSignupStep(2);
                    navigate("/", {
                        state: {
                            phone_number: phone,
                            step: 2

                        }
                    });
                }
                else {
                    _signInOTP();
                }

            } else {
                toast.error(response.message, {
                    type: "error",
                });

            }
            setOtp("");
            setApiLoader(false);
        });
    }

    const _signInOTP = async (type = null) => {
        if (type) {
            resetCountdown();
            setOtp("");
        } else {

            setApiLoader(true);
        }
        const url = "/auth/sign-up"
        let request = {}
        if (type) {
            request = {
                email: email,
                name: name,
            }
        } else {
            request = {
                country_code: "+91",
                phone_number: phone,
            }
        }
        await APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                if (type) {
                    toast.success("OTP resent successfully", {
                        type: "success",
                    });
                } else {
                    toast.success("OTP sent successfully", {
                        type: "success",
                    });
                    setSignupStep(2);
                }

            } else {
                toast.error(response.message, {
                    type: "error",
                });

            }
            setOtp("");
            setApiLoader(false);
        });
    }

    // API -verify signup otp
    const _verifyOTP = async (is_signup) => {
        setApiLoader(true);
        const url = "/auth/verify-otp"
        let request = {}
        if (is_signup) {
            request = {
                "country_code": "+91",
                "phone_number": phone,
                "otp": otp,
            }
        } else {
            request = {
                "otp": otp,
                "email": email
            }
        }
        await APIService(true, url, request, is_signup === false ? refreshToken : undefined).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {

                if (response.data.is_auth_configured === true) {
                    navigate("/unlock", { replace: true });
                    Cookies.set("mintit_refresh_token", response.data.refresh_token, {
                        expires: 90,
                        domain: process.env.REACT_APP_DOMAIN,
                        path: '/'
                    });
                    store.dispatch({
                        type: "REFRESH_TOKEN",
                        payload: response.data.refresh_token
                    })
                } else {
                    setIsMobileVerified(response.data.is_mobile_configured);
                    setIsEmailVerified(response.data.is_email_verified)
                    if (is_signup) {
                        if (flow === false) {
                            navigate("/configure-pin", {
                                state: {
                                    configured: true,
                                    refresh_token: response.data.refresh_token
                                }
                            }, { replace: true });
                        } else {
                            if (response.data.is_email_verified === true) {

                                navigate("/configure-pin", {
                                    state: {
                                        configured: true,
                                        refresh_token: response.data.refresh_token
                                    }
                                }, { replace: true });
                            } else {
                                setSignupStep(3);
                                navigate("/", {
                                    state: {
                                        phone_number: phone,
                                        step: 3,
                                        name: response.data.name
                                    }
                                });
                                setRefreshToken(response.data.refresh_token);
                            }
                        }

                    } else {
                        navigate("/configure-pin", {
                            state: {
                                configured: true,
                                refresh_token: response.data.refresh_token
                            }
                        }, { replace: true });
                    }
                    toast.success("OTP verified successfully", {
                        type: "success",
                    });
                }



            } else {
                toast.error(response.message, {
                    type: "error",
                });
                setTimeout(() => {
                    setOtp("");
                }, 500);
            }
            setApiLoader(false);
        })
    }


    // API - google login
    const _googleLogin = (token, data) => {
        const url = "/auth/google-signin";
        const request = {
            idToken: token,
            user: data,
            scopes: ['profile', 'email']
        }
        APIService(true, url, request).then((response) => {
            setFlow(false);
            setIsEmailVerified(true);
            if (response.status_code === 200) {
                if (response.flow === "LOGIN") {
                    navigate("/unlock", {
                        state: {
                            configured: true,
                            refresh_token: response.refresh_token
                        }
                    }, {
                        replace: true
                    },)
                    store.dispatch({
                        type: "REFRESH_TOKEN",
                        payload: response.refresh_token
                    })

                    Cookies.set("mintit_refresh_token", response.refresh_token, {
                        expires: 90,
                        domain: process.env.REACT_APP_DOMAIN,
                        path: '/'
                    });
                }
                else {
                    setEmailVerified(true);
                    navigate("/", {
                        state: {
                            name: response.name,
                            step: 3,
                            email: data.email
                        }
                    });
                    setSignupStep(3);
                    setRefreshToken(response.refresh_token);
                }
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                });
            }
        });
    }

    function _handleEmailConfirm() {

        setEmailVerified(false);
    }

    const _handleGoogleFailed = (error) => {
        toast.error(error, {
            type: 'error'
        });
    }

    const _handleGoogleSignup = (step, phone_number, email_address, userName) => {
        setSignupStep(step);
        setPhone(phone_number);
        setEmail(email_address);
        setOtp("");
        setName(userName);
        resetCountdown(0);
        console.log(phone_number)
        navigate("/", {
            state: {
                phone_number: phone_number ? phone_number : phone,
                name: userName ? userName : name,
                step: step,
                email: email_address ? email_address : email
            }
        });
    }

    return (
        <Fragment>
            <CustomHeader />
            <div className="padding-96px-top d-flex flex-column align-items-center justify-content-end">
                <div className="e-login-wrapper border-radius-32px">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-5">
                            <AuthSlider />
                        </div>
                        <div className="col-lg-1 col-md-1">
                            <div className="e-login-seperator position-relative"></div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            {
                                signupStep === 1 ?
                                    <Fragment>
                                        <h3 className="color-dark-jungle-green mb-1 e-font-24 e-line-height-36 e-montserrat-semi-bold letter-spacing-minus2em">Hey there!</h3>
                                        <p className="color-blue-gray e-font-14 e-line-height-24 e-montserrat-regular letter-spacing-minus2em mb-2">Welcome to MINTIT</p>
                                        <GmailLogin success={_googleLogin}
                                            failed={_handleGoogleFailed} />

                                        <img src={require("../../Assets/Images/Global/or.svg").default}
                                            alt="or"
                                            draggable={false}
                                            className="my-3 w-100" />
                                        <CustomPhoneInput label="Phone number"
                                            postfix="*"
                                            placeholder="Enter phone number"
                                            value={phone}
                                            maxLength={10}
                                            error={phoneError}
                                            onSubmit={_handleLogin}
                                            handleChange={_handlePhoneNumber} />
                                        <PrimaryButton className="w-100 margin-32px-top padding-10px-tb"
                                            label="Get OTP"
                                            disabled={apiLoader}
                                            onPress={_handleLogin} />
                                        {/* eslint-disable-next-line */}
                                        <p className="color-davy-gray e-font-12 e-line-height-18 e-montserrat-regular letter-spacing-minus2em my-2">By continuing, you agree to our <a href={process.env.REACT_APP_TERMS} target="_blank" className="no-decoration e-montserrat-medium color-davy-gray border-bottom-1px border-davy-gray d-inline-block">Terms & Condition</a> <a href={process.env.REACT_APP_PRIVACY} className="no-decoration e-montserrat-medium color-davy-gray border-bottom-1px border-davy-gray d-inline-block"> Privacy Policy</a></p>

                                    </Fragment>
                                    :
                                    signupStep === 2 ?
                                        <Fragment>
                                            <h3 className="color-dark-jungle-green mb-1 e-font-24 e-line-height-36 e-montserrat-semi-bold letter-spacing-minus2em">Verify OTP</h3>
                                            <p className="color-blue-gray e-font-14 e-line-height-24 e-montserrat-regular letter-spacing-minus2em mb-3">
                                                OTP Sent to <span className="color-charleston-green e-montserrat-semi-bold">+91 - {_getMaskedNumber(phone)}</span>
                                                <Icon icon="edit" size={20} className="ms-2 cursor-pointer"
                                                    onClick={() => {
                                                        navigate(-1);
                                                        setSignupStep(1)
                                                    }
                                                    }
                                                />
                                            </p>

                                            <CustomPINInput id="otp-input"
                                                pinString={otp}
                                                autoFocus={true}
                                                error={otpError}
                                                onSubmit={() => _submitOTP(false)}
                                                handleChange={_handleOTP} />
                                            <p className="color-charcoal-blue e-montserrat-medium e-font-14 e-line-height-20 mt-4">Didn’t get OTP? <span className={`no-decoration color-rebecca-purple e-link ${seconds === 0 ? 'cursor-pointer' : 'disabled'}`} onClick={_handleResend}>{seconds === 0 ? 'Resend OTP' : ` Resend OTP in ${seconds < 10 ? `0${seconds}` : seconds}s`}</span></p>

                                            <PrimaryButton className="w-100 margin-32px-top padding-10px-tb"
                                                label="Verify & Proceed"
                                                onPress={() => _submitOTP(false)}
                                                disabled={apiLoader} />
                                        </Fragment>
                                        :
                                        signupStep === 3 ?
                                            <SocialSignup signUpStep={_handleGoogleSignup}
                                                flow={flow}
                                                token={refreshToken}
                                                is_email_verified={isEmailVerified}
                                                is_mobile_verified={isMobileVerified}
                                                phone={phone}
                                                email={email}
                                                name={name} />
                                            :
                                            <Fragment>
                                                <h3 className="color-dark-jungle-green mb-1 e-font-24 e-line-height-36 e-montserrat-semi-bold letter-spacing-minus2em">Verify OTP</h3>
                                                <p className="color-blue-gray e-font-14 e-line-height-24 e-montserrat-regular letter-spacing-minus2em mb-3">
                                                    OTP Sent to <span className="color-charleston-green e-montserrat-semi-bold">{`${_maskEmail(email)}`}</span>
                                                    <Icon icon="edit" size={20} className="ms-2 cursor-pointer"
                                                        onClick={() => {
                                                            navigate(-1);
                                                        }}
                                                    />
                                                </p>

                                                <CustomPINInput id="otp-input"
                                                    pinString={otp}
                                                    autoFocus={true}
                                                    error={otpError}
                                                    onSubmit={() => _submitOTP(true)}
                                                    handleChange={_handleOTP} />
                                                <p className="color-charcoal-blue e-montserrat-medium e-font-14 e-line-height-20 mt-4">Didn’t get OTP? <span className={`no-decoration color-rebecca-purple e-link ${seconds === 0 ? 'cursor-pointer' : 'disabled'}`} onClick={() => _signInOTP("RESEND")}>{seconds === 0 ? 'Resend OTP' : ` Resend OTP in ${seconds < 10 ? `0${seconds}` : seconds}s`}</span></p>

                                                <PrimaryButton className="w-100 margin-32px-top padding-10px-tb"
                                                    label="Verify & Proceed"
                                                    onPress={() => _submitOTP(true)}
                                                    disabled={apiLoader} />
                                            </Fragment>



                            }

                        </div>
                    </div>
                </div>
                <div className="d-flex flex-wrap align-items-center mt-4 gap-10px padding-32px-bottom px-sm-0 px-4">
                    <p className="color-charcoal-gray e-font-16 e-line-height-28 e-montserrat-semi-bold mb-0">Download App</p>
                    <div className="d-flex align-items-center p-sm-2 p-0 gap-10px">
                        <Icon icon='ios-store'
                            size={28} />
                        <p className="color-charleston-green e-font-16 e-line-height-20 e-montserrat-semi-bold mb-0">AppStore</p>
                    </div>
                    <div className="d-flex align-items-center p-sm-2 p-0 ps-0 gap-10px">
                        <Icon icon='play-store'
                            size={28} />
                        <p className="color-charleston-green e-font-16 e-line-height-20 e-montserrat-semi-bold mb-0">PlayStore</p>
                    </div>

                </div>
            </div>

            {
                emailVerified === true &&
                <EmailConfirmationModal type={1}
                    title="Google Verification Successful"
                    content="Your Google verification Successful! Now, please enter your phone number for verification."
                    handleNavigate={_handleEmailConfirm}
                    showButton={true}
                    closeModal={_handleEmailConfirm} />
            }
        </Fragment>
    )
}

export default Signup