/*
*   File : settings.js
*   Author : https://evoqins.com
*   Description : Profile Settings screen
*   Version : 1.0.0
*/

// packages
import { Fragment, useEffect, useState } from "react";
import OneSignal from "react-onesignal";

// components
import { Header } from "../../Components/Header";
import { Breadcrumb } from "../../Components/Other"
import { useLocation, useNavigate } from "react-router-dom";
import { Icon } from "../../Components/Icon";
import { toast } from "react-toastify";
import { CustomPINInput, RadioGroup } from "../../Components/FormElements";
import { PrimaryButton } from "../../Components/Buttons";
import { Toggle } from "../Others";

// services
import APIService from "../../Services/api-service";

const SETTING_TAB = [{
    id: 1,
    active: "mpin-active",
    inactive: "mpin-inactive",
    label: "Change MPIN",
},
// {
//     id: 2,
//     active: "language-active",
//     inactive: "Language-inactive",
//     label: "Change language",
// },
{
    id: 3,
    active: "push-notification-active",
    inactive: "Push-notification-inactive",
    label: "Push Notifications",
}]

const RADIO_OPTION = [
    {
        name: "English",
        id: 1
    },
    {
        name: "हिंदी - Hindi",
        id: 2
    },
    {
        name: "தமிழ் - Tamil",
        id: 3
    },
    {
        name: "ಕನ್ನಡ - Kannada",
        id: 4
    }
];


const AccountSettings = (props) => {

    const navigate = useNavigate();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(SETTING_TAB[0].id);
    const [currentPin, setCurrentPin] = useState("");
    const [currentPinError, setCurrentPinError] = useState("");
    const [mpin, setMpin] = useState("");
    const [mpinError, setMpinError] = useState("");
    const [newMpin, setNewMpin] = useState("");
    const [newMpinError, setNewMpinError] = useState("");
    const [selectedLanguage, setSelectedLanguage] = useState(RADIO_OPTION[0].id);
    const [mpinLoader, setMpinLoader] = useState(false);
    const [notificationActive, setNotificationActive] = useState(props.active);


    useEffect(() => {
        if (location.state !== null) {
            setActiveTab(location.state.tab_index);
        }
        // eslint-disable-next-line
    }, [OneSignal]);


    useEffect(() => {
        setNotificationActive(props.active);
    }, [props.active]);

    function _handleNavigate() {
        navigate("/");
    }

    const _handleTab = (id) => {
        navigate("/settings", {
            state: {
                tab_index: id
            }
        })
        setActiveTab(id);
    }

    // handle submit mpin validation
    async function _validateMpin() {
        let valid = true;
        if (currentPin === "") {
            setCurrentPinError("Enter your current PIN");
            valid = false;
        }
        if (currentPin !== "" && currentPin.length !== 4) {
            setCurrentPinError("Enter a valid PIN");
            valid = false;
        }
        if (mpin === "") {
            setMpinError("Enter the new PIN");
            valid = false;
        }
        if (mpin !== "" && mpin.length !== 4) {
            setMpinError("Enter a valid PIN");
            valid = false;
        }
        if (newMpin === "") {
            setNewMpinError("Confirm your PIN");
            valid = false;
        }
        if (newMpin !== "" && newMpin.length !== 4) {
            setNewMpinError("Enter a valid PIN");
            valid = false;
        }
        if (mpin !== newMpin) {
            toast.dismiss();
            toast.error("There is a mismatch in the PINs", {
                type: "error",
            });
            valid = false;
        }
        if (valid) {
            setMpinLoader(true);
            _submitMpin();
        }
    }

    // handle current pin
    function _handleCurrentPin(input_value) {
        if (input_value.length <= 4) {
            setCurrentPin(input_value);
        }
        setCurrentPinError("");
    }

    // handle mpin
    function _handleMpin(input_value) {
        if (input_value.length <= 4) {
            setMpin(input_value);
        }
        setMpinError("");
    }

    // handle new mpin
    function _handleNewMpin(input_value) {
        if (input_value.length <= 4) {
            setNewMpin(input_value);
        }
        setNewMpinError("");
    }


    // handle radio group
    function _handleRadioGroup(radio) {
        setSelectedLanguage(radio);
    }



    const _handleToggle = async () => {
        if (notificationActive === true) {
            setNotificationActive(false);
            await OneSignal.User.PushSubscription.optOut();
        } else {
            setNotificationActive(true);
            await OneSignal.User.PushSubscription.optIn();
        }

    };

    // API - Submit MPIN
    const _submitMpin = () => {
        setMpinLoader(true);

        const url = "/profile/update-pin";

        const request = {
            "current_pin": currentPin,
            "new_pin": newMpin
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setCurrentPin("");
                setMpin("");
                setNewMpin("");
                toast.success("MPIN updated", {
                    type: 'success'
                });
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                });
            }
            setMpinLoader(false);
        });
    }

    return (
        <Fragment>
            <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
                {/* Header */}
                <Header type={2} />
                <div className="row ">
                    <div className="col-lg-11 pe-lg-0 padding-144px-bottom">

                        <div className="px-4">
                            <Breadcrumb mainPage="Home"
                                currentPage="Account Settings"
                                handleNavigate={_handleNavigate}
                                type={1} />
                            <h5 className="margin-30px-tb e-font-18 e-line-height-38 e-montserrat-semi-bold color-dark-jungle-green">Account Settings</h5>
                            <div className="row">
                                <div className="col-xl-3 col-md-4">
                                    <div className="e-profile-border border-radius-24px d-md-block d-none">
                                        {
                                            SETTING_TAB.map((item, key) =>
                                                <div className={`${activeTab === item.id && "e-bg-light-lavender"} 
                                                        ${key === 0 && "border-radius-tlr-24px"} 
                                                        ${key !== SETTING_TAB.length - 1 && "border-bottom-1px border-chinese-white"}
                                                        ${key === SETTING_TAB.length - 1 && "border-radius-blr-24px"}
                                                        padding-18px-tb padding-12px-lr padding-22px-bottom cursor-pointer`}
                                                    key={key}
                                                    onClick={() => _handleTab(item.id)}>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="d-flex align-items-center gap-8px">
                                                            <Icon icon={item.id === activeTab ? item.active : item.inactive}
                                                                size={20} />
                                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-24 color-eerie-black mb-0">{item.label}</p>
                                                        </div>
                                                        <Icon icon="arrow-right"
                                                            size={20} />
                                                    </div>

                                                </div>
                                            )
                                        }

                                    </div>
                                    <div className="d-md-none d-block mb-3 ">
                                        <div className="overflow-x-auto d-flex gap-8px white-space-nowrap">
                                            {
                                                SETTING_TAB.map((item, key) =>
                                                    <div className={`${activeTab === item.id ? "e-bg-light-lavender border color-rebecca-purple" : "color-eerie-black border"} border-radius-24px px-3 py-2 cursor-pointer color-eerie-black e-montserrat-medium e-font-12 e-line-height-14`}
                                                        key={key}
                                                        onClick={() => _handleTab(item.id)}>
                                                        {item.label}
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-9 col-md-8">
                                    <div className="e-profile-border border-radius-24px p-4">
                                        {
                                            activeTab === 1 ?
                                                <Fragment>
                                                    <p className="e-montserrat-semi-bold e-font-16 e-line-height-24 color-eerie-black mb-2">Set New MPIN</p>
                                                    <p className="e-montserrat-medium e-font-14 e-line-height-24">Set a new PIN to keep you investments safe & secure.</p>
                                                    <CustomPINInput id="currentPin"
                                                        autoFocus={true}
                                                        pinString={currentPin}
                                                        error={currentPinError}
                                                        postfix="*"
                                                        className="mb-4"
                                                        title="Enter Your Old PIN"
                                                        handleChange={_handleCurrentPin} />
                                                    <CustomPINInput id="mpin"
                                                        autoFocus={false}
                                                        pinString={mpin}
                                                        error={mpinError}
                                                        postfix="*"
                                                        className="mb-4"
                                                        title="Enter New PIN"
                                                        handleChange={_handleMpin} />
                                                    <CustomPINInput id="newMpin"
                                                        autoFocus={false}
                                                        pinString={newMpin}
                                                        error={newMpinError}
                                                        postfix="*"
                                                        className="margin-32px-bottom"
                                                        title="Re-enter Your PIN"
                                                        onSubmit={_validateMpin}
                                                        handleChange={_handleNewMpin} />
                                                    <div className="row mt-lg-0 mt-4 pt-2">
                                                        <div className="col-sm-3 col-6">
                                                            <PrimaryButton label="Update"
                                                                disabled={mpinLoader}
                                                                showLoader={mpinLoader}
                                                                className="padding-12px-tb w-100"
                                                                onPress={_validateMpin} />
                                                        </div>
                                                    </div>
                                                </Fragment>
                                                :
                                                activeTab === 2 ?
                                                    <Fragment>
                                                        <p className="e-montserrat-semi-bold e-font-16 e-line-height-24 color-eerie-black mb-2">Choose language</p>
                                                        <RadioGroup data={RADIO_OPTION}
                                                            className="flex-column gap-24px"
                                                            selected={selectedLanguage}
                                                            handleSelect={_handleRadioGroup} />
                                                    </Fragment>
                                                    :
                                                    <Fragment>
                                                        <p className="e-montserrat-semi-bold e-font-16 e-line-height-24 color-eerie-black mb-3">Push notifications</p>
                                                        {
                                                            <div className={`d-flex justify-content-between align-items-center py-3 padding-14px-right`}>
                                                                <div className="d-flex align-items-center gap-8px">
                                                                    <Icon icon={"Promotional"}
                                                                        size={20} />
                                                                    <span className="e-montserrat-medium e-font-14 e-line-height-20 color-eerie-black">User Alerts</span>
                                                                </div>
                                                                <Toggle
                                                                    check={notificationActive}
                                                                    onChange={() => _handleToggle()} />
                                                            </div>

                                                        }
                                                    </Fragment>

                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default AccountSettings;