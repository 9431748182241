/*
 *   File : stepup-sip.js
 *   Author : https://evoqins.com
 *   Description : Modal component to Step up sip.
 *   Integrations : none
 *   Version : 1.0.0
*/

// Packages
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

// Components
import { Icon } from "../Icon";
import { useCountdown } from "../../Helper/helper";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import { CustomPINInput, EditableSlider } from "../FormElements";

// Color,style
import style from "../../Styles/Components/edit-sip.module.scss";
import Color from "../../Styles/color.module.scss";

// Service,helper
import { _generate2Fa } from "../../Helper/api";
import APIService from "../../Services/api-service";

const StepUpSIP = (props) => {

    const PROFILE_DETAILS = useSelector(state => state.Reducer.PROFILE_DETAILS);
    const MASKED_NUMBER = PROFILE_DETAILS.phone.masked_number

    const [stepUp, setStepUp] = useState(props.fund.step_up);
    const [screenNumber, setScreenNumber] = useState(1);
    const { seconds, resetCountdown } = useCountdown(30);
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");
    const [editModalData, setEditModalData] = useState({});
    const [modalLoader, setModalLoader] = useState(false);

    useEffect(() => {
        var my_modal = document.getElementById("step-up-sip");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();

        });
        // eslint-disable-next-line
    }, []);

    // handle amount
    function _handleStepUp(input_value) {
        setStepUp(input_value);
    }

    // handle OTP
    function _handleOTP(input_value) {
        if (input_value.length <= 4) {
            setOtp(input_value);
        }
        setOtpError("");
    }

    // resend OTP
    function _handleResend() {
        setOtp("");
        setOtpError("");
        _generate2FaApi();
    }

    // API - resend otp
    const _generate2FaApi = () => {
        _generate2Fa("Edit SIP")
            .then((response) => {
                resetCountdown();
            }).catch((error) => {
                toast.dismiss();
                toast.error(error, {
                    type: "error",
                });
            });
    };

    const _save = () => {
        // document.getElementById("close-btn").dispatchEvent(new Event("click"));
        _generate2Fa("Edit SIP")
            .then((response) => {
                setEditModalData({
                    sip_id: props.fund.sip_id,
                    step_up: stepUp,
                    type: props.fund.investment_type
                })
                setScreenNumber(2);
                resetCountdown();

            }).catch((error) => {
                toast.dismiss();
                toast.error(error, {
                    type: "error",
                });
            });
    }

    // handle submit otp validation
    function _submitOTP() {
        let valid = true;
        if (otp === "") {
            setOtpError("OTP is required");
            valid = false;
        }
        if (otp !== "" && otp.length !== 4) {
            setOtpError("Invalid OTP");
            valid = false;
        }
        if (valid) {
            setModalLoader(true);
            _editActiveSIP();
        }
    }

    // API -edit SIP / step up SIP
    const _editActiveSIP = () => {
        let url = "";
        let request = {};
        url = "/sip/step-up";
        request = {
            "sip_id": editModalData.sip_id,
            "step_up": editModalData.step_up,
            "otp": otp
        };

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setEditModalData({});
                document.getElementById("close-btn").dispatchEvent(new Event("click"));
                props.success(editModalData.type);
                toast.success(response.message, {
                    type: "success",
                });
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
            setModalLoader(false);
        })
    };


    const _handleCancel = () => {
        setScreenNumber(1);
        setOtp("");
        setOtpError("");
    }

    return (
        <Fragment>
            <div className={`modal fade ${style.e_edit_sip}`}
                id="step-up-sip"
                tabIndex="-1"
                aria-labelledby="select-career"
                aria-hidden="true"
                data-bs-backdrop="static"
                data-bs-keyboard="false">
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content ${style.e_content} pb-4`}>
                        <div className="pt-4 px-4 pb-3 d-flex justify-content-between align-items-center border-bottom-1px border-ash-gray">
                            <p className="e-montserrat-semi-bold e-font-16 e-line-height-24 color-eerie-black mb-0">{screenNumber === 1 ? "Step Up SIP" : "Enter OTP"}</p>
                            <Icon icon="close-btn"
                                id="close-btn"
                                size={24}
                                color={Color.eerie_black}
                                className="cursor-pointer"
                                data-bs-dismiss="modal"
                                onClick={props.closeModal} />
                        </div>
                        {
                            screenNumber === 1 ?
                                <div className="px-4 pt-3">
                                    <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black">Customize your SIP details</p>
                                    <div className={`${style.e_fund_border} p-3 border-radius-16px`}>
                                        {/* <p className="e-montserrat-medium e-font-12 e-line-height-16 color-black mb-2">{props.filterType.label}</p> */}
                                        <div className="d-flex align-items-center gap-8px">
                                            <img src={props.fund.icon}
                                                alt={props.fund.name}
                                                width={38}
                                                height={38}
                                                draggable={false}
                                                className="border-radius-12px e-fund-detail-chart object-fit-contain" />
                                            <p className="e-montserrat-medium e-font-14 e-line-height-20 color-eerie-black mb-0">{props.fund.name}</p>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-6">
                                                <p className="e-montserrat-regular e-font-14 e-line-height-24 color-outer-space margin-2px-bottom">Current Monthly SIP</p>
                                                <p className="e-montserrat-semi-bold e-font-14 e-line-height-24 color-eerie-black mb-0">{props.fund.amount_text}</p>
                                            </div>
                                            <div className="col-6">
                                                <p className="e-montserrat-regular e-font-14 e-line-height-24 color-outer-space margin-2px-bottom">SIP date</p>
                                                <p className="e-montserrat-semi-bold e-font-14 e-line-height-24 color-eerie-black mb-0">{props.fund.next_sip_date}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <EditableSlider label="Monthly Step Up(%)"
                                        value={stepUp}
                                        min={0}
                                        max={50}
                                        suffix="%"
                                        singularSuffix="%"
                                        pluralSuffix="%"
                                        className="mb-sm-0 mb-4 mt-3"
                                        handleChange={_handleStepUp} />

                                    <div className="row margin-32px-top d-flex justify-content-center">
                                        <div className="col-6">
                                            <PrimaryButton label="Save"
                                                className="padding-10px-tb  w-100"
                                                onPress={_save} />
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="px-4 padding-14px-top">
                                    <p className="e-font-14 mb-2 color-eerie-black e-montserrat-regular e-line-height-24">
                                        An OTP has been sent to your PAN linked mobile
                                        number.
                                    </p>
                                    <h6 className="e-font-16 mb-3 color-eerie-black e-montserrat-semi-bold e-line-height-24">{MASKED_NUMBER}</h6>
                                    <CustomPINInput id="otp"
                                        autoFocus={true}
                                        pinString={otp}
                                        error={otpError}
                                        postfix="*"
                                        label="Enter OTP"
                                        onSubmit={_submitOTP}
                                        handleChange={_handleOTP} />
                                    <p className="color-charcoal-blue e-montserrat-medium e-font-14 e-line-height-20 mt-4">Didn’t get OTP? <span className={`no-decoration color-rebecca-purple e-link 
                                    ${seconds === 0 ?
                                            'cursor-pointer'
                                            : 'disabled'}`}
                                        onClick={_handleResend}>
                                        {seconds === 0 ?
                                            'Resend OTP'
                                            : `Resend OTP in ${seconds < 10 ? `0${seconds}` : seconds}s`}
                                    </span>
                                    </p>
                                    <div className="row margin-32px-top">
                                        <div className="col-6">
                                            <SecondaryButton label="Cancel"
                                                className="padding-12px-tb w-100 e-bg-magnolia"
                                                onPress={_handleCancel} />
                                        </div>
                                        <div className="col-6">
                                            <PrimaryButton label="Verify"
                                                className="padding-12px-tb  w-100"
                                                disabled={modalLoader}
                                                onPress={_submitOTP} />
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>

        </Fragment>
    )
}

export default StepUpSIP;