/*
 *   File : create-switch.js
 *   Author URI : https://evoqins.com
 *   Description :Modal to create switch order from portfolio
 *   Integrations : null
 *   Version : v1.1
 */

// Packages
import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal } from "bootstrap";

// Components
import { Icon } from "../Icon";
import { CustomAmountInput, CustomPINInput, CustomTextInput, RadioGroup } from "../FormElements";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import { CustomDropdown } from "../Dropdown";
import { SearchFunds } from "./";

// Styles
import style from '../../Styles/Components/switch.module.scss';
import Color from '../../Styles/color.module.scss';

// Services,helper
import APIService from "../../Services/api-service";
import { _generate2Fa } from "../../Helper/api";
import { useCountdown } from "../../Helper/helper";

// Store
import store from "../../Store";


// Screen number 
// 1 :: Form elements-> select fund to switch,folio, amount or units
// 2 :: Enter OTP
// 3 :: Success or Failure message


// Search component
const SearchComponent = (props) => {

    function _onFocus() {
        if (props.onFocus) {
            props.onFocus();
        }
    }

    return (
        <div className="position-relative" onClick={_onFocus}>
            {
                props.prefix && <Icon icon="Search"
                    size={props.size}
                    className="position-absolute e-search-icon" />
            }

            <input value={props.inputState}
                placeholder={props.placeholder}
                type='text'
                readOnly={props.disabled}
                className={`color-black e-montserrat-medium e-font-14 e-line-height-20 w-100 no-outline  e-search-fund border-radius-12px  border-0 ${props.class}`}
                onChange={props.onChange}
                onBlur={props.onBlur ? props.onBlur : null} />

            {
                props.suffix && <Icon icon="right-arrow"
                    size={props.size}
                    color={Color.rebecca_purple}
                    className="position-absolute right-16px top-16px rotate-90deg" />
            }
            {
                props.close === true && props.inputState.length > 0 &&
                <Icon icon="close-btn"
                    size={20}
                    color={Color.black}
                    className="position-absolute  top-0 right-8px bottom-0 my-auto cursor-pointer"
                    onClick={props.clearSearch} />
            }
            {
                props.error &&
                <div className='position-absolute d-flex align-items-center margin-6px-top w-max-content'>
                    {/* <Icon icon="info-circle"
                        size={16}
                        className="me-1" /> */}
                    <p className='color-deep-carmine-pink  e-montserrat-medium e-font-12  e-line-height-16 mb-0 '>
                        {props.error}
                    </p>
                </div>
            }

        </div>
    );
}

const SWITCH_TYPE = [
    {
        name: "Amount", id: 1
    },
    {
        name: "Unit", id: 2
    }
]

const SwitchOrderModal = (props) => {
    const navigate = useNavigate();
    const inputRef = useRef(null);
    const PROFILE_DETAILS = store.getState().Reducer.PROFILE_DETAILS;
    const MASKED_NUMBER = PROFILE_DETAILS.phone.masked_number;
    const { seconds, resetCountdown } = useCountdown(30);

    const [screenNumber, setScreenNumber] = useState(1);
    const [switchType, setSwitchType] = useState(SWITCH_TYPE[0].id);

    const [amount, setAmount] = useState("");
    const [amountError, setAmountError] = useState("");

    const [units, setUnits] = useState("");
    const [unitsError, setUnitsError] = useState("");

    const [switchAll, setSwitchAll] = useState(false);

    const [apiLoader, setApiLLoader] = useState(false);

    const [searchFund, setSearchFund] = useState(false);

    const [folioOptions, setFolioOptions] = useState([]);
    const [folio, setFolio] = useState(null);
    const [folioError, setFolioError] = useState("");

    const [selectedFundName, setSelectedFundName] = useState("");
    const [selectedFundId, setSelectedFundId] = useState("");
    const [switchError, setSwitchError] = useState("");


    const [modalData, setModalData] = useState({});

    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");

    useEffect(() => {
        var my_modal = document.getElementById("create-switch");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
            document.body.removeAttribute('style')
        });
        //eslint-disable-next-line
    }, []);


    // Custom options
    useEffect(() => {
        const folio_options = props.summary.folio_data.map((item) => {
            return ({
                label: <div className="e-montserrat-medium" dangerouslySetInnerHTML={{ __html: `${item.folio_number} <br /> <span style="font-size:12px"><span class="e-montserrat-regular">Amount</span>: ₹${item.amount}</span>  ${item.units ? `<span style="font-size:12px; display:inline-block"> <span class="e-montserrat-regular">Units:</span> ${item.units}</span> ` : ``}` }} />,
                value: item.folio_number
            })
        });

        setFolioOptions(folio_options);
    }, [props.summary]);

    useEffect(() => {
        if (searchFund === true) {
            const modal = new Modal(document.getElementById("search-funds"), {});
            modal.show();
        }
    }, [searchFund]);

    // handle switch type
    function _handleType(id) {
        setSwitchType(id);
        setSwitchAll(false);
        setAmount("");
        setUnits("");
    }

    // handle amount
    function _handleAmount(input_value) {
        setAmount(input_value);
        setAmountError("");
    }

    // handle units
    function _handleUnits(input_value) {
        // Regular expression to allow numbers and up to three decimal places
        setUnits(input_value);
        setUnitsError("");
    }

    // handle switch all
    function _handleSwitchAll() {
        setSwitchAll(!switchAll);
        if (switchAll === false) {
            if (folio !== null) {
                const folio_data = props.summary.folio_data.find((item) => item.folio_number === folio.value);
                if (switchType === 1) {
                    setAmount(folio_data.amount)

                } else {
                    setUnits(folio_data.units);
                }
            }
        } else {
            setAmount("");
            setUnits("");
        }
    }

    // handle folio number
    function _handleFolio(select_value) {
        setFolio(select_value);
        setFolioError("");
    }

    // close search funds modal
    function _handleCloseFundModal(status) {
        setSearchFund(false);

    }

    // handle chose selected fund to be switched
    function _handleChoseFund(fund) {
        setSelectedFundName(fund.fund_name);
        setSelectedFundId(fund.id);
        setSearchFund(false);
        setSwitchError("");
    }

    // Open search fund modal to select fund for switching
    function _handleSwitchSelect() {
        setSearchFund(true)
    }

    // handle OTP
    function _handleOTP(input_value) {
        if (input_value.length <= 4) {
            setOtp(input_value);
        }
        setOtpError("");
    }

    // resend OTP
    function _handleResend() {
        setOtp("");
        setOtpError("");
        _generate2FaApi();
    }

    // handle submit OTP
    function _submitOTP() {
        let valid = true;
        if (otp === "") {
            setOtpError("OTP is required");
            valid = false;
        }
        if (otp !== "" && otp.length !== 4) {
            setOtpError("Invalid OTP");
            valid = false;
        }
        if (valid) {
            _placeOrder()
        }
    }

    // navigate to Order list
    function _navigateToOrder() {
        navigate("/orders", {
            state: {
                paymentTypes: [],
                orderStatus: [],
                orderTypes: [6],
                startDate: "DD-MM-YYYY",
                endDate: "DD-MM-YYYY",
                type: 2,
                page: 1
            }
        })
    }

    // handle submit amount
    function _handleSubmit() {
        let valid = true;
        if (selectedFundId === "") {
            setSwitchError("Please select the fund");
            inputRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
            valid = false;
        }
        if (folio === null) {
            setFolioError("Please select folio");
            inputRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
            valid = false;
        }
        if (switchType === 1) {

            if (amount === "") {
                inputRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
                setAmountError("Please enter the amount")
                valid = false
            }

        } else {
            if (units === "") {
                inputRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
                setUnitsError("Please enter the unit")
                valid = false
            }
        }
        if (valid === true) {
            _confirmSwitchOrder()
        }
    }


    // API - resend otp
    const _generate2FaApi = () => {
        _generate2Fa("MF")
            .then((response) => {
                resetCountdown();
            }).catch((error) => {
                toast.dismiss();
                toast.error(error, {
                    type: "error",
                });
            });
    };


    // API - confirm switch order
    const _confirmSwitchOrder = () => {
        setApiLLoader(true);
        const url = "/mf/confirm-switch";

        const request = {
            switch_out_mf_id: props.summary.mf_id,
            switch_in_mf_id: selectedFundId,
            folio_number: folio.value,
            switch_all: switchAll,
            amount: switchAll === true ? null : amount === "" ? null : amount,
            units: switchAll === null ? null : units === "" ? null : units
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setScreenNumber(2);
                setOtp("");
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setApiLLoader(false);
        })
    }

    // API - place switch order
    const _placeOrder = () => {
        setApiLLoader(true);
        const url = "/mf/create-switch";

        const request = {
            switch_out_mf_id: props.summary.mf_id,
            switch_in_mf_id: selectedFundId,
            folio_number: folio.value,
            switch_all: switchAll,
            amount: amount === "" ? null : amount,
            units: units === "" ? null : units,
            otp: otp
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setScreenNumber(3);
                setModalData({
                    status: 1,
                    image: require("../../Assets/Images/Modal/success.png"),
                    title: "Switch order placed",
                    // description: "Lorem Ipsum has been the industry's standard dummy text ever since"
                })
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setApiLLoader(false);
        })
    }

    return (
        <Fragment>
            <div className={`modal fade ${style.e_switch}`}
                id="create-switch"
                tabIndex="-1"
                aria-labelledby="create-switch"
                aria-hidden="true"
                data-bs-backdrop="static"
                data-bs-keyboard="false">
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content pb-4 ${style.e_content} `}>

                        {screenNumber !== 3 && <div className="border-bottom-1px border-ash-gray px-4">
                            <div className="d-flex justify-content-between align-items-center pt-4 pb-3">
                                {
                                    screenNumber === 1 &&
                                    <h5 className='e-font-16 mb-0 color-eerie-black e-montserrat-semi-bold e-line-height-24'>
                                        Switch fund
                                    </h5>
                                }

                                {
                                    screenNumber === 2 &&
                                    <h5 className='e-font-16 mb-0 color-eerie-black e-montserrat-semi-bold e-line-height-24'>
                                        <Icon icon="Arrow-Right"
                                            size={22}
                                            className="e-transform-left-arrow cursor-pointer me-2"
                                            onClick={() => setScreenNumber(1)} />  Enter OTP
                                    </h5>
                                }
                                <Icon icon="close-btn"
                                    size={24}
                                    color={Color.eerie_black}
                                    id="close-fund"
                                    className="cursor-pointer"
                                    data-bs-dismiss="modal" />
                            </div>
                        </div>}

                        {
                            screenNumber === 1 ?
                                <div className={style.e_height}>
                                    <div className="border-radius-12px padding-12px-all e-bg-magnolia mx-4 mt-3 position-relative e-fund-order">
                                        <div className="row ">
                                            <div className="col-10  position-relative z-index-2">
                                                <h5 className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black mb-0">Switch Fund</h5>
                                                <p className="e-montserrat-medium e-font-12 e-line-height-20 mt-1 color-jet-gray mb-0">
                                                    Move your money between two funds of the same fund house.
                                                </p>
                                            </div>
                                        </div>
                                        <img src={require("../../Assets/Images/Portfolio/switch_fund.png")}
                                            alt="switch"
                                            width="auto"
                                            className="object-fit-contain h-100  position-absolute top-0 end-0 w-auto" />
                                    </div>
                                    <div className="box-shadow-black p-3 mx-4  border-radius-16px mt-3 ">
                                        <p className="color-outer-space e-font-14 e-line-height-16 e-montserrat-regular margin-12px-bottom">Switch from</p>
                                        <div className="d-flex align-items-center gap-8px">

                                            <img src={props.summary.icon}
                                                alt="goal"
                                                width={38}
                                                height={38}
                                                className="box-shadow-black border-radius-6px" />
                                            <span className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black mb-0">{props.summary.name}</span>
                                        </div>

                                        <div className="d-flex justify-content-between align-items-start mt-3">
                                            <p className="color-outer-space e-font-12 e-line-height-16 e-montserrat-regular mb-1">Available to Switch</p>
                                            <div className="d-block">
                                                <h6 className="color-eerie-black e-font-16 e-line-height-24 e-montserrat-semi-bold mb-0 text-end">{props.summary.available_to_switch_amount_text}</h6>
                                                <h6 className="color-eerie-black e-font-14 e-line-height-24 e-montserrat-medium mb-0 text-end">{props.summary.available_to_switch_units} Units</h6>
                                            </div>
                                        </div>
                                        {props.summary.exit_load && <div className="d-block mt-2">
                                            <p className="color-outer-space e-font-14 e-line-height-16 mb-1 e-montserrat-medium mb-1">Exit load</p>
                                            <h6 className="color-eerie-black e-font-14 e-line-height-16 e-montserrat-regular mb-0">{props.summary.exit_load}</h6>
                                        </div>}
                                    </div>
                                    <div className="px-4 mt-4">
                                        <h5 className='e-font-14  color-eerie-black e-montserrat-semi-bold e-line-height-24 mb-3'>
                                            Switch to
                                        </h5>
                                        <p className={`e-montserrat-medium color-dark-charcoal e-font-14 e-line-height-18 cursor-text margin-6px-bottom bg-transparent`}>
                                            Fund name
                                            <span className='color-deep-carmine-pink'>
                                                *
                                            </span>
                                        </p>
                                        <SearchComponent placeholder="Select  fund to switch to"
                                            size={16}
                                            suffix={true}
                                            disabled={true}
                                            inputState={selectedFundName}
                                            error={switchError}
                                            class="cursor-pointer padding-14px-tb ps-3 pe-5"
                                            onFocus={_handleSwitchSelect} />


                                        <CustomDropdown label="Folio"
                                            postfix="*"
                                            options={folioOptions}
                                            value={folio}
                                            placeholder="Select Folio"
                                            className="mt-4 pt-1 pb-4"
                                            isSingleValue={true}
                                            error={folioError}
                                            message="No folios found"
                                            onSelectChange={_handleFolio} />
                                        {/* <div className="d-flex align-items-start padding-12px-all mt-3 e-bg-light-blue border-radius-12px mt-3 margin-12px-bottom color-ocean-blue e-font-14 e-line-height-20 gap-4px e-montserrat-medium">
                                            <span>
                                                <Icon icon="info-circle"
                                                    size={16} />
                                            </span>
                                            Transferring your investment from one fund to another within the same fund house.
                                        </div> */}
                                        <RadioGroup data={SWITCH_TYPE}
                                            selected={switchType}
                                            handleSelect={_handleType} />
                                        <div ref={inputRef} className="mt-3 pb-4">
                                            {
                                                switchType === 1 ?
                                                    <CustomAmountInput label="Amount"
                                                        postfix={"*"}
                                                        value={amount}
                                                        disabled={switchAll}
                                                        error={amountError}
                                                        placeholder="50,000"
                                                        handleChange={_handleAmount} />
                                                    :
                                                    <CustomTextInput label="Unit"
                                                        postfix={"*"}
                                                        value={units}
                                                        type="number"
                                                        is_decimal={true}
                                                        placeholder="1.2"
                                                        inputClass={style.e_unit}
                                                        isDisabled={switchAll}
                                                        error={unitsError}
                                                        handleChange={_handleUnits} />
                                            }
                                        </div>
                                        <div className={`d-flex align-items-start mt-2 gap-8px e-montserrat-medium e-font-14 e-line-height-20 letter-spacing-007em cursor-pointer color-eerie-black mb-4 w-max-content ${folio === null ? 'disabled' : ''}`}
                                            onClick={_handleSwitchAll}>
                                            <Icon icon={switchAll ? "checked" : "unchecked"}
                                                size={20} />
                                            Switch all
                                        </div>
                                    </div>
                                </div>
                                :

                                screenNumber === 2 ?
                                    <div className="px-4 padding-14px-top">
                                        <p className="e-font-14 mb-2 color-eerie-black e-montserrat-regular e-line-height-24">
                                            An OTP has been sent to your PAN linked mobile
                                            number.
                                        </p>
                                        <h6 className="e-font-16 mb-3 color-eerie-black e-montserrat-semi-bold e-line-height-24">{MASKED_NUMBER}</h6>
                                        <CustomPINInput id="otp"
                                            autoFocus={true}
                                            pinString={otp}
                                            error={otpError}
                                            postfix="*"
                                            label="Enter OTP"
                                            onSubmit={_submitOTP}
                                            handleChange={_handleOTP} />
                                        <p className="color-charcoal-blue e-montserrat-medium e-font-14 e-line-height-20 mt-4">Didn’t get OTP? <span className={`no-decoration color-rebecca-purple e-link 
                                ${seconds === 0 ?
                                                'cursor-pointer'
                                                : 'disabled'}`}
                                            onClick={_handleResend}>
                                            {seconds === 0 ?
                                                'Resend OTP'
                                                : `Resend OTP in ${seconds < 10 ? `0${seconds}` : seconds}s`}
                                        </span>
                                        </p>

                                    </div>
                                    :
                                    <div className="text-center px-4">
                                        <img src={modalData.image}
                                            alt={modalData.title}
                                            draggable={false}
                                            width={144}
                                            height={144}
                                            className="mx-auto" />
                                        <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-eerie-black mb-0">
                                            {modalData.title}
                                        </h5>

                                        {/* <p className="e-montserrat-medium e-font-16 e-line-height-28 color-charcoal-gray mt-2 mb-0 ">
                                            {modalData.description}
                                        </p> */}
                                    </div>
                        }
                        {
                            screenNumber === 1 ?
                                <div className="row justify-content-center">
                                    <div className="col-6">
                                        <PrimaryButton label="Confirm Switch" className="mt-3 mx-auto w-100"
                                            disabled={apiLoader}
                                            onPress={_handleSubmit} />
                                    </div>
                                </div>
                                :
                                screenNumber === 2 ?
                                    <div className="px-4 mt-3">
                                        <div className="row">
                                            <div className="col-6">
                                                <SecondaryButton label="Cancel"
                                                    cancel="modal"
                                                    className="padding-12px-tb w-100 e-bg-magnolia" />
                                            </div>
                                            <div className="col-6">
                                                <PrimaryButton label="Verify"
                                                    className="padding-12px-tb  w-100"
                                                    disabled={apiLoader}
                                                    onPress={_submitOTP} />
                                            </div>
                                        </div>

                                    </div>
                                    :
                                    screenNumber === 3 ?
                                        <div className="row justify-content-center px-4">
                                            <div className="col-lg-6">
                                                <SecondaryButton label="View Orders"
                                                    className="w-100 padding-12px-tb mt-3"
                                                    cancel="modal"
                                                    onPress={_navigateToOrder} />
                                            </div>

                                            <div className="col-lg-6">
                                                <PrimaryButton label="Portfolio"
                                                    className="w-100 padding-12px-tb mt-3"
                                                    cancel="modal"
                                                    onPress={() => navigate("/portfolio")} />
                                            </div>
                                        </div>
                                        :
                                        null
                        }
                    </div>
                </div>

                {/* Fund Modal */}
                {
                    searchFund === true &&
                    <Fragment>
                        <SearchFunds id={props.summary.mf_id}
                            title="Select fund to switch to"
                            url="/mf/search-switch-in-schemes"
                            backdrop={!searchFund}
                            fund={{
                                fund_name: selectedFundName,
                                mf_id: selectedFundId
                            }}
                            handleChooseFund={_handleChoseFund}
                            handleCloseModal={_handleCloseFundModal} />
                        <div className="position-fixed bg-black opacity-1 w-100 h-100 z-index-4 top-0 start-0">
                        </div>
                    </Fragment>
                }
            </div>
        </Fragment >
    )
}

export default SwitchOrderModal