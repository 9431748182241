/*
*   File : support-detail.js
*   Author : https://evoqins.com
*   Description : Container screen to show details of selected ticket
*   Version : 1.0.0
*/

import { useLocation, useNavigate } from "react-router-dom";
import { Header } from "../../Components/Header"
import { Breadcrumb } from "../../Components/Other"
import { Icon } from "../../Components/Icon";

const TicketDetails = () => {

    const navigate = useNavigate();
    const location = useLocation();

    function _handleNavigate() {
        navigate("/support", {
            state: {
                current_page: location.state.current_page,
                tab_index: location.state.tab_index
            }
        });
    }

    const _handleClick = () => {
        window.open(location.state.data.image, '_blank')
    }

    return (

        <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
            {/* Header */}
            <Header type={2} />
            <div className="row ">
                <div className="col-lg-11 pe-lg-0 padding-144px-bottom">

                    {/* Support list */}
                    <div className="px-4">
                        <Breadcrumb mainPage="Home"
                            currentPage="Support"
                            handleNavigate={_handleNavigate}
                            type={1} />
                        <Icon icon="Arrow-Right"
                            size={32}
                            className="e-transform-left-arrow mb-3 margin-32px-top cursor-pointer"
                            onClick={_handleNavigate} />
                        <div className="row">
                            <div className="col-md-9">
                                <div className="border-1px border-bright-gray p-sm-4 p-3 border-radius-24px">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex gap-8px align-items-center">
                                            <img src={require("../../Assets/Images/Support/ticket-number.png")}
                                                alt="Ticket number"
                                                width={32}
                                                height={32} />
                                            <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-eerie-black mb-0">{location.state.data.ticket_no}</p>
                                        </div>
                                        <div className={`d-flex align-items-center gap-4px border-radius-16px py-1 px-2 ${location.state.data.status_id === 1 ?
                                            "e-bg-persian-blue" :
                                            location.state.data.status_id === 2 ?
                                                "e-bg-dark-tangerine" :
                                                location.state.data.status_id === 3 ?
                                                    "e-bg-green-NCS" :
                                                    location.state.data.status_id === 4 ?
                                                        "e-bg-flirt" :
                                                        "e-bg-bright-gray"}`}>
                                            <div className={`w-6px h-6px rounded-circle bg-white`}></div>
                                            <p className={`e-montserrat-medium e-font-12 e-line-height-18 mb-0 color-white`}>{location.state.data.status}</p>
                                        </div>
                                    </div>
                                    <p className="e-montserrat-medium e-font-14 e-line-height-24 color-outer-space mb-2 margin-30px-top">Subject</p>
                                    <p className="e-montserrat-medium e-font-16 e-line-height-20 color-eerie-black mb-2">{location.state.data.subject}</p>
                                    <p className="e-montserrat-medium e-font-14 e-line-height-24 color-outer-space mb-2 ">Description</p>
                                    <p className="e-montserrat-regular e-font-14 e-line-height-20 color-eerie-black">{location.state.data.description}</p>
                                    {
                                        location.state.data.image !== null &&
                                        <button className="e-customize-btn  e-font-14 e-line-height-18 e-montserrat-semi-bold py-1 px-2 d-flex gap-4px align-items-center" onClick={() => window.open(location.state.data.image, "_blank")}>
                                            <img src={require("../../Assets/Images/Support/support-image.png")}
                                                alt={"Screen shot"}
                                                width={24}
                                                height={24}
                                                draggable={false}
                                                className="cursor-pointer"
                                                onClick={_handleClick} />
                                            <p className="e-montserrat-regular e-font-14 e-line-height-20 margin-2px-bottom color-outer-space text-truncate cursor-pointer truncate-first-line"
                                                onClick={_handleClick}>
                                                {location.state.data.image_name}</p>
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default TicketDetails