/*
*   File : goal-invest.js
*   Author : https://evoqins.com
*   Description : Screen to show goal Information
*   Version : 1.0.0
*/

// import packages
import { Fragment, useEffect, useState } from "react";
import { Modal } from "bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useRazorpay, } from "react-razorpay";

// import components
import { HorizontalStackedBar } from "../../Components/Charts";
import { Icon } from "../../Components/Icon";
import { DataTableContainer } from "../../Components/Table";
import { InvestmentStatusModal, CustomFundAllocation, SIPDateModal, ConfirmInvest, PrimaryBasketStatusModal, RiskKycConfirmationModal, RiskConfirmationModal } from "../../Components/Modal";
import { PrimaryButton } from "../../Components/Buttons";
import { _formatSip, _findNearestDate, _validatingKycStatus, _getTomorrowDate } from "../../Helper/helper";
import { CustomSelectBox, RadioGroup } from "../../Components/FormElements";
import { EmptyScreen, Loader } from "../../Components/Other";
import { PassiveActiveComparison } from "../Others";

// import color
import Color from '../../Styles/color.module.scss';

// import services
import APIService from "../../Services/api-service";
import { CustomTabBar } from "../../Components/Tab";
import { useSelector } from "react-redux";
import { ConsentCard } from "../../Components/Cards";

// Table style
const TABLE_STYLE = {
    table: {
        style: {
            borderRadius: "0px",
            backgroundColor: 'transparent'
        },
    },
    headRow: {
        style: {
            backgroundColor: 'transparent',
            borderBottomColor: 'transparent',
            paddingLeft: "0px",
            // color: Color.primary,
            fontFamily: "Montserrat-SemiBold",
            fontSize: "14px",
            lineHeight: "24px",
            paddingTop: "12px",
            paddingBottom: '12px',
        }
    },
    headCells: {
        style: {
            padding: "0px"
        }
    },
    rows: {
        style: {
            '&:not(:last-child)': {
                borderBottom: `1px solid ${Color.onyx}`
            },
            padding: '12px 0px 12px 0',
            // paddingTop: "12px",
            backgroundColor: 'transparent',
        },
    },
    cells: {
        style: {
            paddingLeft: '0px !important',
            paddingRight: '0px !important',
        }
    }

};

const TABLE_STYLE_RESPONSIVE = {
    table: {
        style: {
            borderRadius: "0px",
            backgroundColor: 'transparent'
        },
    },
    headRow: {
        style: {
            display: "none"
        }
    },
    headCells: {
        style: {
            padding: "0px"
        }
    },
    rows: {
        style: {
            '&:not(:last-child)': {
                borderBottom: `1px solid ${Color.onyx}`
            },
            padding: '12px 0px 12px 0',
            // paddingTop: "12px",
            backgroundColor: 'transparent',
        },
    },
    cells: {
        style: {
            paddingLeft: '0px !important',
            paddingRight: '0px !important',
        }
    }

};


const FUND_TYPES = [
    {
        label: "Allocation",
        value: 1
    },
    {
        label: "Amount",
        value: 2
    },
]

const TAB_DATA = [
    {
        label: "Active",
        id: 1
    },
    {
        label: "Passive",
        id: 2
    }
]

const FIRST_INSTALLMENT = [{
    id: 1,
    name: "Yes"
},
{
    id: 2,
    name: "No"
}]

const GoalInvest = () => {
    const { Razorpay } = useRazorpay();

    const location = useLocation();
    const navigate = useNavigate();
    const SIGNUP_PROGRESS = useSelector(state => state.Reducer.SIGNUP_PROGRESS);
    const PROFILE_DETAILS = useSelector(state => state.Reducer.PROFILE_DETAILS);
    const [investmentSuggestion, setInvestmentSuggestion] = useState({});
    const [showComparison, setShowComparison] = useState(false);

    const [selectedDate, setSelectedDate] = useState(_getTomorrowDate());
    const [selectedDateString, setSelectedDateString] = useState(_formatSip(_getTomorrowDate()));
    const [toggleDateModal, setToggleDateModal] = useState(false);
    const [paymentType, setPaymentType] = useState(null);
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [showFundAllocation, setShowFundAllocation] = useState(false);
    const [allocationSubmitted, setAllocationSubmitted] = useState(false);
    const [selectedBasket, setSelectedBasket] = useState(1);
    const [activeFunds, setActiveFunds] = useState([]);
    const [passiveFunds, setPassiveFunds] = useState([]);
    const [loader, setLoader] = useState(true);
    const [modalData, setModalData] = useState({});

    const [fundType, setFundType] = useState(FUND_TYPES[0]);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [isFirstInstallment, setIsFirstInstallment] = useState(FIRST_INSTALLMENT[0].id);


    const [showOtpModal, setShowOtpModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [modalLoader, setModalLoader] = useState(false);
    const [paymentModalData, setPaymentModalData] = useState({});

    const [riskCheck, setRiskCheck] = useState(false);
    const [riskKycCheck, setRiskKycCheck] = useState(false);


    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        if (location.state !== null && Object.keys(PROFILE_DETAILS).length > 0) {
            _getInvestmentSuggestions()
        }

        // eslint-disable-next-line
    }, [PROFILE_DETAILS]);

    useEffect(() => {
        if (showOtpModal === true) {
            const modal = new Modal(document.getElementById("confirm-invest"));
            modal.show()
        }
    }, [showOtpModal]);

    useEffect(() => {
        if (showFundAllocation === true) {
            const modal = new Modal(document.getElementById("fund-allocation"));
            modal.show()
        }
    }, [showFundAllocation]);

    useEffect(() => {
        if (allocationSubmitted === true) {
            const modal = new Modal(document.getElementById("investment-status"));
            modal.show()
        }
    }, [allocationSubmitted]);

    useEffect(() => {
        if (toggleDateModal === true) {
            const modal = new Modal(document.getElementById("sip-date"), {});
            modal.show();
        }
    }, [toggleDateModal]);

    useEffect(() => {
        if (showSuccessModal === true) {
            const modal = new Modal(document.getElementById("goal-success"));
            modal.show()
        }
    }, [showSuccessModal]);

    useEffect(() => {
        if (riskKycCheck === true) {
            const modal = new Modal(document.getElementById("risk-kyc-check"));
            modal.show();
        }
    }, [riskKycCheck]);

    useEffect(() => {
        if (riskCheck === true) {
            const modal = new Modal(document.getElementById("risk-confirm"));
            modal.show();
        }
    }, [riskCheck]);

    useEffect(() => {

        if (Object.keys(investmentSuggestion).length !== 0) {

            if (selectedBasket === 1) {
                setModalData(Object.assign({}, investmentSuggestion.suggestion.active_portfolio.allocation_data));
            }
            else {
                setModalData(Object.assign({}, investmentSuggestion.suggestion.passive_portfolio.allocation_data));
            }
        }

    }, [investmentSuggestion, selectedBasket]);


    // Table columns
    const TABLE_COLUMNS = [
        {
            name: "Fund Name",
            cell: row => <div className="d-flex align-items-center gap-8px pe-2" onClick={() => navigate("/explore/detail", {
                state: {
                    data: {
                        mf_id: row.mf_id
                    }
                }
            })}>
                <img src={row.image}
                    width={36}
                    height={36}
                    alt="fund_image"
                    draggable={false}
                    className="border-1px border-light-gray border-radius-10px bg-white d-sm-block d-none" />
                <img src={row.image}
                    width={28}
                    height={28}
                    alt="fund_image"
                    draggable={false}
                    className="border-1px border-light-gray border-radius-8px bg-white d-sm-none d-block" />
                <span className="color-eerie-black e-font-14 e-xs-font-12 e-line-height-18 e-montserrat-medium">{row.name}</span>
            </div>,
            sortable: false,
            // minwidth: "300px"
        },
        {
            name: fundType.value === 1 ? "Allocation(%)" : "Amount",
            cell: row => <span className="color-eerie-black e-font-14 e-xs-font-12 e-line-height-24 e-montserrat-semi-bold">{fundType.value === 1 ? `${row.allocation}%` : row.amount}</span>,
            sortable: false,
            right: true,
            width: "max-content"
        }
    ]

    // Update selected basket
    function _handleBasket(value) {
        setSelectedBasket(value)
    }

    // Show comparison toggle
    function _handleToggle() {
        setShowComparison(!showComparison);
    }

    // show sip date modal
    function _handleCloseModal(status) {
        setToggleDateModal(status);
    }

    // handle sip date
    function _handleSelectDate(date, dateString) {
        setSelectedDate(date);
        setSelectedDateString(dateString)
    }

    // handle payment type
    function _handlePayment(item) {
        setPaymentType(item);
    }

    // show allocation modal 
    function _openAllocationModal() {
        setShowFundAllocation(true);
    }

    function _closeAllocationModal() {
        setShowFundAllocation(false);
    }


    // close success modal
    function _handleConfirmModal() {
        setAllocationSubmitted(false);
    }

    function _confirmRiskKyc(status) {
        setRiskKycCheck(status)
    }

    function _handleNavigateToScreen() {
        navigate("/kyc");
    }

    // handle submit
    const _handleSubmit = () => {
        if (_validatingKycStatus(SIGNUP_PROGRESS.kyc_data.status) === 1) {
            _confirmRiskKyc(true);
        } else if (_validatingKycStatus(SIGNUP_PROGRESS.kyc_data.status) === 4) {
            navigate("/");
        }
        else if (_validatingKycStatus(SIGNUP_PROGRESS.kyc_data.status) === 2) {
            _confirmRiskKyc(true);
        }
        else {

            if (location.state.investment_option === "sip_only" || location.state.investment_option === "sip_and_lumpsum") {
                if (investmentSuggestion.mandate_status === false) {
                    setSubmitLoader(true);
                    _createMandate();
                } else {

                    setSubmitLoader(true);
                    _confirmInvest();

                }
            }
            else {

                setSubmitLoader(true);
                _confirmInvest();

            }
        }
    }

    const _handleClose = () => {
        setShowOtpModal(false);
    }
    const _handleCloseSuccessModal = () => {
        setShowSuccessModal(false);
        setPaymentModalData({});
    }

    const _fundBasket = (type) => {
        setFundType(type);
    }

    // API - update funds
    const _updateFunds = (basket_type, lookup_ids) => {
        const url = "/goal/update-funds";
        const request = {
            goal_id: location.state.goal_id,
            basket_type: basket_type,
            lookup_ids: lookup_ids
        }

        APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                setAllocationSubmitted(true);
                setInvestmentSuggestion(response.data);
                setSelectedDate(_findNearestDate(response.data.milestone_details.sip_dates, selectedDate));
                const date_string = _formatSip(_findNearestDate(response.data.milestone_details.sip_dates, selectedDate))
                setSelectedDateString(date_string);
                let data = response.data;

                const combined_active = [...Object.values(data.suggestion.active_portfolio.allocation_data).flat()];
                const combined_passive = [...Object.values(data.suggestion.passive_portfolio.allocation_data).flat()];

                setActiveFunds(combined_active);
                setPassiveFunds(combined_passive);
            } else {
                toast.error(response.message, {
                    type: "error",
                });
            }
            setShowFundAllocation(false);
        });
    }

    // API - Get Investment Suggestions
    const _getInvestmentSuggestions = (mandate_check = false) => {
        const url = "/goal/investment-suggestion";
        const request = {
            goal_id: location.state.goal_id,
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setInvestmentSuggestion(response.data);
                setPaymentType(response.data.payment_types[0]);
                setSelectedBasket(response.data.basket_type === "PASSIVE" ? 2 : 1)
                setSelectedDate(_findNearestDate(response.data.milestone_details.sip_dates, selectedDate));
                let data = response.data;

                const combined_active = [...Object.values(data.suggestion.active_portfolio.allocation_data).flat()];
                let combined_passive = [];
                if (data.suggestion.passive_portfolio.allocation_data !== null) {

                    combined_passive = [...Object.values(data.suggestion.passive_portfolio.allocation_data).flat()];
                }

                setActiveFunds(combined_active);
                setPassiveFunds(combined_passive);

                const date_string = _formatSip(_findNearestDate(response.data.milestone_details.sip_dates, selectedDate))
                setSelectedDateString(date_string);
                setLoader(false);

                if (mandate_check === true) {
                    if (response.data.mandate_status === true) {
                        toast.success("Mandates Created Successfully", {
                            type: "success",
                        });
                    }
                    else {
                        toast.error("Mandate Creation Failed", {
                            type: "error",
                        });
                    }
                } else {
                    if (response.data.investment_style_based_suggestion === false) {
                        setRiskCheck(true);
                    }
                }
            }
            else {
                // toast.dismiss();
                // toast.error(response.message, {
                //     type: "error",
                // });
                navigate(location.pathname, {
                    state: {
                        goal_id: location.state.goal_id,
                        step: 1,
                        request_data: location.state.request_data,
                        investment_option: location.state.investment_option,
                    }
                })
            }
            setTimeout(() => {
                setSubmitLoader(false);
            }, 3000);
        })
    }

    const _handleFirstInstallment = (id) => {
        setIsFirstInstallment(id);
    }

    const InvestmentTypeTitle = (props) => {
        return (
            <div className="d-flex justify-content-between align-items-center" >
                <div className="d-flex align-items-center gap-8px">
                    <img src={props.type === 1 ?
                        require("../../Assets/Images/Goal/basket.svg").default
                        :
                        require("../../Assets/Images/Goal/passive-icon.png")} alt={"Basket"}
                        draggable={false}
                        width={28}
                        height={28} />
                    <span className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black">
                        {props.label}
                    </span>
                </div>
                <Icon icon={selectedBasket === props.type ? 'radio-checked' : 'radio-unchecked'}
                    size={24} />
            </div>
        )
    }


    // API - Create mandate
    const _createMandate = () => {
        const url = "/mandate/check";
        const request = {
            create: true,
            platform: "web",
            mandate_type: paymentType.slug === "UPI" ? "UPI" : "E_MANDATE"
        }
        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                let mandate_window = window.open(response.data.mandate_url, '_blank', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=800,height=600');
                if (!mandate_window || typeof mandate_window.closed == 'undefined') {
                    toast.dismiss();
                    toast.error("Please enable pop-up permission");
                    setSubmitLoader(false);
                    return;
                };
                let timer = setInterval(function () {
                    if (mandate_window.closed) {
                        clearInterval(timer);
                        _getInvestmentSuggestions(true);
                    }
                }, 1000);
            }
            else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
                setSubmitLoader(false);
            }
        })
    }
    const _buildRequest = () => {
        const request = {
            type: null,
            basket_type: selectedBasket === 1 ? "ACTIVE" : "PASSIVE",
            goal_id: investmentSuggestion.goal_type_id,
            sip: investmentSuggestion.milestone_details.sip !== 0 ? {
                amount: investmentSuggestion.milestone_details.sip,
                date: selectedDate,
                get_first_installment: isFirstInstallment === 1 ? true : false
            } : null,
            payment_type: paymentType.slug,
            platform: "web",
            lumpsum: investmentSuggestion.milestone_details.lumpsum !== 0 ? {
                amount: investmentSuggestion.milestone_details.lumpsum
            } : null
        }
        return request;
    };
    // API - confirm invest
    const _confirmInvest = () => {
        const url = "/goal/confirm-invest";
        APIService(true, url, _buildRequest()).then((response) => {
            if (response.status_code === 200) {
                setShowOtpModal(true);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
            }
            setSubmitLoader(false);
        })
    }
    // API - verify otp
    const _verifyOTP = async (otp) => {
        setModalLoader(true);
        const url = "/goal/invest";
        const request = {
            ..._buildRequest(),
            otp: otp
        };
        try {
            const response = await APIService(true, url, request);
            if (response.status_code === 200) {
                if (response.data.payment_url) {
                    setShowOtpModal(false);
                    setModalLoader(false);
                    if (response.data.payment_provider === "RAZORPAY") {
                        _getRazorpay(response.data.rp_info.razorpay, response.data.payment_id, response.data.order_id);
                        return true
                    } else {
                        let payment_url = window.open(response.data.payment_url, '_blank', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=800,height=600');
                        if (!payment_url || typeof payment_url.closed == 'undefined') {
                            toast.dismiss();
                            toast.error("Please enable pop-up permission");
                            setModalLoader(false);
                            return false;
                        }

                        return await new Promise((resolve) => {
                            let timer = setInterval(function () {
                                if (payment_url.closed) {
                                    clearInterval(timer);
                                    _getPaymentStatus(response.data.payment_id, response.data.order_id);
                                    setModalLoader(false);
                                    resolve(true);
                                }
                            }, 1000);
                        });
                    }


                } else {
                    setShowSuccessModal(true);
                    if (response.data.invested === true) {
                        setPaymentModalData({
                            status: response.data.invested,
                            image: require("../../Assets/Images/Modal/success.png"),
                            title: "SIP scheduled successfully",
                            type: 1,
                            description: "Your SIP order has been scheduled successfully. It will auto debit on next scheduled date."
                        });
                    } else {
                        setPaymentModalData({
                            status: response.data.invested,
                            image: require("../../Assets/Images/Modal/failed.png"),
                            title: response.data.message,
                            type: 0,
                            description: response.data.failed_reason ? response.data.failed_reason : "Your payment has been failed. Please try again."
                        });
                    }
                    setModalLoader(false);
                    return true

                }
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                });
                setModalLoader(false);
                return false;
            }
        } catch (error) {
            console.error("Error verifying OTP:", error);
            setModalLoader(false);
            return false;
        }
    };

    async function _getRazorpay(data, payment_id, order_id) {
        let paymentFailed = false;
        var options = {
            description: 'Payment for fund',
            image: process.env.REACT_APP_LOGO_URL,
            currency: 'INR',
            key: data.key,
            amount: data.amount,
            name: process.env.REACT_APP_COMPANY_NAME,
            order_id: data.order_id, // Replace this with an order_id created using Orders API.
            handler: (response) => {
                setShowSuccessModal(true);
                setPaymentModalData({
                    order_id: order_id,
                    status: true,
                    image: require("../../Assets/Images/Modal/success.png"),
                    title: "Payment successful",
                    type: 1,
                    description: "Your payment has been successful and the order has been submitted to the KRA. The allocation will be reflected in T+2 days."
                })
                _getPaymentStatus(payment_id, order_id, true);
                // Handle successful payment here
            },
            prefill: {
                email: data.email,
                contact: data.contact,
                name: PROFILE_DETAILS.name,
            },
            theme: { color: Color.rebecca_purple },
            modal: {
                ondismiss: () => {
                    if (!paymentFailed) {
                        setShowSuccessModal(true);
                        _getPaymentStatus(payment_id, order_id, false);
                    }
                    // This function is triggered when the payment window is closed

                    // Optionally, you can handle failed payment logic here, e.g., show a failed message or retry the payment.
                }
            }
        };

        const razorpayInstance = new Razorpay(options);
        razorpayInstance.on('payment.failed', function (response) {
            paymentFailed = true;
            setShowSuccessModal(true);
            setPaymentModalData({
                status: false,
                image: require("../../Assets/Images/Modal/failed.png"),
                title: "Payment failed",
                type: 0,
                description: response.error.description
            });
        });
        razorpayInstance.open();

    };
    // API - payment status
    const _getPaymentStatus = (payment_id, order_id, is_razorpay = false) => {
        const url = "/payment/check-status";
        const request = {
            payment_id: payment_id
        }
        APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                setShowSuccessModal(true);
                if (is_razorpay === false) {
                    if (response.data.status === true) {
                        setPaymentModalData({
                            order_id: order_id,
                            status: response.data.status,
                            image: require("../../Assets/Images/Modal/success.png"),
                            title: "Payment successful",
                            type: 1,
                            description: "Your payment has been successful and the order has been submitted to the KRA. The allocation will be reflected in T+2 days."
                        })
                    }
                    else {
                        setPaymentModalData({
                            status: response.data.status,
                            image: require("../../Assets/Images/Modal/failed.png"),
                            title: response.data.message,
                            type: 0,
                            description: response.data.failed_reason ? response.data.failed_reason : "Your payment has been failed. Please try again."
                        })
                    }
                }
            } else {
                toast.error(response.message, {
                    type: "error",
                });
            }
        });
    }

    const _handleSecondaryButton = () => {

        document.body.removeAttribute("style")

        const _navigateToOrderPageDetail = (path) => {
            navigate(path, {
                state: {
                    id: paymentModalData.order_id,
                    paymentTypes: [],
                    orderStatus: [],
                    orderTypes: [],
                    startDate: "DD-MM-YYYY",
                    endDate: "DD-MM-YYYY",
                    type: 1,
                    page: 1
                }
            });
        };


        if (isFirstInstallment === 2 && location.state.investment_option === "sip_only") {
            navigate("/systematic-transactions")
        } else {
            if (paymentModalData.type === 0) {
                _navigateToOrderPageDetail("/orders");
            } else {
                _navigateToOrderPageDetail("/orders");
            }
        }
    }

    const _getButtonLabel = () => {

        if (location.state.investment_option === "sip_only" || location.state.investment_option === "sip_and_lumpsum") {
            if (investmentSuggestion.mandate_status === false) {
                return "Create mandate";
            } else {
                return isFirstInstallment === 1 ? "Verify OTP & Pay" : "Verify OTP & Schedule";
            }
        } else {
            return "Verify OTP & Pay";

        }

    };

    return (
        <>
            {
                loader === true ?
                    <Loader height="h-80vh" />
                    :
                    <Fragment>
                        <div className="row margin-48px-top">
                            <div className="col-md-7">
                                <div className="border-bottom-1px border-onyx pb-4">

                                    {/* customize investment category options - Passive and Active */}
                                    <div className="d-flex flex-wrap justify-content-between align-items-center pb-3 mb-2">
                                        <h3 className="e-font-16 e-line-height-48 color-eerie-black e-montserrat-semi-bold mb-0">Fund Breakdown</h3>
                                        <button type="submit" className="e-customize-btn e-font-14 e-line-height-18 e-montserrat-semi-bold padding-12px-tb px-3 d-flex gap-8px align-items-center"
                                            onClick={_openAllocationModal}>
                                            <img src={require("../../Assets/Images/Global/custominse.svg").default}
                                                draggable={false}
                                                alt="customize" />
                                            Customize
                                        </button>
                                    </div>

                                    {/* bar chart */}
                                    <HorizontalStackedBar allocations={selectedBasket === 1 ?
                                        investmentSuggestion.suggestion.active_portfolio.graph_data :
                                        investmentSuggestion.suggestion.passive_portfolio.graph_data} />
                                </div>
                                {/* {investmentSuggestion.investment_style_based_suggestion === true && <div className="padding-12px-all e-bg-ivory-bridge border-radius-12px d-flex gap-8px mt-3">
                                    <span>
                                        <Icon icon="info-circle-yellow"
                                            size={18} />
                                    </span>
                                    <p className="e-montserrat-medium e-font-14 e-line-height-24 color-vivid-orange mb-0">
                                        Although your risk profile is ({PROFILE_DETAILS.investment_style_text}), the funds for your ({investmentSuggestion.milestone_details.name}) are based on the remaining tenure. Funds chosen by risk profile may or may not achieve the desired result.
                                    </p>
                                </div>} */}

                            </div>
                        </div>

                        <h4 className="e-font-16 e-line-height-24 color-eerie-black e-montserrat-semi-bold mb-0 mt-3">Fund Allocations</h4>
                        <div className="d-flex flex-wrap justify-content-between align-items-center gap-16px mt-3 mb-4">
                            <h4 className="e-font-16 e-line-height-14 color-eerie-black e-montserrat-semi-bold mb-0">Fund Baskets</h4>
                            <div className="d-sm-flex d-none  e-bg-lavender-frost border-radius-30px p-1">
                                {
                                    FUND_TYPES.map((item, index) => {
                                        return (
                                            <span key={index}
                                                className={`padding-6px-tb px-3 e-font-14 e-montserrat-medium e-line-height-20 color-eerie-black cursor-pointer ${item.value === fundType.value ? "box-shadow-36 border-radius-28px bg-white" : ""}`}
                                                onClick={() => _fundBasket(item)}>
                                                {item.label}
                                            </span>
                                        )
                                    })
                                }

                            </div>
                            <div className="d-sm-none d-flex justify-content-end">
                                <CustomSelectBox options={FUND_TYPES}
                                    value={fundType}
                                    type={2}
                                    isSearchable={false}
                                    className="d-sm-none d-block"
                                    onSelectChange={(select_value) => _fundBasket(select_value)} />
                            </div>
                            <div className="col-12 d-sm-none">
                                <CustomTabBar data={TAB_DATA}
                                    selectedTab={selectedBasket}
                                    onSelectTab={_handleBasket} />

                                <div className="row mt-3">
                                    {
                                        selectedBasket === 1 ?
                                            <div className="col-md-6">
                                                <div className={`p-3 border-1px border-radius-16px h-100 ${activeFunds.filter(item => item.selected === true).length !== 0 && "cursor-pointer"} ${selectedBasket === 1 ? "border-purple e-bg-light-lavender" : "border-bright-gray"} h-100`}
                                                    onClick={() => {
                                                        if (activeFunds.filter(item => item.selected === true).length === 0) {
                                                            return
                                                        }
                                                        _handleBasket(1)
                                                    }
                                                    }>

                                                    {/* investment type title */}
                                                    <InvestmentTypeTitle label={'Active'}
                                                        type={1}
                                                        selectedBasket={selectedBasket} />

                                                    {/* active fund list */}
                                                    <div className="mt-2">
                                                        {
                                                            activeFunds.filter(item => item.selected === true).length === 0 ?
                                                                <Fragment>
                                                                    <EmptyScreen className="mt-5"
                                                                        description=""
                                                                        width={120}
                                                                        title="No Funds found"
                                                                        type={7}
                                                                    />
                                                                </Fragment>
                                                                :
                                                                <DataTableContainer columns={TABLE_COLUMNS}
                                                                    data={[...activeFunds.filter(item => item.selected === true)]}
                                                                    customStyles={TABLE_STYLE_RESPONSIVE} />
                                                        }
                                                    </div>

                                                </div>
                                            </div>
                                            :
                                            <div className="col-md-6">
                                                <div className={`p-3 border-1px border-radius-16px h-100 ${passiveFunds.filter(item => item.selected === true).length !== 0 && `cursor-pointer ${selectedBasket === 2 ? "border-purple e-bg-light-lavender" : "border-bright-gray"}`} border-bright-gray`}
                                                    onClick={() => {
                                                        if (passiveFunds.filter(item => item.selected === true).length === 0) {
                                                            return
                                                        }
                                                        _handleBasket(2)
                                                    }
                                                    }
                                                >

                                                    {/* investment type title */}


                                                    {/* passive fund list */}
                                                    <div className="mt-2">
                                                        {
                                                            passiveFunds.filter(item => item.selected === true).length === 0 ?
                                                                <div className="row justify-content-center">
                                                                    <div className="col-6">
                                                                        <EmptyScreen className="mt-5"
                                                                            description=""
                                                                            width={120}
                                                                            type={7}
                                                                        />
                                                                    </div>
                                                                    <p className="e-montserrat-semi-bold e-font-16 e-line-height-24 color-charleston-green mt-2 mb-1 text-center px-3">No Passive Allocations found</p>
                                                                    <p className="e-montserrat-regular e-font-14 e-line-height-24 color-dark-grayish-blue mb-0 text-center px-3">Passive funds for this tenure/time frame are not available in Mutual fund universe. Please proceed with Active basket.</p>
                                                                </div>
                                                                :
                                                                <Fragment>
                                                                    <InvestmentTypeTitle label={'Passive'}
                                                                        type={2}
                                                                        selectedBasket={selectedBasket} />
                                                                    <DataTableContainer columns={TABLE_COLUMNS}
                                                                        data={passiveFunds.filter(item => item.selected === true)}
                                                                        customStyles={TABLE_STYLE_RESPONSIVE} />
                                                                </Fragment>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                    }


                                </div>
                            </div>
                        </div>
                        <div className="d-sm-block d-none">

                            <div className="row row-gap-16px ">
                                {/* -------------------- active funds ------------- */}
                                <div className="col-md-6">
                                    <div className={`p-4 border-1px border-radius-16px h-100 ${activeFunds.filter(item => item.selected === true).length !== 0 && "cursor-pointer"} ${selectedBasket === 1 ? "border-purple e-bg-light-lavender" : "border-bright-gray"}`}
                                        onClick={() => {
                                            if (activeFunds.filter(item => item.selected === true).length === 0) {
                                                return
                                            }
                                            _handleBasket(1)
                                        }
                                        }>

                                        {/* investment type title */}
                                        <InvestmentTypeTitle label={'Active'}
                                            type={1}
                                            selectedBasket={selectedBasket} />

                                        {/* active fund list */}
                                        <div className="padding-12px-top">
                                            {
                                                activeFunds.filter(item => item.selected === true).length === 0 ?
                                                    <Fragment>
                                                        <EmptyScreen className="mt-5"
                                                            description=""
                                                            width={120}
                                                            type={7}
                                                            title="No Funds found"
                                                        />
                                                    </Fragment>
                                                    :
                                                    <DataTableContainer columns={TABLE_COLUMNS}
                                                        data={[...activeFunds.filter(item => item.selected === true)]}
                                                        customStyles={TABLE_STYLE} />
                                            }
                                        </div>

                                    </div>
                                </div>

                                {/* -------------------- passive funds ------------- */}
                                <div className="col-md-6">
                                    <div className={`p-4 border-1px border-radius-16px h-100 ${passiveFunds.filter(item => item.selected === true).length !== 0 && "cursor-pointer"} ${selectedBasket === 2 ? "border-purple e-bg-light-lavender" : "border-bright-gray"}`}
                                        onClick={() => {
                                            if (passiveFunds.filter(item => item.selected === true).length === 0) {
                                                return
                                            }
                                            _handleBasket(2)
                                        }
                                        }
                                    >

                                        {/* investment type title */}
                                        <InvestmentTypeTitle label={'Passive'}
                                            type={2}
                                            selectedBasket={selectedBasket} />

                                        {/* passive fund list */}
                                        <div className="padding-12px-top">
                                            {
                                                passiveFunds.filter(item => item.selected === true).length === 0 ?
                                                    <div className="row justify-content-center">
                                                        <div className="col-8">
                                                            <EmptyScreen className="mt-3"
                                                                description=""
                                                                width={120}
                                                                type={7}
                                                            />
                                                        </div>
                                                        <p className="e-montserrat-semi-bold e-font-16 e-line-height-24 color-charleston-green mt-2 mb-1 text-center px-3">No Passive Allocations found</p>
                                                        <p className="e-montserrat-regular e-font-16 e-line-height-24 color-dark-grayish-blue mb-0 text-center px-3">Passive funds for this tenure/time frame are not available in Mutual fund universe. Please proceed with Active basket.</p>
                                                    </div>
                                                    :
                                                    <DataTableContainer columns={TABLE_COLUMNS}
                                                        data={passiveFunds.filter(item => item.selected === true)}
                                                        customStyles={TABLE_STYLE} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-7">
                                {/* -----------comparison------------- */}
                                <div className={`mt-sm-4 mt-0 border-tb-1px border-light-periwinkle cursor-pointer ${showComparison ? 'pb-0 e-bg-light-white' : ''}`} >
                                    <div className={`${showComparison ? 'pb-0 p-sm-4 p-3' : 'p-sm-4 p-3'} d-flex align-items-center justify-content-between`} onClick={_handleToggle}>
                                        <div className="d-flex align-items-center">
                                            <img src={require("../../Assets/Images/Goal/compare.svg").default}
                                                alt="Compare"
                                                draggable={false} />
                                            <span className="e-font-14 e-line-height-20 e-montserrat-medium color-eerie-black ms-2">Compare Active Vs Passive Baskets</span>
                                        </div>
                                        <Icon icon="header-drop-down" size={20}
                                            color="#3C3D3C"
                                            className={showComparison ? "rotate-up float-end" : "float-end"} />
                                    </div>
                                    {
                                        showComparison ?
                                            <PassiveActiveComparison goal_id={location.state.goal_id} />

                                            :
                                            null
                                    }
                                </div>

                                {/* ------- milestone details ----------- */}
                                <div className="mt-4">
                                    <h5 className="e-font-16 e-line-height-20 color-eerie-black e-montserrat-semi-bold mb-4">Milestone Details</h5>
                                    <div className="e-bg-magnolia box-shadow-33 py-4 border-radius-24px">
                                        <div className="box-shadow-3b bg-white mx-4 border-radius-46px padding-12px-all d-flex gap-8px align-items-center e-font-14 e-line-height-20 e-montserrat-medium color-eerie-black">
                                            <img src={investmentSuggestion.milestone_details.image}
                                                alt={investmentSuggestion.milestone_details.name}
                                                draggable={false}
                                                width={40}
                                                height={40}
                                                className="rounded-circle" />
                                            Milestone: <span className="e-montserrat-semi-bold e-font-16">{investmentSuggestion.milestone_details.name}</span>
                                        </div>
                                        <div className="d-flex align-items-sm-center align-items-start padding-12px-top px-4 gap-8px color-eerie-black pb-3 border-bottom-1px border-onyx">
                                            <img src={require('../../Assets/Images/Goal/investment-type.svg').default}
                                                alt="goal"
                                                draggable={false} />
                                            <p className="e-font-14 e-line-height-26 e-montserrat-medium mb-0">Investment type: <span className="e-montserrat-semi-bold e-font-16">{investmentSuggestion.milestone_details.investment_text}</span></p>
                                        </div>
                                        <div className="px-4 pt-3">
                                            <div className="row row-gap-16px mb-3">
                                                {
                                                    investmentSuggestion.milestone_details.lumpsum_text !== null &&
                                                    <div className="col-xl-6">
                                                        <div className="d-flex align-items-sm-center align-items-start gap-8px  color-eerie-black">
                                                            <img src={require('../../Assets/Images/Goal/wallet.svg').default}
                                                                alt="goal"
                                                                draggable={false} />
                                                            <p className="e-font-14 e-line-height-22 e-montserrat-medium mb-0">
                                                                Lumpsum investment amount:  <span className="e-montserrat-bold color-green e-font-16">{investmentSuggestion.milestone_details.lumpsum_text}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    investmentSuggestion.milestone_details.sip_text !== null &&
                                                    <div className="col-xl-6">
                                                        <div className="d-flex align-items-center gap-8px  color-eerie-black">
                                                            <img src={require('../../Assets/Images/Goal/amount.svg').default}
                                                                alt="goal"
                                                                draggable={false} />
                                                            <p className="e-font-14 e-line-height-22 e-montserrat-medium mb-0">
                                                                SIP amount:  <span className="e-montserrat-bold color-green e-font-16">{investmentSuggestion.milestone_details.sip_text}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="row row-gap-16px align-items-center">
                                                <div className="col-xl-6">
                                                    <div className="d-flex align-items-center gap-8px color-eerie-black">
                                                        <img src={require('../../Assets/Images/Goal/amount.svg').default}
                                                            alt="goal"
                                                            draggable={false} />
                                                        <p className="e-font-14 e-line-height-22 e-montserrat-medium mb-0">
                                                            Fund basket:  <span className="e-montserrat-bold color-green e-font-16">{selectedBasket === 1 ? "Active" : "Passive"}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                {
                                                    investmentSuggestion.milestone_details.sip_text !== null &&
                                                    <div className="col-xl-6">
                                                        <div className="d-flex align-items-center gap-8px cursor-pointer" onClick={() => _handleCloseModal(true)}>
                                                            <span className="e-font-14 e-line-height-24 e-montserrat-medium color-eerie-black text-nowrap">
                                                                SIP date:
                                                            </span>
                                                            <div className="padding-14px-tb px-3 bg-white box-shadow-charleston border-1px border-light-grayish-blue d-flex justify-content-between border-radius-12px">
                                                                <p className="mb-0 w-max-content">
                                                                    <span className="color-rebecca-purple e-font-14 e-montserrat-semi-bold e-line-height-18">{selectedDateString} </span>
                                                                    <span className="color-cadet-gray e-montserrat-medium e-font-12 e-line-height-20">
                                                                        of every month
                                                                    </span>
                                                                    <Icon icon="header-drop-down" size={20}
                                                                        color="#662D91"
                                                                        className={toggleDateModal ? "rotate-up ms-2" : "ms-2"} />
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    (location.state.investment_option === "sip_only") &&
                                                    <Fragment>

                                                        <div className="col-12 mt-3">
                                                            <p className={`e-montserrat-medium color-dark-charcoal e-font-14 e-line-height-18 cursor-text mb-0 bg-transparent`}>
                                                                Pay first installment now?
                                                            </p>
                                                            <RadioGroup data={FIRST_INSTALLMENT}
                                                                selected={isFirstInstallment}
                                                                handleSelect={_handleFirstInstallment} />
                                                            {
                                                                isFirstInstallment === 1 ?
                                                                    <div className="d-flex align-items-center gap-4px e-bg-ivory-bridge p-2 pe-4 border-radius-12px mt-1">
                                                                        <Icon icon="info-circle-yellow"
                                                                            size={18}
                                                                            color="#C75802" />
                                                                        <p className="e-montserrat-medium e-font-12 e-line-height-18 color-dark-golden mb-0">
                                                                            Your first SIP will be deducted immediately.
                                                                        </p>
                                                                    </div>
                                                                    :
                                                                    isFirstInstallment === 2 ?
                                                                        <div className="d-flex align-items-center gap-8px e-bg-ivory-bridge p-2 pe-4 border-radius-12px mt-1">
                                                                            <Icon icon="info-circle-yellow"
                                                                                size={18}
                                                                                color="#C75802" />
                                                                            <p className="e-montserrat-regular e-font-12 e-line-height-18 color-dark-golden mb-0">Your first SIP shall start from the selected date.</p>
                                                                        </div>
                                                                        :
                                                                        null
                                                            }
                                                        </div>
                                                    </Fragment>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {/* ------- payment details ----------- */}
                                {
                                    investmentSuggestion.mandate_status === false && (location.state.investment_option === "sip_only" || location.state.investment_option === "sip_and_lumpsum") ?
                                        <Fragment>
                                            <h5 className="e-font-16 e-line-height-20 color-eerie-black e-montserrat-semi-bold mt-4">
                                                Mandate Method
                                            </h5>
                                            {
                                                investmentSuggestion.payment_types.map((item, index) => {
                                                    return (
                                                        <div key={index}
                                                            className={`p-3 d-flex align-items-center justify-content-between border-1px cursor-pointer mt-3 border-radius-16px  ${item.id === paymentType.id ? "border-purple e-bg-light-lavender" : "border-bright-gray"}`} onClick={() => _handlePayment(item)}>
                                                            <div className="d-flex align-items-center gap-8px">
                                                                <img src={item.image} alt={"Basket"}
                                                                    draggable={false}
                                                                    width={46}
                                                                    height={32}
                                                                />
                                                                <span className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black">
                                                                    {item.label}
                                                                </span>
                                                            </div>
                                                            <Icon icon={
                                                                item.id === paymentType.id
                                                                    ?
                                                                    'radio-checked1' :
                                                                    'radio-unchecked'
                                                            }
                                                                size={24} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Fragment>
                                        :
                                        isFirstInstallment === 1 &&
                                        <Fragment>
                                            <h5 className="e-font-16 e-line-height-20 color-eerie-black e-montserrat-semi-bold mt-4">
                                                {
                                                    investmentSuggestion.mandate_status === false && (location.state.investment_option === "sip_only" || location.state.investment_option === "sip_and_lumpsum") ?
                                                        "Mandate Method"
                                                        :
                                                        "Payment Method"
                                                }
                                            </h5>
                                            {
                                                investmentSuggestion.payment_types.map((item, index) => {
                                                    return (
                                                        <div key={index}
                                                            className={`p-3 d-flex align-items-center justify-content-between border-1px cursor-pointer mt-3 border-radius-16px  ${item.id === paymentType.id ? "border-purple e-bg-light-lavender" : "border-bright-gray"}`} onClick={() => _handlePayment(item)}>
                                                            <div className="d-flex align-items-center gap-8px">
                                                                <img src={item.image} alt={"Basket"}
                                                                    draggable={false}
                                                                    width={46}
                                                                    height={32}
                                                                />
                                                                <span className="e-montserrat-semi-bold e-font-14 e-line-height-20 mb-0 color-eerie-black">
                                                                    {item.label}
                                                                </span>
                                                            </div>
                                                            <Icon icon={
                                                                item.id === paymentType.id
                                                                    ?
                                                                    'radio-checked1' :
                                                                    'radio-unchecked'
                                                            }
                                                                size={24} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Fragment>
                                }
                                {
                                    investmentSuggestion.mandate_status === false && (location.state.investment_option === "sip_only" || location.state.investment_option === "sip_and_lumpsum") && <div className="d-flex align-items-start padding-12px-all mt-2 e-bg-light-blue border-radius-12px my-4 color-ocean-blue e-font-12 e-line-height-20 gap-4px e-montserrat-medium">
                                        <span>
                                            <Icon icon="info-circle"
                                                size={16} />
                                        </span>
                                        The mandate is for the automatic debit of your monthly SIP. It's important to know that the mandate allows a maximum value of {paymentType.slug === "UPI" ? investmentSuggestion.mandate_limit.UPI : investmentSuggestion.mandate_limit.E_MANDATE}. However, the auto-debit will occur at the exact SIP order value. There will be no additional charges for the mandate's auto-debit feature.
                                    </div>
                                }
                                {investmentSuggestion.mandate_status === true && location.state.investment_option !== "lumpsum_only" && <ConsentCard acceptedTerms={acceptedTerms}
                                    type={1}
                                    setAcceptedTerms={() => setAcceptedTerms(!acceptedTerms)} />}

                                {location.state.investment_option === "lumpsum_only" && <ConsentCard acceptedTerms={acceptedTerms}
                                    type={1}
                                    setAcceptedTerms={() => setAcceptedTerms(!acceptedTerms)} />}

                                <div className="mt-5 border-top-1px border-bright-gray mb-4"></div>


                                <div className="d-flex justify-content-center">
                                    <div className="col-6">
                                        <PrimaryButton label={_getButtonLabel()}
                                            className="padding-10px-tb w-100"
                                            disabled={location.state.investment_option !== "lumpsum_only" && investmentSuggestion.mandate_status === false ? submitLoader : acceptedTerms === false || submitLoader === true}
                                            showLoader={submitLoader}
                                            onPress={_handleSubmit}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            showFundAllocation === true &&
                            <CustomFundAllocation fundData={modalData}
                                basket={selectedBasket}
                                submitAllocation={_updateFunds}
                                closeModal={_closeAllocationModal} />
                        }

                        {
                            allocationSubmitted === true &&
                            <InvestmentStatusModal
                                type={1}
                                title="Fund Customization Successful"
                                content="We have successfully customized the fund to align with your risk tolerance"
                                closeModal={() => _handleConfirmModal(false)} />
                        }

                        {
                            toggleDateModal &&
                            <SIPDateModal apiDates={investmentSuggestion.milestone_details.sip_dates}
                                selectedSIPDate={selectedDate}
                                handleSelect={_handleSelectDate}
                                handleCloseDateModal={() => _handleCloseModal(false)} />
                        }

                        {
                            showOtpModal === true &&
                            <ConfirmInvest verify={_verifyOTP}
                                loader={modalLoader}
                                closeModal={_handleClose} />
                        }
                        {
                            riskCheck === true && <RiskConfirmationModal
                                description={`Although your risk profile is ${(PROFILE_DETAILS.investment_style_text).toLowerCase()} the funds for your ${(investmentSuggestion.milestone_details.name).toLowerCase()} are based on the tenure left to achieve the same. Funds chosen by your primary risk profile may or may not achieve the desired result.`}
                                closeModal={() => setRiskCheck(false)}
                                hideSecondary={true}
                                handleConfirm={() => setRiskCheck(false)} />
                        }
                        {
                            showSuccessModal === true &&
                            <PrimaryBasketStatusModal data={paymentModalData}
                                isFirstInstallment={isFirstInstallment}
                                type={location.state.investment_option}
                                handleSecondaryButtonClick={_handleSecondaryButton}
                                handlePrimaryButtonClick={() => navigate("/portfolio", { replace: true })}
                                closeModal={_handleCloseSuccessModal} />
                        }
                        {
                            riskKycCheck === true && <RiskKycConfirmationModal
                                title="Open an Investment Account Now"
                                content="You are halfway through your account creation. Investment Account need to start with Investment."
                                closeModal={() => _confirmRiskKyc(false)}
                                handleConfirm={_handleNavigateToScreen} />
                        }
                    </Fragment >
            }
        </>
    )
}

export default GoalInvest