/*
 *   File : profile.js
 *   Author : https://evoqins.com
 *   Description : Profile header dropdown
 *   Integrations : NA
 *   Version : 1.0.0
*/

// import packages
import { Fragment } from "react"
import { useSelector } from "react-redux"

// import components
import { Icon } from "../Icon"
import { _logout } from "../../Helper/helper"

// import styles
import Color from "../../Styles/color.module.scss";
import style from "../../Styles/Components/profile.module.scss";


const MENU_LIST = [{
    image: "Systematic-Transactions",
    label: "Systematic Transactions",
    link: "/systematic-transactions",
    kyc: true,
},
{
    image: "Manage-risk",
    label: "Manage Risk Profile",
    link: "/risk-profile",
    kyc: true,
},
{
    image: "Bank",
    label: "Manage Bank Account",
    link: "/manage-bank",
    kyc: false,
},
{
    image: "Nominee",
    label: "Manage Nominee",
    link: "/nominee",
    kyc: false,
},
{
    image: "manage-kyc",
    label: "Manage KYC",
    link: "/manage-kyc",
    kyc: true,
},
{
    image: "Orders",
    label: "Orders",
    link: "/orders",
    kyc: true,
},
{
    image: "Calculators",
    label: "Calculators",
    link: "/calculators",
    kyc: true,
},
{
    image: "Reports",
    label: "Reports",
    link: "/reports",
    kyc: true,
},
{
    image: "refer-friend",
    label: "Refer A Friend",
    link: "/refer-earn",
    kyc: true,
},
{
    image: "Settings",
    label: "Account Settings",
    link: "/settings",
    kyc: true,
},
{
    image: "Support1",
    label: "Support",
    link: "/support",
    kyc: true,
},
{
    image: "Refer-friend",
    label: "About Us",
    link: "/about-us",
    kyc: true,
},
{
    image: "like-shapes",
    label: "FAQs",
    link: "/faq",
    kyc: true
}]

const ProfileModal = (props) => {

    const PROFILE_DETAILS = useSelector(state => state.Reducer.PROFILE_DETAILS);
    const SIGNUP_PROGRESS = useSelector(state => state.Reducer.SIGNUP_PROGRESS);

    const _handleLogout = () => {
        _logout();
    }

    const _handleNavigate = (item) => {
        props.navigateLink(item.link);
    }

    const _navigateToProfile = () => {
        props.navigateLink("/profile");
    }

    let menu_list = (SIGNUP_PROGRESS.kyc_data.status === "ACCOUNT_CREATED" || SIGNUP_PROGRESS.kyc_data.status === "INVESTED") ? MENU_LIST : MENU_LIST.filter((menu) => menu.kyc === true);

    return (
        <Fragment>
            <div className={`${style.e_profile} position-absolute top-54px end-0 border-radius-24px bg-white border-1px border-white z-index-99`}>
                <div className={`m-3 border-radius-20px overflow-hidden position-relative`}>
                    <div className={`py-2 ps-2 padding-12px-right d-flex justify-content-between align-items-center  cursor-pointer ${style.e_profile_hover}`}
                        onClick={_navigateToProfile}>
                        <div className="d-flex gap-8px align-items-center z-index-2 p-2 ">
                            <div className=" rounded-circle e-bg-magnolia">
                                <span className="e-montserrat-semi-bold e-font-20 e-line-height-20 color-american-purple w-48px h-48px d-flex align-items-center justify-content-center ">{PROFILE_DETAILS.short_name}</span>
                            </div>
                            <div >
                                <p className="e-montserrat-semi-bold e-font-14 e-line-height-20 color-white margin-2px-bottom text-truncate w-132px ">{PROFILE_DETAILS.name}</p>
                                <p className="e-montserrat-regular e-font-12 e-line-height-20 mb-0 color-white">User ID: {PROFILE_DETAILS.customer_id}</p>
                            </div>
                        </div>
                        <Icon icon="arrow-right"
                            size={24}
                            color={Color.white}
                            className={`z-index-2 position-relative ${style.e_arrow_right}`} />
                        <img src={props.background}
                            alt="Profile"
                            height={80}
                            loading="lazy"
                            className="w-100 position-absolute top-0 start-0 z-index-1" />
                    </div>
                </div>
                <div className={`${style.e_menu_scroll} h-376px border-radius-24px`}>
                    {
                        menu_list.map((item, key) =>
                            <div className={`d-flex align-items-center gap-8px ps-3 margin-12px-top cursor-pointer ${style.e_profile_hover}`}
                                key={key}
                                onClick={() => _handleNavigate(item)}>
                                <Icon icon={item.image}
                                    size={20}
                                    className={`mb-3 d-sm-block d-none`} />
                                <Icon icon={item.image}
                                    size={18}
                                    className={`mb-2 d-sm-none d-block`} />


                                <div className={`d-flex align-items-center justify-content-between gap-8px pe-3 w-100  ${key + 1 !== menu_list.length && "border-bottom-1px  border-light-gray"} pb-sm-3 pb-2`}>
                                    <span className="e-montserrat-medium e-font-14 e-line-height-24 color-eerie-black">{item.label}</span>
                                    <Icon icon="arrow-right"
                                        size={24}
                                        className={`${style.e_arrow_right}`} />
                                </div>
                            </div>
                        )
                    }
                    <div className="d-flex align-items-center gap-8px ps-3 pt-3 border-top-1px border-light-gray mb-3 cursor-pointer"
                        onClick={_handleLogout}>
                        <Icon icon="Logout"
                            size={20} />
                        <span className="e-montserrat-medium e-font-14 e-line-height-24 color-eerie-black">Log Out</span>
                    </div>
                </div>

            </div>

        </Fragment>
    )
}

export default ProfileModal