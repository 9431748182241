/*
*   File : calculator.js
*   Author : https://evoqins.com
*   Description : Calculator screen
*   Version : 1.0.0
*/

import { Fragment, useEffect, useState } from "react";
import { Modal } from "bootstrap";
import { useNavigate } from "react-router-dom";

import { Breadcrumb } from "../../Components/Other";
import { Header } from "../../Components/Header";
import { CustomTabBar } from "../../Components/Tab";
import { EditableSlider } from "../../Components/FormElements";
import { SegmentChart } from "../../Components/Charts";
import { PrimaryButton } from "../../Components/Buttons";
import { CustomNavigation } from "../../Components/Modal";


import store from "../../Store";
import Color from "../../Styles/color.module.scss";

const TAB_DATA = [
    {
        id: 1,
        label: "SIP"
    },
    {
        id: 2,
        label: "Lumpsum"
    },
    {
        id: 3,
        label: "SWP"
    }
]

const Calculator = () => {

    const navigate = useNavigate();
    const PROGRESS_DATA = store.getState().Reducer.SIGNUP_PROGRESS
    const [selectedTab, setSelectedTab] = useState(TAB_DATA[0].id);
    const [sipAmount, setSipAmount] = useState(10000);
    const [sipTimePeriod, setSipTimePeriod] = useState(3);
    const [sip, setSip] = useState(8);
    const [sipExpectedReturn, setSipExpectedReturn] = useState(16);

    const [lumpsumAmount, setLumpsumAmount] = useState(500000);
    const [lumpsumTimePeriod, setLumpsumTimePeriod] = useState(3);
    const [lumpsumExpectedReturn, setLumpsumExpectedReturn] = useState(17);


    const [swpAmount, setSwpAmount] = useState(500000);
    const [swpTimePeriod, setSwpTimePeriod] = useState(3);
    const [withdrawalMonthly, setWithdrawalMonthly] = useState(5000);
    const [swpReturns, setSwpReturns] = useState(8);

    const [totalWithdrawnAmount, setWithdrawnAmount] = useState(0);
    const [remainingBalance, setRemainingBalance] = useState(0);

    const [totalReturns, setTotalReturns] = useState(0)
    const [wealthGained, setWealthGained] = useState(0)
    const [graphPercent, setGraphPercent] = useState("");
    const [totalInvested, setTotalInvested] = useState(0);

    const [showNavigation, setShowNavigation] = useState(false);

    useEffect(() => {
        if (showNavigation === true) {
            const modal = new Modal(document.getElementById("custom-navigation"));
            modal.show();
        }
    }, [showNavigation]);

    useEffect(() => {
        if (selectedTab === 2) {
            const lumpsumCalculate = () => {
                const monthly_rate = lumpsumExpectedReturn / 100;
                let final_value = lumpsumAmount * (Math.pow(1 + monthly_rate, lumpsumTimePeriod));
                final_value = parseInt(final_value.toFixed(0));
                setLumpsumAmount(lumpsumAmount);
                setTotalReturns(parseFloat(final_value.toFixed(2)));
                const gained_value = final_value - lumpsumAmount;
                const total_returns_percentage = (lumpsumAmount / final_value) * 100
                setWealthGained(gained_value);
                if (isNaN(total_returns_percentage)) {
                    setGraphPercent(0)
                }
                else {
                    setGraphPercent(total_returns_percentage)
                }
            }
            lumpsumCalculate();
        }
    }, [lumpsumAmount, lumpsumExpectedReturn, lumpsumTimePeriod, selectedTab]);

    useEffect(() => {
        if (selectedTab === 1) {
            const _sipCalculator = () => {
                let future_value = 0;
                let monthly_rate = (sipExpectedReturn / 12) / 100;
                let total_months = sipTimePeriod * 12;
                let inflation_rate = 0;

                for (let month = 1; month <= total_months; month++) {
                    let returned_value = sipAmount * Math.pow(1 + sip / 100, Math.floor((month - 1) / 12));
                    future_value += returned_value;
                    future_value *= (1 + monthly_rate);
                    future_value /= (1 + inflation_rate / 100);
                }

                let total_invested = 0; // total invested amount
                let step_sip = sip; // step sip percentage
                let sips = parseInt(sipAmount)
                let yearly_invested_sip = 0;
                for (let year = 1; year <= sipTimePeriod; year++) {
                    if (year !== 1) {
                        sips = sips + (sips * (step_sip / 100)); // updated sip value for selected year
                    }
                    yearly_invested_sip = sips * 12;
                    total_invested += yearly_invested_sip;
                }
                const gained_value = future_value - total_invested;
                const total_returns_percentage = (total_invested / future_value) * 100
                setTotalReturns(parseFloat(future_value.toFixed(2)));
                setWealthGained(gained_value);
                setTotalInvested(total_invested);
                if (isNaN(total_returns_percentage)) {
                    setGraphPercent(0)
                }
                else {
                    setGraphPercent(total_returns_percentage)
                }
            }

            _sipCalculator();
        }
    }, [sipAmount, sipExpectedReturn, sipTimePeriod, sip, selectedTab])

    useEffect(() => {
        if (selectedTab === 3) {
            const _swpCalculator = () => {

                let initial_value = 0;
                let total_investment_value = (swpAmount);;
                let monthly_withdrawal_value = (withdrawalMonthly);;
                let expected_returns = swpReturns
                let years = swpTimePeriod * 12;

                let monthly_interest_rate = (expected_returns / 12) / 100;
                let total_interest1 = 0;
                let interest = 0;
                let interest1 = 0;
                let count = 0;
                let balance = total_investment_value;
                let tenure_period = swpTimePeriod * 12;


                let totalInvested = [];

                let monthlyGrowthRate = Math.pow(1 + swpReturns / 100, 1 / 12) - 1;
                let month = 0;

                while (month < years) {
                    balance += balance * monthlyGrowthRate;
                    balance -= monthly_withdrawal_value;
                    month++;
                }

                setRemainingBalance(balance)

                for (let i = 1; i <= years; i++) {
                    count = count + 1;

                    interest1 = balance * monthly_interest_rate;
                    total_interest1 = total_interest1 + interest1;
                    balance = balance + interest1;
                    totalInvested.push(Math.round(total_investment_value));

                    if (interest > initial_value) {
                        setWithdrawnAmount(0);
                    }
                    total_investment_value = initial_value;

                    setWithdrawnAmount(monthly_withdrawal_value * tenure_period);

                }

            }
            _swpCalculator();
        }
    }, [swpReturns, swpAmount, swpTimePeriod, withdrawalMonthly, selectedTab]);

    function _handleNavigate() {
        navigate("/");
    }

    // handle Returns
    function _handleTabChange(tab_index) {
        setSelectedTab(tab_index);
    }

    function _handleNavigation(status) {
        setShowNavigation(status)
    }

    const _handleSlider = (value, type) => {
        switch (type) {
            case 1:
                if (selectedTab === 1) {
                    setSipAmount(value);
                } else if (selectedTab === 2) {
                    setLumpsumAmount(value);
                } else {
                    setSwpAmount(value);
                } break;
            case 2:
                if (selectedTab === 1) {
                    setSipExpectedReturn(value);
                } else if (selectedTab === 2) {
                    setLumpsumExpectedReturn(value);
                } else {
                    setWithdrawalMonthly(value);
                }
                break;
            case 3:
                if (selectedTab === 1) {
                    setSipTimePeriod(value);
                } else if (selectedTab === 2) {
                    setLumpsumTimePeriod(value);
                } else {
                    setSwpTimePeriod(value);
                }
                break;
            case 4:
                if (selectedTab === 1) {
                    setSip(value);
                } else {
                    setSwpReturns(value);
                }
                break;

            default:
                break;
        }
    }

    return (
        <Fragment>
            <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
                <Header type={2} />
                <div className="row ">
                    <div className="col-lg-11 padding-144px-bottom">

                        {/* Header */}

                        {/* Goal list */}
                        <section className="px-4 ">
                            <Breadcrumb mainPage="Home"
                                currentPage="Calculators"
                                type={1}
                                handleNavigate={_handleNavigate} />
                            <h5 className="margin-30px-top e-font-18 e-line-height-38 e-montserrat-semi-bold color-dark-jungle-green margin-14px-bottom">Calculators</h5>
                            <div className="row">
                                <div className="col-xl-8 col-lg-11">
                                    <div className="e-calculator  border-radius-24px">
                                        <div className="px-sm-4 px-3 border-bottom-1px border-chinese-white pt-2">
                                            <CustomTabBar data={TAB_DATA}
                                                selectedTab={selectedTab}
                                                onSelectTab={_handleTabChange} />
                                        </div>
                                        <div className="p-sm-4 p-3 pt-3">
                                            {selectedTab === 3 ?
                                                <div className="row row-gap-16px">
                                                    <div className="col-lg-6 d-flex flex-column gap-16px">
                                                        <EditableSlider label="Total Investment"
                                                            edit={true}
                                                            inputPrefix="₹"
                                                            value={swpAmount}
                                                            min={100000}
                                                            max={50000000}
                                                            // suffix="₹"
                                                            className="mb-sm-0 mb-4"
                                                            handleChange={(value) => _handleSlider(value, 1)} />

                                                        <EditableSlider label="Monthly Withdrawal"
                                                            edit={true}
                                                            value={withdrawalMonthly}
                                                            min={5000}
                                                            max={500000}
                                                            inputPrefix="₹"
                                                            className="mb-sm-0 mb-4"
                                                            handleChange={(value) => _handleSlider(value, 2)} />

                                                        <EditableSlider label="Expected Returns Rate %"
                                                            value={swpReturns}
                                                            min={1}
                                                            max={30}
                                                            suffix="%"
                                                            singularSuffix="%"
                                                            pluralSuffix="%"
                                                            className="mb-sm-0 mb-4"
                                                            handleChange={(value) => _handleSlider(value, 4)} />

                                                        <EditableSlider label="Time Period"
                                                            value={swpTimePeriod}
                                                            min={1}
                                                            max={30}
                                                            suffix={swpTimePeriod === 1 ? "Yr" : "Yrs"}
                                                            edit={false}
                                                            singularSuffix="Yr"
                                                            pluralSuffix="Yrs"
                                                            className="mb-sm-0 mb-4"
                                                            handleChange={(value) => _handleSlider(value, 3)} />

                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="border-1px border-mystic-gray p-4 border-radius-24px">
                                                            <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                                                                <p className="e-montserrat-medium e-font-14 e-line-height-24px mb-0 color-outer-space">Total Investment</p>
                                                                <p className="e-montserrat-bold e-font-16 e-line-height-24px color-rebecca-purple mb-0">₹{parseFloat(swpAmount.toFixed(0)).toLocaleString('en-IN')}</p>
                                                            </div>
                                                            <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                                                                <p className="e-montserrat-medium e-font-14 e-line-height-24px mb-0 color-outer-space">Withdrawn Amount</p>
                                                                <p className="e-montserrat-bold e-font-16 e-line-height-24px color-eerie-black mb-0">₹{parseFloat(totalWithdrawnAmount.toFixed(0)).toLocaleString('en-IN')}</p>
                                                            </div>
                                                            <div className="d-flex flex-wrap align-items-center justify-content-between ">
                                                                <p className="e-montserrat-medium e-font-14 e-line-height-24px mb-0 color-outer-space">Final Value</p>
                                                                <p className="e-montserrat-bold e-font-16 e-line-height-24px color-eerie-black mb-0">₹{parseFloat(remainingBalance.toFixed(0)).toLocaleString('en-IN')}</p>
                                                            </div>

                                                            <PrimaryButton className="padding-14px-tb w-100 border-radius-12px mt-5"
                                                                label="Invest Now"
                                                                onPress={() => _handleNavigation(true)} />
                                                        </div>

                                                    </div>
                                                </div>
                                                :
                                                <div className="row row-gap-16px">
                                                    <div className="col-lg-6 d-flex flex-column gap-16px">
                                                        <EditableSlider label={selectedTab === 1 ? "Monthly Investment" : "One Time investment Amount"}
                                                            edit={true}
                                                            inputPrefix="₹"
                                                            value={selectedTab === 1 ? sipAmount : lumpsumAmount}
                                                            min={selectedTab === 1 ? 500 : 5000}
                                                            max={selectedTab === 1 ? 500000 : 10000000}
                                                            // suffix="₹"
                                                            className="mb-sm-0 mb-4"
                                                            handleChange={(value) => _handleSlider(value, 1)} />

                                                        <EditableSlider label={selectedTab === 1 ? "Expected Return Rate %" : "Avg Yearly Returns %"}
                                                            value={selectedTab === 1 ? sipExpectedReturn : lumpsumExpectedReturn} min={1}
                                                            max={30}
                                                            suffix="%"
                                                            singularSuffix="%"
                                                            pluralSuffix="%"
                                                            className="mb-sm-0 mb-4"
                                                            handleChange={(value) => _handleSlider(value, 2)} />
                                                        <EditableSlider label={selectedTab === 1 ? "Time Period" : "Tenure"}
                                                            value={selectedTab === 1 ? sipTimePeriod : lumpsumTimePeriod}
                                                            min={1}
                                                            max={30}
                                                            suffix={selectedTab === 1 ? sipTimePeriod === 1 ? "Yr" : "Yrs" : lumpsumTimePeriod === 1 ? "Yr" : "Yrs"}
                                                            edit={false}
                                                            singularSuffix="Yr"
                                                            pluralSuffix="Yrs"
                                                            className="mb-sm-0 mb-4"
                                                            handleChange={(value) => _handleSlider(value, 3)} />
                                                        {
                                                            selectedTab === 1 &&
                                                            <EditableSlider label="Step up SIP(%)"
                                                                value={sip}
                                                                min={0}
                                                                max={50}
                                                                suffix="%"
                                                                singularSuffix="%"
                                                                pluralSuffix="%"
                                                                className="mb-sm-0 mb-4"
                                                                handleChange={(value) => _handleSlider(value, 4)} />
                                                        }
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="border-1px border-mystic-gray p-sm-4 p-3 border-radius-24px">
                                                            <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                                                                <p className="e-montserrat-medium e-font-14 e-line-height-24px mb-0 color-outer-space">Invested Amount</p>
                                                                <p className="e-montserrat-bold e-font-16 e-line-height-24px color-rebecca-purple mb-0">₹{selectedTab === 1 ?
                                                                    parseFloat(totalInvested.toFixed(0)).toLocaleString('en-IN') :
                                                                    parseFloat(lumpsumAmount.toFixed(0)).toLocaleString('en-IN')}</p>
                                                            </div>
                                                            <div className="d-flex flex-wrap align-items-center justify-content-between mb-4">
                                                                <p className="e-montserrat-medium e-font-14 e-line-height-24px mb-0 color-outer-space">Exp. Returns</p>
                                                                <p className="e-montserrat-bold e-font-16 e-line-height-24px color-hot-pink mb-0">₹{parseFloat(wealthGained.toFixed(0)).toLocaleString('en-IN')}</p>
                                                            </div>
                                                            <div className="d-flex flex-wrap align-items-center justify-content-between ">
                                                                <p className="e-montserrat-medium e-font-14 e-line-height-24px mb-0 color-outer-space">Total Value</p>
                                                                <p className="e-montserrat-bold e-font-16 e-line-height-24px color-eerie-black mb-0">₹{parseFloat(totalReturns.toFixed(0)).toLocaleString('en-IN')}</p>
                                                            </div>
                                                            <div className="d-sm-flex d-none flex-wrap justify-content-center position-relative">
                                                                <SegmentChart size={270}
                                                                    progress={graphPercent / 100}
                                                                    emptyColor={Color.hot_pink}
                                                                    fillColor={Color.rebecca_purple} />
                                                                <div className="d-flex flex-wrap align-items-center gap-24px position-absolute e-chart-data start-0 mx-auto">
                                                                    <p className="e-montserrat-medium e-font-14 e-line-height-24px color-outer-space e-chart-active-color position-relative mb-0 ps-3">Invested Amount</p>
                                                                    <p className="e-montserrat-medium e-font-14 e-line-height-24px color-outer-space e-chart-inactive-color position-relative mb-0 ps-3">Exp. Returns</p>
                                                                </div>
                                                            </div>
                                                            <div className="d-sm-none d-flex justify-content-center position-relative">
                                                                <SegmentChart size={200}
                                                                    progress={graphPercent / 100}
                                                                    emptyColor={Color.hot_pink}
                                                                    fillColor={Color.rebecca_purple} />
                                                                <div className="d-flex  align-items-center justify-content-start position-absolute start-0 end-0 bottom-48px m-auto">
                                                                    <p className="e-montserrat-medium e-font-12 e-line-height-20 color-outer-space e-chart-active-color position-relative mb-0 ps-3 me-sm-4 me-2">Invested Amount</p>
                                                                    <p className="e-montserrat-medium e-font-12 e-line-height-20 color-outer-space e-chart-inactive-color position-relative mb-0 ps-3">Exp. Returns</p>
                                                                </div>
                                                            </div>

                                                            <PrimaryButton className="padding-14px-tb w-100 border-radius-12px"
                                                                label="Invest Now"
                                                                onPress={() => _handleNavigation(true)} />
                                                        </div>

                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            {
                showNavigation === true && <CustomNavigation goal={() => {
                    if (PROGRESS_DATA.kyc_user === true) {
                        navigate("/milestone")
                    } else {
                        navigate("/kyc")
                    }
                }}
                    explore={() => {
                        if (PROGRESS_DATA.kyc_user === true) {
                            navigate("/explore")
                        } else {
                            navigate("/kyc")
                        }
                    }}
                    closeModal={() => _handleNavigation(false)} />
            }
        </Fragment>
    )
}

export default Calculator;