/*
*   File : editable-slider.js
*   Author : https://evoqins.com
*   Description : Screen to show goal Information 
*   Version : 1.0.0
*/

// import packages
import { Fragment, memo, useState } from "react";

// import components
import CustomSlider from "./slider";

// import styles
import style from "../../Styles/Components/editable-slider.module.scss"
import { useEffect } from "react";

const EditableSlider = (props) => {

    const [value, setValue] = useState(props.value);

    useEffect(() => {
        setValue(props.value);
    }, [props.value])

    const _handleChange = (value, type) => {
        switch (type) {
            case 1:
                const formatted_value = Number(value.replaceAll(",", ''));
                props.handleChange(formatted_value)
                if (formatted_value <= props.max) {
                    props.handleChange(formatted_value)
                    setValue(formatted_value);
                } else {
                    setValue(props.max);
                    props.handleChange(props.max)
                }
                break;
            case 2:
                props.handleChange(value);
                setValue(value);
                break;
            default:
                break;
        }
    }

    return (
        <Fragment>
            <div className={props.className}>
                <div className="d-flex justify-content-between gap-8px align-items-center mb-4">
                    <p className="mb-0 color-dark-charcoal e-font-14 e-line-height-24 e-montserrat-medium e-xs-font-12">{props.label}</p>
                    {
                        props.edit ?
                            <div className="position-relative w-max-content">
                                <input className={`w-144px  color-rebecca-purple e-montserrat-semi-bold  e-line-height-24 e-bg-lavender-mist border-radius-12px padding-10px-tb border-0 no-outline  padding-32px-lr ${props.fontSize} ${props.inputPrefix ? "e-slider-amount-input" : props.right ? "text-end padding-40px-right e-slider-edited-input" : "e-slider-input"} ${(value < props.min || value > props.max) && "outline-deep-carmine-pink"}`}

                                    value={value.toLocaleString('en-IN')}
                                    onChange={(e) => _handleChange(e.target.value, 1)} />
                                {props.suffix && <p className={`color-rebecca-purple e-montserrat-semi-bold e-line-height-24 mb-0 position-absolute right-12px d-flex align-items-center top-0 h-100 ${props.fontSize}`}>{props.suffix}</p>}

                                {props.inputPrefix && <span className={`color-rebecca-purple e-montserrat-semi-bold e-line-height-24 position-absolute ps-3 ${props.fontSize} ${style.e_input_prefix}`}>{props.inputPrefix}</span>}
                            </div>


                            :
                            props.risk ?
                                <div className="w-144px color-rebecca-purple e-montserrat-semi-bold e-font-16 e-line-height-24 e-bg-lavender-mist border-radius-12px padding-10px-tb padding-20px-lr text-end text-truncate">
                                    {props.riskLabel}
                                </div>
                                :

                                <div className="w-144px e-slider-input  color-rebecca-purple e-montserrat-semi-bold e-font-16 e-line-height-24 e-bg-lavender-mist border-radius-12px padding-10px-tb padding-20px-lr text-end">
                                    {value} {props.suffix}
                                </div>
                    }
                </div>

                <CustomSlider step={props.step}
                    defaultValue={value}
                    min={props.min}
                    max={props.max}
                    handleChange={(value) => _handleChange(value, 2)} />
                <div className="d-flex justify-content-between pt-4">
                    <span className="e-font-14 e-line-height-24 e-montserrat-medium color-slate-gray">{props.inputPrefix} {props.min.toLocaleString("en-IN")} {props.singularSuffix}</span>
                    <span className="e-font-14 e-line-height-24 e-montserrat-medium color-slate-gray">{props.inputPrefix} {props.max.toLocaleString("en-IN")} {props.pluralSuffix}</span>
                </div>
            </div>
        </Fragment>
    )
}

export default memo(EditableSlider)

EditableSlider.defaultProps = {
    handleChange: () => { },
    value: "",
    min: null,
    max: null,
    suffix: "",
    edit: false,
    risk: false,
    riskLabel: "",
    right: false,
    fontSize: "e-font-16"
}