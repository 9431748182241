
/*
 *   File : investment-status.js
 *   Author URI : https://evoqins.com
 *   Description : Investment status modal 
 *   Integrations : null
 *   Version : v1.1
 */

// Packages
import { Fragment, memo, useEffect } from "react";

// Custom components
import { Icon } from "../Icon";
import { PrimaryButton } from "../Buttons";

// Custom styles
import style from "../../Styles/Components/fund-allocation.module.scss";
import Color from '../../Styles/color.module.scss';


const InvestmentStatusModal = (props) => {

    useEffect(() => {
        var my_modal = document.getElementById("investment-status");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });
        //eslint-disable-next-line
    }, []);


    return (
        <Fragment>
            <div className={`modal fade ${style.e_confirm_modal}`}
                id="investment-status"
                tabIndex="-1"
                aria-labelledby="investment-status"
                aria-hidden="true"
                data-bs-backdrop="true" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content padding-36px-bottom  text-center padding-12px-lr ${style.e_content} `}>
                        <Icon icon="close-btn"
                            size={24}
                            id="close-modal"
                            data-bs-dismiss="modal"
                            color={Color.eerie_black}
                            className='cursor-pointer position-absolute right-16px top-16px' />

                        <Fragment>
                            <img src={require("../../Assets/Images/Modal/success.png")}
                                alt="success"
                                draggable={false}
                                width={144}
                                height={144}
                                className="mx-auto" />
                            <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-eerie-black mb-2">
                                {props.title}
                            </h5>

                            <p className="e-montserrat-medium e-font-16 e-line-height-28 color-charcoal-gray mb-0 ">
                                {props.content}
                            </p>
                            {
                                props.showButton === true &&
                                <div className="row d-flex justify-content-center">
                                    <div className="col-6">
                                        <PrimaryButton label="Dashboard"
                                            className="w-100 padding-12px-tb mt-4"
                                            cancel="modal"
                                            onPress={props.handleNavigate} />
                                    </div>
                                </div>
                            }
                        </Fragment>
                    </div>

                </div>
            </div>
        </Fragment>
    )
}

export default memo(InvestmentStatusModal)