// SegmentChart.jsx
import React from 'react';
import ArcProgress from 'react-arc-progress';

const SegmentChart = (props) => {
    return (
        <div style={{ width: props.size, height: props.size }} className='e-arc'>
            <ArcProgress
                progress={props.progress}
                text={props.text}
                thickness={20}
                lineCap="butt"
                width="100%"
                height="100%"
                style={{ width: "100%", height: "100%" }}
                arcStart={180}
                arcEnd={360}
                emptyColor={props.emptyColor}
                fillColor={props.fillColor}
            />
        </div>
    );
};

export default SegmentChart;
