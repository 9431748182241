
/*
 *   File : tab.js
 *   Author URI : https://evoqins.com
 *   Description : Custom tab component
 *   Integrations : null
 *   Version : 1
 */

// Packages
import React, { Fragment, memo, useEffect, useState } from "react";

// Styles
import style from "../../Styles/Components/tab.module.scss";

const CustomTabBar = React.memo((props) => {

    const [tabIndex, setTabIndex] = useState(props.selectedTab);

    useEffect(() => {
        if (props.selectedTab) {
            setTabIndex(props.selectedTab)
        }
    }, [props.selectedTab])

    //Onclick event for tab change
    const _handleChangeTab = (index, label) => {
        setTabIndex(index);
        props.onSelectTab(index, label);
    }

    return (
        <Fragment>
            {
                props.type === 3 ?
                    <div className={`d-flex gap-16px w-100 overflow-x-auto ${props.wrapperClass} ${style.e_scroll}`}>
                        {
                            props.data.map((item, key) => {
                                return (

                                    <div
                                        className={`text-center e-tab e-font-14 cursor-pointer e-montserrat-medium e-line-height-20 text-nowrap
                                    ${(item.id) === tabIndex ?
                                                `${style.e_active_tab} color-rebecca-purple`
                                                :
                                                `${style.e_tab} color-muted-gray`}
                                            ${props.className}`}
                                        key={key}
                                        onClick={() => _handleChangeTab(item.id, item.label)}>
                                        <img src={item.id === tabIndex ? item.active : item.icon} alt="tab" className="me-1" />
                                        {item.label}
                                    </div>
                                )
                            })
                        }

                    </div>
                    :
                    props.type === 2 ?
                        <div className={`d-flex align-items-center gap-16px w-100 overflow-x-auto ${props.wrapperClass} ${style.e_scroll}`}>
                            {
                                props.data.map((item, key) => {
                                    return (


                                        <div
                                            className={`text-center e-tab d-flex align-items-center gap-4px e-font-16 cursor-pointer e-montserrat-medium e-line-height-20 text-nowrap
                                                ${(item.id) === tabIndex ?
                                                    `${style.e_icon_active_tab} color-sky-blue`
                                                    :
                                                    `${style.e_icon_tab} color-muted-gray`}
                                                    ${props.className}`}
                                            key={key}
                                            onClick={() => _handleChangeTab(item.id, item.label)}>
                                            <img src={item.icon}
                                                alt={item.label}
                                            />  {item.label}
                                        </div>

                                    )
                                })
                            }

                        </div>
                        :
                        <div className={`d-flex gap-16px w-100 overflow-x-auto ${props.wrapperClass} ${style.e_scroll}`}>
                            {
                                props.data.map((item, key) => {
                                    return (

                                        <div
                                            className={`${props.tabClass} e-tab text-center e-font-14 cursor-pointer e-montserrat-medium e-line-height-20 text-nowrap
                                            ${(item.id) === tabIndex ?
                                                    `${style.e_active_tab} color-rebecca-purple`
                                                    :
                                                    `${style.e_tab} color-muted-gray`}
                                                    ${props.className}`}
                                            key={key}
                                            onClick={() => item.id !== tabIndex && _handleChangeTab(item.id, item.label)}>
                                            {props.icon && <img src={item.id === tabIndex ? require("../../Assets/Images/Global/basket-tab.svg").default : require("../../Assets/Images/Global/basket-inactive.svg").default} alt="tab" className="me-1" />}
                                            {item.label}
                                        </div>
                                    )
                                })
                            }

                        </div>
            }
        </Fragment>
    )
})

export default memo(CustomTabBar)

CustomTabBar.defaultProps = {
    className: '',
    selectedTab: 1,
    tabClass: "",
    onSelectTab: () => { }
}  
