/*
*   File : tax-savings.js
*   Author : https://evoqins.com
*   Description : Screen to show goal Investment for tax savings
*   Version : 1.0.0
*/

// import packages
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom/dist";
import { toast } from "react-toastify";
import { Modal } from "bootstrap";

// import components
import { Header } from "../../Components/Header";
import { Breadcrumb, Loader, StepProgress } from "../../Components/Other";
import { CustomAmountInput, RadioGroup } from "../../Components/FormElements";
import { PrimaryButton } from "../../Components/Buttons";
import { GoalInformation, GoalInvest } from "./";
import { RiskKycConfirmationModal } from "../../Components/Modal";

// Services, helper
import { _getGoal } from "../../Helper/api";
import APIService from "../../Services/api-service";

const MONTLY_INCOME = [{
    id: 1,
    name: "Next month"
},
{
    id: 2,
    name: "3 months"
},
{
    id: 3,
    name: "6 months"
},
{
    id: 4,
    name: "1Yr"
},
{
    id: 5,
    name: "We win decide later"
}]

const RECEIVE_INCOME = [{
    id: 1,
    name: "Monthly"
},
{
    id: 2,
    name: "Quarterly"
},
{
    id: 3,
    name: "Half yearly"
},
{
    id: 4,
    name: "Yearly"
}]

const TaxSavings = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const monthlyIncomeRef = useRef(null);
    const currentExpensesRef = useRef(null);

    const [goalDetail, setGoalDetail] = useState({});

    const [monthlyIncome, setMonthlyIncome] = useState("");
    const [monthlyIncomeError, setMonthlyIncomeError] = useState("");

    const [currentIncome, setCurrentIncome] = useState("");
    const [currentIncomeError, setCurrentIncomeError] = useState("");

    const [incomeStart, setIncomeStart] = useState(MONTLY_INCOME[0].id);
    const [receiveIncome, setReceiveIncome] = useState(RECEIVE_INCOME[0].id);

    const [configurationStep, setConfigurationStep] = useState(1);
    const [screenLoader, setScreenLoader] = useState(true);
    const [apiLoader, setApiLoader] = useState(false);
    const [locationData, setLocationData] = useState(null);
    const [riskKycCheck, setRiskKycCheck] = useState(false);

    useEffect(() => {
        if (location.state !== null) {
            _goalDetail(location.state.goal_id);
            setConfigurationStep(location.state.step);
            if (location.state.step === 3) {
                setLocationData(location.state);
            }
        }
    }, [location]);

    useEffect(() => {
        if (riskKycCheck === true) {
            const modal = new Modal(document.getElementById("risk-kyc-check"));
            modal.show();
        }
    }, [riskKycCheck]);

    function _confirmRiskKyc(status) {
        setRiskKycCheck(status)
    }

    function _handleNavigateToScreen() {
        navigate("/risk-profile")
    }

    // API - get goal detail
    const _goalDetail = (id) => {
        _getGoal(id)
            .then((response) => {
                if (response.is_configured === false) {
                    setMonthlyIncome(response.admin_configured.meta_data.default_target);
                    setLocationData(null);
                } else {
                    if (response.user_configured.additional_data.target_value) {
                        setMonthlyIncome(response.user_configured.additional_data.target_value);
                    }
                    setCurrentIncome(response.user_configured.additional_data.current_expenses);
                    setIncomeStart(response.user_configured.tenure);
                    setReceiveIncome(response.user_configured.additional_data.existing_investment_id);
                }
                setGoalDetail(response);
                setScreenLoader(false);
            }).catch((error) => {
                toast.dismiss();
                toast.error(error, {
                    type: "error",
                });
            });
    }


    // navigate to home screen
    function _handleParentNavigate() {
        navigate("/");
    }

    // navigate to parent screen
    function _handleCurrentNavigate() {
        navigate("/milestone");
    }

    // handle monthlyIncome
    function _handleMonthlyIncome(value) {
        setMonthlyIncome(value);
        setMonthlyIncomeError("");
    }

    function _handleCurrentIncome(value) {
        setCurrentIncome(value);
        setCurrentIncomeError("");
    }

    function _handleIncomeStart(value) {
        setIncomeStart(value);
    }

    function _handleReceiveIncome(value) {
        setReceiveIncome(value);
    }

    // handle submit
    function _submitInvestment() {
        let valid = true;
        let firstErrorRef = null;
        if (monthlyIncome === "" || monthlyIncome === 0) {
            setMonthlyIncomeError("Monthly income is required");
            if (!firstErrorRef) {
                firstErrorRef = monthlyIncomeRef;
            }
            valid = false;
        }
        if (currentIncome === "") {
            setCurrentIncomeError("Current expenses required")
            if (!firstErrorRef) {
                firstErrorRef = currentExpensesRef;
            }
            valid = false;
        }

        if (firstErrorRef) {
            firstErrorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            firstErrorRef.current.querySelector('input').focus();
        }

        if (valid === true) {
            setApiLoader(true);
            _setTaxSavings();
        }
    }

    const _setTaxSavings = () => {
        const url = "/goal/set";
        const request = {
            goal_id: location.state.goal_id,
            tenure: incomeStart,
            target_value: monthlyIncome,
            additional_data: {
                existing_investment_id: receiveIncome,
                current_expenses: currentIncome,
                target_value: monthlyIncome,
            }
        };

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setConfigurationStep(2);
                navigate(location.pathname, {
                    state: {
                        goal_id: location.state.goal_id,
                        step: 2,
                        request_data: response.data
                    }
                });

            } else {
                if (response.data && response.data.risk_not_done === true) {
                    _confirmRiskKyc(true);
                } else {
                    toast.dismiss();
                    toast.error(response.message, {
                        type: "error",
                    });
                }
            }

            setApiLoader(false);
        })
    }

    // handle to go back previous step
    function _handleConfigurationStep(step) {
        navigate(location.pathname, {
            state: {
                goal_id: location.state.goal_id,
                step: step,
                request_data: location.state.request_data
            }
        })
    }

    return (

        screenLoader === true ?
            <Loader height="h-80vh" />
            :
            <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
                <div className="row ">
                    <div className="col-12 padding-144px-bottom">
                        <Header type={2} />
                        <div className="px-4 ">
                            <Breadcrumb mainPage="Home"
                                currentPage="Milestones"
                                childPage={goalDetail.admin_configured.name}
                                handleParentNavigate={_handleParentNavigate}
                                handleCurrentNavigate={_handleCurrentNavigate}
                                type={2} />


                            <div className="row pt-4 d-flex justify-content-center">
                                <div className="col-lg-6">
                                    {/* Step progress */}
                                    <StepProgress stepCompleted={configurationStep}
                                        gotToStep={_handleConfigurationStep} />

                                </div>

                            </div>
                            {
                                configurationStep === 1 ?
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-lg-6">
                                            <div className="mt-4 e-goal-bg border-radius-16px d-flex align-items-center p-3">
                                                <img src={goalDetail.admin_configured.image}
                                                    alt="goals"
                                                    width={65}
                                                    height={54} />
                                                <div className="padding-12px-left">
                                                    <h6 className="mb-1 color-eerie-black e-font-16 e-line-height-22 e-montserrat-semi-bold">{goalDetail.admin_configured.name}</h6>
                                                    <p className="mb-0 color-dim-gray e-font-14 e-line-height-20 e-montserrat-medium">{goalDetail.admin_configured.description}</p>
                                                </div>
                                            </div>

                                            <div ref={monthlyIncomeRef}>
                                                <CustomAmountInput
                                                    value={monthlyIncome}
                                                    error={monthlyIncomeError}
                                                    postfix="*"
                                                    className="mt-4"
                                                    label="How much monthly income do you need?"
                                                    placeholder="5,00,000"
                                                    handleChange={_handleMonthlyIncome} />
                                            </div>

                                            <div ref={currentExpensesRef}>
                                                <CustomAmountInput
                                                    value={currentIncome}
                                                    error={currentIncomeError}
                                                    postfix="*"
                                                    className="mt-4"
                                                    label="What are your current expenses?"
                                                    placeholder="5,00,000"
                                                    handleChange={_handleCurrentIncome} />
                                            </div>

                                            <p className={`e-montserrat-medium color-dark-charcoal e-font-14 e-line-height-18 cursor-text mb-2 bg-transparent mt-4`}>
                                                When do you want this income to start?
                                                <span className='color-deep-carmine-pink'>*</span>
                                            </p>
                                            <RadioGroup data={MONTLY_INCOME}
                                                selected={incomeStart}
                                                handleSelect={_handleIncomeStart} />

                                            <p className={`e-montserrat-medium color-dark-charcoal e-font-14 e-line-height-18 cursor-text mb-2 bg-transparent mt-3`}>
                                                How should you receive it?
                                                <span className='color-deep-carmine-pink'>*</span>
                                            </p>
                                            <RadioGroup data={RECEIVE_INCOME}
                                                selected={receiveIncome}
                                                handleSelect={_handleReceiveIncome} />


                                            <div className="mt-5 border-top-1px border-bright-gray mb-4"></div>

                                            <div className="d-flex justify-content-center">
                                                <div className="col-lg-6 col-md-4 col-6">
                                                    <PrimaryButton label="Calculate" className="padding-10px-tb w-100"
                                                        showLoader={apiLoader}
                                                        disabled={apiLoader}
                                                        onPress={_submitInvestment}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    configurationStep === 2 ?
                                        <div className="row d-flex justify-content-center">
                                            <div className="col-lg-6">
                                                <GoalInformation short_description={"Recommended Corpus:"}
                                                    locationData={locationData}
                                                    text1="To start your "
                                                    text2="regularly, you'll need a corpus of" />
                                            </div>
                                        </div>
                                        :
                                        <div className="row ">
                                            <div className="col-xl-10 col-lg-11">
                                                <GoalInvest />
                                            </div>
                                        </div>
                            }
                        </div>

                    </div>
                </div>
                {
                    riskKycCheck === true && <RiskKycConfirmationModal
                        title="Complete Risk Profile"
                        content="Please complete your Risk Profile to start with an Investment."
                        closeModal={() => _confirmRiskKyc(false)}
                        handleConfirm={_handleNavigateToScreen} />
                }
            </div>

    )
}

export default TaxSavings;