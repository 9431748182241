/*
*   File : verify-pan.js
*   Author : https://evoqins.com
*   Description : Screen to verify PAN 
*   Version : 1.0.0
*/

// import packages
import { Fragment, useEffect, useState } from "react";
import { Modal } from "bootstrap";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

// import components
import { CustomTextInput, RadioGroup } from "../../../Components/FormElements";
import { PrimaryButton } from "../../../Components/Buttons";
import { KYCStatusModal, NRIKYCStatusModal } from "../../../Components/Modal";
import { KYCErrorCard } from "../../../Components/Cards";

// import services
import APIService from "../../../Services/api-service";
import { _getProfile, _getSignupProgress } from "../../../Helper/api";


const INVESTOR_TYPES = [
    {
        id: 1,
        name: "Yes"
    },
    {
        id: 2,
        name: "No"
    },
];
const VerifyPAN = (props) => {

    const PROGRESS_DATA = useSelector(state => state.Reducer.SIGNUP_PROGRESS);
    const PROFILE_DETAILS = useSelector(state => state.Reducer.PROFILE_DETAILS);

    const [pan, setPan] = useState("");
    const [panError, setPanError] = useState("");

    const [name, setName] = useState(PROFILE_DETAILS.name);
    const [nameError, setNameError] = useState("");

    const [apiLoader, setApiLoader] = useState(false);

    const [investorType, setInvestorType] = useState(INVESTOR_TYPES[1].id);
    const [showStatus, setShowStatus] = useState(false);
    // const [acceptedTerms, setAcceptedTerms] = useState(false);

    const [kycStatus, setKycStatus] = useState(false);
    const [validationUrl, setValidationUrl] = useState("");

    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if (PROGRESS_DATA.kyc_data.pan !== null) {
            setPan(PROGRESS_DATA.kyc_data.pan);
        };
        if (PROGRESS_DATA.kyc_data.name !== null) {
            setName(PROGRESS_DATA.kyc_data.name);
        }
        setInvestorType(PROGRESS_DATA.kyc_data.is_nri === true ? 1 : 2);

        if (PROGRESS_DATA.step_detail[0].error === true) {
            setErrors(PROGRESS_DATA.step_detail[0].message);
        }

    }, [PROGRESS_DATA]);

    useEffect(() => {
        if (showStatus === true) {
            const modal = new Modal(document.getElementById("nri-kyc-status"));
            modal.show();
        }
    }, [showStatus]);

    useEffect(() => {
        if (kycStatus === true) {
            const modal = new Modal(document.getElementById("check-kyc"));
            modal.show();
        }
    }, [kycStatus]);


    // PAN handler
    function _handlePAN(input_value) {
        if (input_value.length <= 10) {
            setPan(input_value);
        }
        setPanError("");
    }

    // name handler
    function _handleName(input_value) {
        setName(input_value);
        setNameError("");
    }

    // name investor
    function _handleInvestor(input_value) {
        setInvestorType(input_value);
    }


    function _showStatus(status) {
        setShowStatus(status);
    }

    function _showKyc(status) {
        setKycStatus(status)
    }


    // submit PAN details
    function _submitPAN() {
        let valid = true;
        let validate_pan = /^[A-Z]{3}P[A-Z]{1}\d{4}[A-Z]{1}$/; // validation of PAN
        const pan_value = pan.toLocaleUpperCase();
        if (pan === "") {
            setPanError("Provide your PAN card number to continue");
            valid = false;
        }
        if (pan !== "" && !validate_pan.test(pan_value)) {
            setPanError("Please provide a valid PAN number");
            valid = false;
        }

        if (name === "") {
            setNameError("Please enter your name as per PAN");
            valid = false;
        }

        // if (acceptedTerms === false) {
        //     toast.error("Accept consent", {
        //         type: "error"
        //     });
        //     valid = false
        // }

        if (valid === true) {
            _verifyKyc()
        }
    }

    // API - verify kyc
    const _verifyKyc = () => {
        setApiLoader(true);
        const url = "/kyc/check-kyc-status";
        const user_name = name.trim()
        const request = {
            pan: pan.toLocaleUpperCase(),
            name: user_name,
            is_nri: investorType === 1 ? true : false
        };

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                if (response.data.non_kyc_nri === true) {
                    setApiLoader(false);
                    _showStatus(true);
                    return; // exit from flow since we can't do allow customer to create investment account 
                }
                if (response.data.modify_kyc === true) {
                    setValidationUrl(response.data.validation_url);
                    setApiLoader(false);
                    _showKyc(true);
                    return;
                }
                _getSignupProgress().then((kyc_response) => {
                    props.handleNavigate();
                    _getProfile();
                    toast.success("PAN verified successfully", {
                        type: "success"
                    })
                })
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error"
                })
            }
            setApiLoader(false);
        });
    }

    return (
        <Fragment>
            <h5 className="color-dark-jungle-green e-font-18 e-line-height-38 e-montserrat-semi-bold mb-lg-3 mb-0 padding-12px-tb">Account Setup</h5>
            <div className="row">
                <div className="col-md-7 pe-md-5">

                    {/* title */}
                    <h6 className="color-eerie-black e-font-20 e-line-height-32 e-montserrat-semi-bold mb-2">Let's start with your PAN</h6>
                    {/* <p className="color-gunmental e-font-16 e-line-height-32 e-montserrat-regular mb-0">Norem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}

                    {
                        errors.length > 0 &&
                        <KYCErrorCard errors={errors} />
                    }
                    <div className="row padding-32px-top">
                        {/* PAN number */}
                        <div className="col-lg-6 pb-lg-0 pb-4">
                            <CustomTextInput label="Enter your PAN"
                                postfix="*"
                                placeholder="Enter your PAN number"
                                value={pan}
                                error={panError}
                                onSubmit={_submitPAN}
                                inputClass="text-transform-upper"
                                handleChange={_handlePAN} />
                        </div>

                        {/* investor name */}
                        <div className="col-lg-6 pb-4">
                            <CustomTextInput label="Name ( As per PAN Card)"
                                postfix="*"
                                placeholder="Enter your name"
                                value={name}
                                error={nameError}
                                onSubmit={_submitPAN}
                                handleChange={_handleName} />
                        </div>

                        {/* investor type */}
                        <div className="col-lg-12 col-md-6 col-sm-6">
                            <p className={`e-montserrat-medium color-dark-charcoal e-font-14 e-line-height-18 cursor-text mb-0 bg-transparent`}>
                                Are you a NRI Investor?
                                <span className='color-deep-carmine-pink'>
                                    *
                                </span>
                            </p>
                            <RadioGroup selected={investorType}
                                data={INVESTOR_TYPES}
                                handleSelect={_handleInvestor} />
                        </div>
                        {/* <div className="d-md-block d-none">
                            <ConsentCard acceptedTerms={acceptedTerms}
                                type={2}
                                setAcceptedTerms={() => setAcceptedTerms(!acceptedTerms)} />
                        </div> */}
                    </div>

                    <div className="border-top-1px border-bright-gray margin-40px-top mb-md-4 mb-3"></div>
                    {/* submit data  */}
                    <div className="col-xl-3 col-lg-4 col-md-6 d-md-block d-none">
                        <PrimaryButton label="Verify & continue"
                            disabled={apiLoader}
                            className="w-100 padding-12px-tb"
                            onPress={_submitPAN} />
                    </div>
                </div>

                <div className="col-md-5 pt-md-0 d-flex flex-column">
                    <p className="e-montserrat-semi-bold e-font-16 e-line-height-30 color-eerie-black mb-0 d-md-block d-none">For Title:</p>
                    <p className="e-montserrat-medium e-font-16 e-line-height-30 color-eerie-black d-md-block d-none">Just for reference.</p>
                    <img src={require("../../../Assets/Images/KYC/PAN.png")}
                        alt="PAN"
                        draggable={false}
                        className="w-100 h-auto d-md-block d-none" />
                    {/* <div className="d-md-none d-flex">
                        <ConsentCard acceptedTerms={acceptedTerms}
                            setAcceptedTerms={() => setAcceptedTerms(!acceptedTerms)} />
                    </div> */}
                    <div className="col-12 d-md-none d-block pt-4 mt-auto">
                        <PrimaryButton label="Verify & continue"
                            disabled={apiLoader}
                            className="w-100 padding-12px-tb"
                            onPress={_submitPAN} />
                    </div>

                </div>
                {
                    showStatus === true && <NRIKYCStatusModal type={2}
                        closeModal={() => _showStatus(false)}
                        handleNavigate={() => {
                            _showStatus(false);
                        }} />
                }

                {
                    kycStatus === true && <KYCStatusModal
                        closeModal={() => _showKyc(false)}
                        handleConfirm={() => {
                            window.open(validationUrl, "_blank")
                        }} />
                }
            </div>
        </Fragment>
    )
}

export default VerifyPAN;