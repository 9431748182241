/*
 *   File : radio-group.js
 *   Author : https://evoqins.com
 *   Description : Radio button component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';

import { Icon } from '../Icon';

const RadioGroup = React.memo((props) => {

    // id , name
    const [radioData, setRadioData] = useState([]);
    useEffect(() => {
        if (props.data) {
            setRadioData(props.data);
        }
    }, [props.data]);

    return (
        <>

            <div className={`d-flex flex-wrap gap-16px e-radio-gap py-1 ${props.className}`}>
                {/* <div className='d-flex gap-16px'> */}

                {radioData.map((data, key) => (
                    <div className='d-flex align-items-center gap-8px cursor-pointer'
                        key={key}
                        onClick={() => props.handleSelect(data.id)}>
                        <Icon icon={props.selected === data.id ? "radio-checked" : "radio-unchecked"}
                            size={24}
                            className="d-sm-block d-none min-w-24px" />
                        <Icon icon={props.selected === data.id ? "radio-checked" : "radio-unchecked"}
                            size={20}
                            className="d-sm-none d-block min-w-20px" />
                        <p className={`mb-0 color-dark-charcoal e-font-14 e-montserrat-medium e-line-height-24`}>
                            {data.name}
                        </p>
                    </div>
                ))}
            </div>
        </>
    )
})

RadioGroup.defaultProps = {
    handleSelect: () => { }
}

export default RadioGroup;