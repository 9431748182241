/*
 *   File : update-dob.js
 *   Author : https://evoqins.com
 *   Description : Modal component save data to update financial pyramid.
 *   Integrations : lodash
 *   Version : 1.0.0
*/
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

// import components
import { Icon } from "../Icon";
import { CustomAmountInput, CustomDatePicker, RadioGroup } from "../FormElements";
import { PrimaryButton, SecondaryButton } from "../Buttons";

// import custom styles
import style from "../../Styles/Components/dob.module.scss";
import Color from '../../Styles/color.module.scss';

// import services
import APIService from "../../Services/api-service";
import { _formatDate } from "../../Helper/helper";
import { _getProfile } from "../../Helper/api";

const UpdateDobModal = React.memo((props) => {
    const today = new Date();
    const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

    const disabledDays = { after: eighteenYearsAgo };

    const PROFILE_DATA = useSelector((state) => state.Reducer.PROFILE_DETAILS);
    const [apiLoader, setApiLoader] = useState(false);

    const [dob, setDob] = useState("DD-MM-YYYY");
    const [dobError, setDobError] = useState("");
    const [annualIncome, setAnnualIncome] = useState("");
    const [annualIncomeError, setAnnualIncomeError] = useState("");
    const [familyStructure, setFamilyStructure] = useState([]);
    const [selectedFamilyStructure, setSelectedFamilyStructure] = useState(null);

    const [monthlyExpense, setMonthlyExpense] = useState("");
    const [monthlyExpenseError, setMonthlyExpenseError] = useState("");

    const [defaultMonth, setDefaultMonth] = useState(eighteenYearsAgo);

    useEffect(() => {
        const my_modal = document.getElementById("update-dob");
        const handleHidden = () => {
            props.closeModal();
            // Remove all extra backdrops
            document.querySelectorAll('.modal-backdrop').forEach(backdrop => backdrop.remove());
            document.body.removeAttribute('style')
            document.body.removeAttribute('class')
        };

        my_modal.addEventListener("hidden.bs.modal", handleHidden);

        if (PROFILE_DATA.formated_dob !== null) {
            setDob(PROFILE_DATA.formated_dob);
        }
        setFamilyStructure(props.familyStructure);
        setSelectedFamilyStructure(props.familyStructure[0].id);

        // Clean up event listener
        return () => {
            my_modal.removeEventListener("hidden.bs.modal", handleHidden);
        };
    }, [PROFILE_DATA, props]);

    // handle dob
    function _handleDate(selected_date) {
        const formatted_start_date = _formatDate(selected_date);
        setDob(formatted_start_date);
        setDefaultMonth(selected_date);
        setDobError("");
    }

    // handle amount
    function _handleAmount(input_value) {
        setAnnualIncome(input_value);
        setAnnualIncomeError("");
    }


    // _handle monthly expense
    function _handleMonthlyExpense(value) {
        setMonthlyExpense(value);
        setMonthlyExpenseError("");
    }


    // handle Acc type
    function _handleFamilyStructure(id) {
        setSelectedFamilyStructure(id);
    }

    function _submitDob() {
        let valid = true;
        if (dob === "DD-MM-YYYY") {
            setDobError('DOB is required');
            valid = false;
        }
        if (annualIncome === "") {
            setAnnualIncomeError("Annual income is required");
            valid = false;
        }
        if (monthlyExpense < 5000) {
            setMonthlyExpenseError("Monthly income is required");
            valid = false;
        }
        if (valid) {
            _updateDob();
        }
    }

    // API - update DOB
    const _updateDob = () => {
        setApiLoader(true);

        const url = "/home/save-data";

        const request = {
            dob: dob,
            annual_income: annualIncome,
            family_structure: selectedFamilyStructure,
            monthly_expense: monthlyExpense
        };

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                _getProfile();
                toast.dismiss();
                toast.success("Financial details updated successfully", {
                    type: "success"
                })
                document.getElementById("close-modal").dispatchEvent(new Event("click"));
            } else {
                toast.error(response.message, {
                    type: 'error'
                });
            }
            setApiLoader(false);
        });
    };

    return (
        <div className={`modal fade ${style.e_dob}`}
            id="update-dob"
            tabIndex="-1"
            aria-labelledby="update-dob"
            aria-hidden="true"
            data-bs-backdrop="static"
            data-bs-keyboard="false">
            <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                <div className={`modal-content ps-4 pe-2 pb-4 ${style.e_content}`}>

                    <Icon icon="close-btn"
                        size={24}
                        color={Color.eerie_black}
                        id="close-modal"
                        className="cursor-pointer d-none"
                        data-bs-dismiss="modal" />

                    <img src={require("../../Assets/Images/Modal/dob.png")}
                        alt="success"
                        draggable={false}
                        width={144}
                        height={144}
                        className="mx-auto" />
                    <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-eerie-black mb-2 padding-10px-top text-center">
                        Configure Financial Pyramid
                    </h5>

                    <p className="e-montserrat-medium e-font-16 e-line-height-20 color-charcoal-gray mb-0 text-center pb-3">
                        Your essential companion for mastering Financial well-being
                    </p>

                    <div className={style.e_fields}>
                        <div className="row">
                            <div className={`col-12 mb-4 pb-1 ${PROFILE_DATA.formated_dob !== null && "disabled"}`}>
                                <CustomDatePicker error={dobError}
                                    disabledDays={disabledDays}
                                    label="Enter your date of birth"
                                    postfix={true}
                                    start={dob}
                                    toYear={new Date().getFullYear() - 18}
                                    mode="single"
                                    defaultMonth={defaultMonth}
                                    selectDate={_handleDate} />
                            </div>
                            <div className="col-12 mb-4 pb-1">
                                <CustomAmountInput className=""
                                    value={annualIncome}
                                    error={annualIncomeError}
                                    postfix="*"
                                    label="Annual income"
                                    placeholder="8,00,000"
                                    handleChange={_handleAmount} />
                            </div>
                            <div className="col-12 mb-3 pb-1">
                                <CustomAmountInput className=""
                                    value={monthlyExpense}
                                    error={monthlyExpenseError}
                                    postfix="*"
                                    label="What is your current monthly expenses?"
                                    placeholder="50,000"
                                    handleChange={_handleMonthlyExpense} />

                            </div>
                            <div className="col-12 mb-3">
                                <p className={`e-montserrat-medium color-dark-charcoal e-font-14 e-line-height-20 cursor-text mb-2 bg-transparent`}>
                                    Your family structure or number of dependents you have
                                    <span className='color-deep-carmine-pink'>*</span>
                                </p>
                                <RadioGroup data={familyStructure}
                                    selected={selectedFamilyStructure}
                                    handleSelect={_handleFamilyStructure} />
                            </div>



                        </div>
                    </div>
                    <div className="row pt-3 pe-2">
                        <div className="col-6">
                            <SecondaryButton label="Cancel"
                                cancel="modal"
                                className="w-100 padding-12px-tb" />
                        </div>
                        <div className="col-6">
                            <PrimaryButton label="Continue"
                                className="w-100 padding-12px-tb"
                                onPress={_submitDob}
                                disabled={apiLoader}
                                showLoader={apiLoader} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default UpdateDobModal;
