/*
 *   File : empty-screen.js
 *   Author : https://evoqins.com
 *   Description : Empty screen
 *   Version : 1.0.0
*/

import GlobalEmpty from "../../Assets/Images/Global/empty-funds.png";
import OrderEmpty from "../../Assets/Images/Global/order-empty.png"
import SipEmpty from "../../Assets/Images/Global/sip-empty.png"
import SupportEmpty from "../../Assets/Images/Global/support-empty.png";
import BasketEmpty from "../../Assets/Images/Portfolio/empty-basket.png"
import NotificationEmpty from "../../Assets/Images/Global/notification-empty.png"
import PortfolioEmpty from "../../Assets/Images/Portfolio/internal.png";
import NoInternet from '../../Assets/Images/Loader/no-internet.png';

const EmptyScreen = (props) => {

    const _image = () => {
        switch (props.type) {
            case 1:
                return GlobalEmpty
            case 2:
                return OrderEmpty
            case 3:
                return SipEmpty
            case 4:
                return SupportEmpty
            case 5:
                return NotificationEmpty
            case 6:
                return PortfolioEmpty;
            case 7:
                return BasketEmpty
            case 8:
                return NoInternet
            default:
                return GlobalEmpty
        }
    }
    return (
        <div className={`row d-flex flex-column justify-content-center align-items-center ${props.className}`}>
            <div className="col-sm-6 text-center">
                <img src={_image()}
                    alt={props.title}
                    draggable={false}
                    width={props.width ? props.width : 250}
                    className={`h-100 d-sm-block d-none mx-auto  ${props.height ? props.height : "w-100 object-fit-cover "}`} />
                <img src={_image()}
                    alt={props.title}
                    draggable={false}
                    width={props.width ? props.width : 250}
                    className={`h-100 d-sm-none d-block w-100 px-3 ${props.height ? props.height : ""}`} />
                <p className="e-montserrat-semi-bold e-font-18 e-line-height-32 color-charleston-green mt-2 mb-1">{props.title}</p>
                <p className="e-montserrat-regular e-font-16 e-line-height-24 color-dark-grayish-blue mb-0">{props.description}</p>
            </div>
        </div>
    )
}

export default EmptyScreen;