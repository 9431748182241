
/*
 *   File : goals-success.js
 *   Author URI : https://evoqins.com
 *   Description : Goal success 
 *   Integrations : null
 *   Version : v1.1
 */

// Packages
import { Fragment, memo, useEffect } from "react";

// Custom components
import { LinkButton, PrimaryButton, SecondaryButton } from "../Buttons";

// Custom styles
import style from "../../Styles/Components/fund-allocation.module.scss";

const PrimaryBasketStatusModal = (props) => {

    useEffect(() => {
        var my_modal = document.getElementById("goal-success");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
            document.body.removeAttribute("style")
        });
        // eslint-disable-next-line
    }, []);


    return (
        <Fragment>
            <div className={`modal fade ${style.e_confirm_modal}`}
                id="goal-success"
                tabIndex="-1"
                aria-labelledby="goal-success"
                aria-hidden="true"
                data-bs-backdrop="static" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content pb-4  text-center px-4 ${style.e_content} `}>

                        <Fragment>
                            <img src={props.data.image}
                                alt={props.data.title}
                                draggable={false}
                                width={144}
                                height={144}
                                className="mx-auto" />
                            <h5 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-eerie-black mb-0">
                                {props.data.title}
                            </h5>

                            <p className="e-montserrat-medium e-font-16 e-line-height-28 color-charcoal-gray mt-2 mb-0 ">
                                {props.data.description} {props.data.type === 1 && props.isFirstInstallment === 1 && <LinkButton label="View TAT"
                                    className="e-font-14 d-inline-block"
                                    onPress={() => window.open(`${window.location.origin}/view-tat`, "_blank")} />}
                            </p>


                            <div className="row tow-gap-8x d-flex align-items-center justify-content-center mt-4">

                                <div className="col-6">
                                    <SecondaryButton label={(props.type === "lumpsum_only" || props.type === "sip_and_lumpsum") ?
                                        "View Order" :
                                        props.isFirstInstallment === 2 ? "View SIP" : "View Order"}
                                        cancel="modal"
                                        className="padding-12px-tb w-100 e-bg-magnolia"
                                        onPress={() => props.handleSecondaryButtonClick()} />
                                </div>


                                <div className="col-6">
                                    <PrimaryButton label={props.data.type === 1 ? "Portfolio" : "Go back"}
                                        className=" padding-12px-tb w-100"
                                        cancel="modal"
                                        onPress={() => props.data.type === 1 && props.handlePrimaryButtonClick()} />
                                </div>

                            </div>
                        </Fragment>
                    </div>

                </div>
            </div>
        </Fragment>
    )
}

export default memo(PrimaryBasketStatusModal)