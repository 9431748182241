
/*
 *   File : verify-email.js
 *   Author URI : https://evoqins.com
 *   Description : Verify Email modal 
 *   Integrations : null
 *   Version : v1.1
 */

// Packages
import { Fragment, memo, useEffect } from "react";
import { useState } from "react";

// Custom components
import { Icon } from "../Icon";
import { PrimaryButton, SecondaryButton } from "../Buttons";
import { CustomPINInput, CustomTextInput } from "../FormElements";

// Custom styles
import style from "../../Styles/Components/email.module.scss";
import Color from '../../Styles/color.module.scss';

// custom hooks
import { useCountdown } from "../../Helper/helper";


// type  1 ---- success
// else failed

const VerifyEmailModal = (props) => {
    const { seconds, resetCountdown } = useCountdown(30);

    const [verifyStep, setVerifyStep] = useState(1);

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");

    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");

    useEffect(() => {
        if (props.email !== null) {
            setEmail(props.email);
        }
    }, [props.email]);

    useEffect(() => {
        var my_modal = document.getElementById("verify-email");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
            setEmail("");
            setEmailError("");
            setVerifyStep(1);
            setOtp("");
            setOtpError("")
        });
        //eslint-disable-next-line
    }, []);


    useEffect(() => {
        if (verifyStep === 2) {
            resetCountdown();
        }
        // eslint-disable-next-line
    }, [verifyStep]);

    // handle Email
    function _handleEmail(input_value) {
        setEmail(input_value);
        setEmailError("");
    }

    // handle OTP
    function _handleOTP(input_value) {
        if (input_value.length <= 4) {
            setOtp(input_value);
        }
        setOtpError("");
    }

    // resend OTP
    function _handleResend() {
        resetCountdown();
        setOtp("");
        props.confirm(email, "RESEND");
    }

    // handle submit email validation
    function _submitEmail() {
        let valid = true;
        const email_value = email.trim()
        if (email_value === "") {
            setEmailError("Email id cannot be empty");
            valid = false
        }
        // eslint-disable-next-line
        if (email_value !== "" && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email_value)) {
            setEmailError("Invalid email");
            valid = false
        }
        if (valid === true) {
            _getEmailOTP();
        }
    }

    // handle submit otp validation
    function _submitOTP() {
        let valid = true;
        if (otp === "") {
            setOtpError("OTP is required");
            valid = false;
        }
        if (otp !== "" && otp.length !== 4) {
            setOtpError("Invalid OTP");
            valid = false;
        }
        if (valid === true) {
            _verifyEmailOTP();
        }
    }

    // send email OTP helper
    const _getEmailOTP = async () => {
        try {
            const result = await props.confirm(email, null);

            if (result === true) {
                setVerifyStep(2);
            }

        } catch (error) {

        }
    }

    // verify email OTP helper
    const _verifyEmailOTP = async () => {
        try {
            const result = await props.verify(otp);
            if (result === true) {
                document.getElementById("close-modal").dispatchEvent(new Event("click"));
                props.emailVerified();
            }
        } catch (error) {

        }
    }

    return (
        <Fragment>
            <div className={`modal fade ${style.e_email}`}
                id="verify-email"
                tabIndex="-1"
                aria-labelledby="select-career"
                aria-hidden="true"
                data-bs-backdrop="static"
                data-bs-keyboard="false">
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    {
                        verifyStep === 2 ?
                            <div className={`modal-content pb-4 ${style.e_content} `}>
                                <div className="border-bottom-1px border-ash-gray px-4">
                                    <div className="d-flex justify-content-between align-items-center pt-4 pb-3">
                                        <h6 className='e-font-16 mb-0 color-eerie-black e-montserrat-semi-bold e-line-height-24'>
                                            Enter OTP
                                        </h6>
                                        <Icon icon="close-btn"
                                            size={24}
                                            color={Color.eerie_black}
                                            id="close-modal"
                                            className="cursor-pointer"
                                            data-bs-dismiss="modal" />
                                    </div>
                                </div>
                                <div className="px-4 padding-14px-top">
                                    <p className="e-font-14 mb-2 color-eerie-black e-montserrat-regular e-line-height-24">
                                        An OTP has been sent to your email
                                        address
                                    </p>
                                    <h6 className="e-font-14 mb-3 color-eerie-black e-montserrat-semi-bold e-line-height-24">{email}</h6>
                                    <CustomPINInput id="otp-input"
                                        autoFocus={true}
                                        pinString={otp}
                                        error={otpError}
                                        postfix="*"
                                        label="Enter OTP"
                                        onSubmit={_submitOTP}
                                        handleChange={_handleOTP} />
                                    <p className="color-charcoal-blue e-montserrat-medium e-font-14 e-line-height-20 mt-4">Didn’t get OTP? <span className={`no-decoration color-rebecca-purple e-link ${seconds === 0 ? 'cursor-pointer' : 'disabled'}`} onClick={_handleResend}>{seconds === 0 ? 'Resend OTP' : ` Resend OTP in ${seconds < 10 ? `0${seconds}` : seconds}s`}</span></p>
                                    <div className="row margin-32px-top">
                                        <div className="col-6">
                                            <SecondaryButton label="Cancel"
                                                cancel="modal"
                                                className="padding-12px-tb w-100 e-bg-magnolia" />
                                        </div>
                                        <div className="col-6">
                                            <PrimaryButton label="Verify"
                                                className="padding-12px-tb  w-100"
                                                disabled={props.loader}
                                                onPress={_submitOTP} />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            :
                            <div className={`modal-content padding-36px-bottom ${style.e_content} `}>
                                <div className="border-bottom-1px border-ash-gray px-4">
                                    <div className="d-flex justify-content-between align-items-center pt-4 pb-3">
                                        <h6 className='e-font-16 mb-0 color-eerie-black e-montserrat-semi-bold e-line-height-24'>
                                            Verify email id
                                        </h6>
                                        <Icon icon="close-btn"
                                            size={24}
                                            color={Color.eerie_black}
                                            id="close-modal"
                                            className="cursor-pointer"
                                            data-bs-dismiss="modal" />
                                    </div>
                                </div>
                                <div className="px-4 padding-14px-top">
                                    <h6 className="e-font-14 mb-2 color-eerie-black e-montserrat-semi-bold e-line-height-24">Enter your email address</h6>
                                    <p className="e-font-14 mb-4 color-eerie-black e-montserrat-regular e-line-height-24">We will send you a verification email to confirm your email address and complete the verification process.</p>
                                    <CustomTextInput type="email"
                                        value={email}
                                        error={emailError}
                                        postfix="*"
                                        label="Enter your email"
                                        placeholder="Eg: rahul@gmail.com"
                                        inputClass="text-transform-lower"
                                        handleChange={_handleEmail}
                                        onSubmit={_submitEmail} />
                                    <div className="d-flex justify-content-center mt-5">
                                        <PrimaryButton label="Get OTP"
                                            disabled={props.loader}
                                            className="padding-12px-tb padding-66px-lr"
                                            onPress={_submitEmail} />
                                    </div>
                                </div>

                            </div>
                    }

                </div>
            </div>
        </Fragment>
    )
}

export default memo(VerifyEmailModal)