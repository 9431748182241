/*
*   File : verify-pan.js
*   Author : https://evoqins.com
*   Description : Screen to verify PAN 
*   Version : 1.0.0
*/

// import packages
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom/dist";
import { useSelector } from "react-redux";

// import components
import { Header } from "../../Components/Header";
import { Breadcrumb, Loader } from "../../Components/Other";
import { AddressDetails, BankDetails, FetchAadhaar, NomineeDetails, PersonalDetails, Summary, VerifyEmail, VerifyPAN } from "./Helper";


// services, helper
import { _getSignupProgress } from "../../Helper/api";

const KYC = () => {

    const PROGRESS_DATA = useSelector(store => store.Reducer.SIGNUP_PROGRESS);

    const navigate = useNavigate();

    const [kycStep, setKycStep] = useState(0);

    const [panVerified, setPanVerified] = useState(false);
    const [investmentStepCompleted, setInvestmentStepCompleted] = useState(0);
    const [kycUser, setKycUser] = useState(false);
    const [isDigiSkipped, setIsDigiSkipped] = useState(false);

    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState([]);


    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [kycStep]);

    useEffect(() => {
        if (Object.keys(PROGRESS_DATA).length > 0) {
            const signup_progress = PROGRESS_DATA
            setKycUser(signup_progress.kyc_data.is_kyc_compliant);
        }
    }, [PROGRESS_DATA])

    useEffect(() => {

        _getSignupProgress().then((response) => {
            let status = response.kyc_data.status;
            let current_step = response.current_step;
            setKycUser(response.kyc_data.is_kyc_compliant);
            const allErrors = response.step_detail.reduce((acc, step) => {
                if (step.errors) {
                    acc = acc.concat(step.errors);
                }
                return acc;
            }, []);
            setErrors(allErrors);

            if (status === "KYC_SUBMITTED" || status === "INVESTED" || status === "ACCOUNT_CREATED") {
                navigate("/", {
                    replace: true,
                });
            }
            else if (status === "KYC_REJECTED") {
                setKycStep(1);
                setPanVerified(true);
            }
            else if (current_step >= 0 && current_step <= 9) {
                if (current_step < 1) {
                    setKycStep(0);
                    setPanVerified(false);
                } else {
                    setPanVerified(true);
                    setKycStep(1);
                }
            } else {
                navigate("/", {
                    replace: true,
                });
            }
            setLoading(false);
        })
        //eslint-disable-next-line
    }, []);



    // navigate to home
    function _handleNavigate() {
        navigate("/");
    }

    function _handleNavigateStep(step) {
        if (PROGRESS_DATA.kyc_data.status === "KYC_REJECTED" && kycStep === 1) {
            setKycStep(0);
            setPanVerified(false);
        }
        else if (PROGRESS_DATA.kyc_data.status === "KYC_REJECTED" && kycStep === 0) {
            setKycStep(2);
            setInvestmentStepCompleted(step);
        }
        else {
            setInvestmentStepCompleted(step - 1);
            setKycStep(step);
        }

    }

    function _handleGoToStep(step) {
        setKycStep(step)

    }

    return (
        <Fragment>
            {
                loading === true ?
                    <Loader height="h-80vh" />
                    :
                    <div className="bg-white border-radius-tl-40px border-radius-bl-40px h-80 pb-5">

                        {/* Header */}
                        <Header type={2} />
                        <div className="row ">
                            <div className="col-lg-11 pe-lg-0">

                                <div className="px-4">
                                    <Breadcrumb mainPage="Home"
                                        currentPage="Account Setup"
                                        handleNavigate={_handleNavigate}
                                        type={1}
                                        className="mb-md-3 mb-0" />

                                    {
                                        panVerified === false ?
                                            kycStep === 0 ?
                                                <VerifyPAN handleNavigate={() => {
                                                    _handleNavigateStep(kycStep + 1);
                                                    setPanVerified(true);
                                                }} />
                                                :

                                                null
                                            :
                                            kycStep === 1 ?
                                                <Summary
                                                    errors={errors}
                                                    completedStep={investmentStepCompleted}
                                                    handleNavigate={() => _handleNavigateStep(kycStep + 1)} />
                                                :
                                                kycStep === 2 ?
                                                    <VerifyEmail completed={investmentStepCompleted}
                                                        navigateBack={() => _handleGoToStep(kycStep - 1)}
                                                        handleNavigate={() => _handleNavigateStep(kycStep + 1)} />
                                                    :
                                                    kycStep === 3 ?
                                                        <PersonalDetails completed={investmentStepCompleted}
                                                            navigateBack={() => _handleGoToStep(kycStep - 1)}
                                                            handleNavigate={(main_step, digi_skipped) => {
                                                                _handleNavigateStep(main_step);
                                                                setIsDigiSkipped(digi_skipped)
                                                            }}
                                                        />
                                                        :
                                                        kycStep === 4 ?
                                                            kycUser === true ?
                                                                <AddressDetails completed={investmentStepCompleted}
                                                                    navigateBack={() => _handleNavigateStep(kycStep - 1)}
                                                                    handleNavigate={() => _handleNavigateStep(kycStep + 1)}
                                                                />
                                                                :
                                                                <FetchAadhaar completed={investmentStepCompleted}
                                                                    navigateBack={() => _handleGoToStep(kycStep - 1)}
                                                                    handleNavigate={() => {
                                                                        setIsDigiSkipped(true);
                                                                        _handleNavigateStep(kycStep + 1)
                                                                    }}
                                                                />
                                                            :
                                                            kycStep === 5 ?
                                                                kycUser === true ?
                                                                    <BankDetails completed={investmentStepCompleted}
                                                                        navigateBack={() => _handleNavigateStep(kycStep - 1)}
                                                                        handleNavigate={() => _handleNavigateStep(kycStep + 1)}
                                                                    />
                                                                    :
                                                                    <AddressDetails completed={investmentStepCompleted}
                                                                        navigateBack={() => {
                                                                            if (isDigiSkipped === true) {
                                                                                _handleGoToStep(3);
                                                                            } else {
                                                                                _handleGoToStep(kycStep - 1);
                                                                            }
                                                                        }}
                                                                        handleNavigate={() => _handleNavigateStep(kycStep + 1)}
                                                                    />
                                                                :
                                                                kycStep === 6 ?
                                                                    kycUser === true ?
                                                                        <NomineeDetails completed={investmentStepCompleted}
                                                                            navigateBack={() => _handleNavigateStep(kycStep - 1)}
                                                                            handleNavigate={() => navigate("/")} />
                                                                        :
                                                                        <BankDetails completed={investmentStepCompleted}
                                                                            navigateBack={() => _handleGoToStep(kycStep - 1)}
                                                                            handleNavigate={() => _handleNavigateStep(kycStep + 1)}
                                                                        />
                                                                    :
                                                                    kycStep === 7 ?
                                                                        <NomineeDetails completed={investmentStepCompleted}
                                                                            navigateBack={() => _handleGoToStep(kycStep - 1)}
                                                                            handleNavigate={() => navigate("/")} />
                                                                        :
                                                                        null

                                    }

                                </div>
                            </div>
                        </div>
                    </div>}
        </Fragment>
    )
}

export default KYC;