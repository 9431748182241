/*
*   File : index.js
*   Author : https://evoqins.com
*   Description : Risk profiling screen
*   Version : 1.0.0
*/

// import packages
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal } from "bootstrap";

// import components
import { Header } from "../../Components/Header";
import { Breadcrumb, Loader, StepProgress } from "../../Components/Other";
import { PersonalRiskReview, PersonalRiskView, RiskScore } from "./Helper";
import APIService from "../../Services/api-service";
import { Icon } from "../../Components/Icon";
import { RiskScoreModal } from "../../Components/Modal";

// import style
import Color from "../../Styles/color.module.scss";

const RiskProfiling = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [screenLoader, setScreenLoader] = useState(true);
    const [questionData, setQuestionData] = useState({});
    const [configurationStep, setConfigurationStep] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [apiLoader, setApiLoader] = useState(false);
    const [chartData, setChartData] = useState({});
    const [forceStepThree, setForceStepThree] = useState(false);
    const [fromFund, setFromFund] = useState(false);
    const [fundId, setFundId] = useState(null);

    useEffect(() => {
        _getQuestions();
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [configurationStep]);

    useEffect(() => {
        if (location.state !== null) {
            setConfigurationStep(location.state.step);
            if (location.state.from_fund) {
                setFromFund(location.state.from_fund);
                setFundId(location.state.id);
            }
            if (location.state.chart) {
                setChartData(location.state.chart);
            }
        } else {
            setConfigurationStep(1);
        }
    }, [location]);

    useEffect(() => {
        if (forceStepThree === true) {
            // Ensure the modal is in the DOM before attempting to close it
            setTimeout(() => {
                const closeModalButton = document.getElementById("close-modal");
                if (closeModalButton) {
                    closeModalButton.dispatchEvent(new Event("click"));
                    setShowModal(false);
                }
                setForceStepThree(false);
                navigate(location.pathname, {
                    state: {
                        step: 3,
                        chart: chartData,
                        from_fund: fromFund,
                        id: fundId
                    }
                });
            }, 2000);
        }
        //eslint-disable-next-line
    }, [forceStepThree])

    useEffect(() => {
        if (showModal === true) {
            const modal = new Modal(document.getElementById("risk-score"));
            modal.show();
        }
    }, [showModal]);

    function _handleNavigate() {
        navigate("/");
    }

    // navigate to step 2
    function _handleNavigateToStep(step) {
        if (_validate()) {
            setApiLoader(true);
            _submitAnswers(step);
        }
    }

    // handle to go back previous step
    function _handleConfigurationStep(step) {
        navigate(location.pathname, {
            state: {
                step: step,
                from_fund: fromFund,
                id: fundId
            }
        })
    }

    // validate form
    const _validate = () => {
        let valid = true;
        const updatedData = questionData.map(category => {
            if (category.order === 1) {
                const updatedQuestions = category.questions.map(question => {
                    if (question.question_type === 3 && (question.answer === null || question.answer.length === 0)) {
                        valid = false;
                        return { ...question, error: "Please enter valid input" };
                    }
                    return question;
                });

                return { ...category, questions: updatedQuestions };
            }
            return category;
        });
        setQuestionData(updatedData);

        return valid;
    }

    const _handleChange = (value, questionId, label) => {
        const updatedData = questionData.map(category => {
            // handler for order type 1 
            if (category.order === 1) {
                return {
                    ...category,
                    questions: category.questions.map(question => {
                        if (question.question_id === questionId) {
                            // for question type 1
                            if (question.question_type === 1) {
                                const updatedAnswers = question.answers.map(answer => ({
                                    ...answer,
                                    is_answer: answer.id === value
                                }));
                                return {
                                    ...question,
                                    answer: value,
                                    answers: updatedAnswers
                                };
                            }
                            // for question type 2
                            else if (question.question_type === 2) {
                                return {
                                    ...question,
                                    answer: value,
                                    is_answer: true
                                };
                            }
                            // for question type 3
                            else if (question.question_type === 3) {
                                return {
                                    ...question,
                                    answer: value,
                                    is_answer: true,
                                    error: ""
                                };
                            }
                        }
                        return question;
                    })
                };
            } else {
                // Return unchanged category for other orders
                return {
                    ...category,
                    questions: category.questions.map(question => {
                        if (question.question_id === questionId) {
                            return {
                                ...question,
                                answer: value,
                                answer_label: label,
                                is_answer: true
                            };
                        }
                        return question;
                    })
                };
            }
        });

        setQuestionData(updatedData);
    };


    const _processData = (data) => {
        // Find the category with order 1
        const category = data.find(item => item.order === 1);
        const cateogry_two = data.find(item => item.order === 2);
        if (category) {
            // Iterate through questions to find those with question_type 1
            category.questions.forEach(question => {

                // question type 1 is radio button
                if (question.question_type === 1) {
                    // Transform the answers array
                    question.answers = question.answers.map(answer => ({
                        id: answer.answer_id,
                        name: answer.answer,
                        score: answer.score,
                        is_answer: answer.is_answer
                    }));
                    if (question.is_answer === false) {
                        question.answer = question.answers[0].id;
                    }
                }
                else if (question.question_type === 2) {
                    if (question.is_answer === false) {
                        question.answer = question.config.min_max.default
                    }
                }

                // question type 3 is text field
                else {
                    question.error = "";
                }
            });
        }
        if (cateogry_two) {
            cateogry_two.questions.forEach(question => {

                // question type 4 
                if (question.question_type === 4) {
                    // Transform the answers array
                    if (question.is_answer === false) {
                        question.answer = question.config.min_max.default;
                        question.answer_label = question.config.investment_style[question.config.min_max.default];
                    }
                    else {
                        question.answer_label = question.config.investment_style[question.answer];
                    }
                }
            });
        }
        return data;
    };

    const _openModal = (step) => {
        _submitAnswers(step);
        setShowModal(true);
    }

    const _extractAnswers = (data) => {
        let extractedAnswers = [];
        data.forEach(category => {
            category.questions.forEach(question => {

                extractedAnswers.push({
                    question_id: question.question_id,
                    answer_id: (category.order === 2 &&
                        question.answer === null) ?
                        question.config.default_answer :
                        question.question_type === 1 || question.question_type === 4 ? question.answer : null,
                    answer: question.answer
                });

            });
        });

        return extractedAnswers;
    };

    // API - get questionnaire 
    const _getQuestions = () => {
        const url = "/quiz/list-questions";

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                // Transform the data and update the state
                const processed_questionnaire = _processData(response.data);
                setQuestionData(processed_questionnaire);
                setScreenLoader(false);
            } else {
                // navigation back to "/"
            }
        });
    }

    // API - submit answers
    const _submitAnswers = (step) => {
        const url = "/quiz/save-answer";
        const request = {
            answers: _extractAnswers(questionData)
        };

        setApiLoader(true);

        APIService(true, url, request)
            .then((response) => {
                if (response.status_code === 200) {
                    setChartData(response.data);
                    if (configurationStep === 1) {
                        navigate(location.pathname, {
                            state: {
                                step: step,
                                from_fund: fromFund,
                                id: fundId
                            }
                        });
                    }
                    else {
                        setForceStepThree(true);
                    }

                } else {
                    toast.dismiss();
                    toast.error(response.message, {
                        type: "error",
                    });

                    setTimeout(() => {
                        const closeModalButton = document.getElementById("close-modal");
                        if (closeModalButton) {
                            closeModalButton.dispatchEvent(new Event("click"));
                            setShowModal(false);
                        }
                        setForceStepThree(false);
                    }, 2000);


                }
            })
            .finally(() => {
                setApiLoader(false);
            });
    };

    return (
        <>
            {
                screenLoader === true ?
                    <Loader height="h-80vh" />
                    :
                    <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
                        <Header type={2} />
                        <div className="row ">
                            <div className="col-lg-11 padding-144px-bottom">

                                {/* Goal list */}
                                <section className="px-4 ">
                                    <Breadcrumb mainPage="Home"
                                        currentPage="Risk Profiling"
                                        type={1}
                                        handleNavigate={_handleNavigate} />

                                    <div className="row d-flex justify-content-center mt-3">
                                        <div className="col-xl-6 col-lg-8 col-md-8">
                                            {
                                                configurationStep !== 1 &&
                                                <Icon icon="Arrow-Right"
                                                    size={32}
                                                    color={Color.davy_gray}
                                                    className="e-transform-left-arrow cursor-pointer mb-4"
                                                    onClick={() => _handleConfigurationStep(configurationStep - 1)} />
                                            }

                                            <StepProgress stepCompleted={configurationStep}
                                                gotToStep={_handleConfigurationStep} />

                                            {
                                                configurationStep === 1 ?
                                                    <PersonalRiskView orderOne={questionData.find(item => item.order === 1)}
                                                        loader={apiLoader}
                                                        handleChange={_handleChange}
                                                        submit={_handleNavigateToStep} />
                                                    :
                                                    configurationStep === 2 ?
                                                        <PersonalRiskReview orderTwo={questionData.find(item => item.order === 2)}
                                                            handleChange={_handleChange}
                                                            submit={_openModal} />
                                                        :
                                                        <RiskScore chartData={chartData}
                                                            from_fund={fromFund}
                                                            mf_id={fundId} />
                                            }

                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
            }

            {
                showModal === true &&
                <RiskScoreModal closeModal={() => setShowModal(false)} />
            }
        </>
    )
}

export default RiskProfiling