/*
 *   File : select-box.js
 *   Author URI : www.evoqins.com
 *   Description : Select box will allow the customers to pick the desired option from a precreated list.
 *   Integrations : null
 *   Version : v1.0
 *   Created : 
 */

/* import package */
import React, { Fragment, useEffect, useState } from "react";
import Select, { components } from "react-select";

/* import components */
import Icon from "../Icon/icon";

import Colors from "../../Styles/color.module.scss";





// value, label

const CustomSelectBox = (props) => {

    const [value, setValue] = useState("");

    const DropdownIndicator = (dropdown) => {
        return (
            <components.DropdownIndicator {...dropdown}>
                <Icon icon={props.type === 2 ? "right-arrow" : "header-drop-down"}
                    size={props.type === 2 ? 14 : 16} />
            </components.DropdownIndicator>
        );
    };

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const customStyles = {
        container: (defaultStyles, state) => ({
            ...defaultStyles,
        }),
        placeholder: (defaultStyles) => ({
            ...defaultStyles,
            margin: "0",
            fontFamily: "Montserrat-Medium",
            fontSize: "14px",
            lineHeight: "20px",
        }),
        control: (defaultStyles, state) => ({
            ...defaultStyles,
            minWidth: "86px",
            borderRadius: "12px",
            border: "none",
            backgroundColor: "white",
            overflow: "hidden",
            boxShadow: "0px 0px 2px 0px #00000033, 0px 0px 2px 0px #00000036",
            padding: "10px",
            "&:hover": {
                border: "none",
                cursor: 'pointer',
            },
        }),
        valueContainer: (defaultStyles) => ({
            ...defaultStyles,
            padding: "0px",
        }),
        menu: (defaultStyles) => ({
            ...defaultStyles,
            // width: "247px",
            right: "0",
            border: `1px solid ${Colors.athens_gray}`,
            background: Colors.white,
            boxShadow: "5px 3px 10px 0px #0000001A",
            borderRadius: "16px",
            zIndex: 10,
            width: props.width ? "200px" : "100%"
        }),


        menuList: (defaultStyles, state) => ({
            ...defaultStyles,
            padding: 0,
            '& > div:not(:last-child)': {
                borderBottom: `1px solid ${Colors.platinum}`,
            },
            borderRadius: "16px",
            display: "flex",
            flexDirection: "column",
            maxHeight: "15.625rem",
            "&::-webkit-scrollbar": {
                width: "0.125rem"
            },

            "&::-webkit-scrollbar-thumb": {
                backgroundColor: Colors.rebecca_purple,
                borderRadius: "0.25rem"
            },
            '&::-webkit-scrollbar-track': {
                marginTop: "0.313rem",
                marginBottom: "0.313rem"
            },
        }),

        dropdownIndicator: (defaultStyles, state) => ({
            ...defaultStyles,
            padding: "0",
            marginLeft: "4px",
            transition: "transform 0.2s",
            transform: state.selectProps.menuIsOpen === true && "rotate(-180deg)"
        }),

        option: (defaultStyles, state) => ({
            ...defaultStyles,
            padding: '12px 16px',
            fontFamily: "Montserrat-Medium",
            fontSize: "14px",
            fontStyle: " normal",
            fontWeight: "500",
            lineHeight: "24px",
            backgroundColor: Colors.white,
            // borderRadius: "12px",
            cursor: "pointer",
            "&:hover": {
                backgroundColor: Colors.light_lavender,
                color: Colors.rebecca_purple
            },
        }),
    };


    const CUSTOM_STYLES_DROPDOWN = {
        dropdownIndicator: (defaultStyles, state) => ({
            ...defaultStyles,
            padding: "0",
            marginLeft: "4px",
            transition: "transform 0.2s",
            transform: state.selectProps.menuIsOpen === true ? "rotate(270deg)" : "rotate(90deg)"
        }),
        control: (styles, state) => ({
            ...styles,
            borderColor: 'transparent',
            backgroundColor: Colors.lavender_gray,
            boxShadow: '0px 0px 2px 0px #00000033',
            borderRadius: "1.125rem",
            padding: "0px 6px",
            cursor: "pointer",
            alignItems: "center",
            minHeight: "32px",
            width: "max-content",
            "&:hover": {
                boxShadow: '0px 0px 2px 0px #00000033',
            }
        }),
        valueContainer: (styles, state) => ({
            ...styles,
            padding: "0px 8px 0px 0px",
            width: "max-content",
        }),
        indicatorSeparator: (styles, state) => ({
            ...styles,
            display: "none",
        }),
        singleValue: (styles, state) => ({
            ...styles,
            fontFamily: "Montserrat-SemiBold",
            fontSize: "10px",
            lineHeight: "14px",
            letterSpacing: "0.007em",
            margin: "0px",
            color: Colors.rebecca_purple
        }),

        menu: (styles, state) => ({
            ...styles,
            width: "100px",
            borderRadius: "8px",
            overflow: "hidden",
            zIndex: 10,
            right: 0
        }),
        option: (styles, state) => ({
            ...styles,
            fontFamily: "Montserrat-Medium",
            fontSize: "10px",
            lineHeight: "14px",
            letterSpacing: "0.007em",
            color: state.isSelected ? Colors.white : Colors.dark_charcoal,
            backgroundColor: state.isSelected ? Colors.rebecca_purple : "",
            cursor: "pointer",
            "&:active": {
                backgroundColor: Colors.white,
            },
            "&:hover": {
                backgroundColor: state.isSelected ? Colors.rebecca_purple : Colors.lavender_gray,
                color: state.isSelected ? Colors.white : Colors.dark_charcoal,
            },
        })

    }

    const _onSelectChange = (value) => {
        props.onSelectChange(value)
    }

    // Custom Option component with the icon
    const CustomOption = ({ children, isSelected, ...props }) => (
        <components.Option {...props}>
            <div className="d-flex align-items-center gap-10px">
                {
                    isSelected ?
                        <span>
                            <Icon icon="radio-checked"
                                size={18} />
                        </span>
                        :
                        <span>
                            <Icon icon="radio-unchecked"
                                size={18}
                                color={Colors.rebecca_purple} />
                        </span>

                }
                <span className={`${isSelected} ? " color-rebecca-purple" : "color-eerie-black"`}>{children}</span>
            </div>
        </components.Option>
    );

    const CustomSingleValue = ({ children, ...single }) => (
        <components.SingleValue {...single}>
            <div className="d-flex align-items-center gap-8px">
                {props.width !== true && <span className="color-granite-gray e-montserrat-medium e-font-14 e-line-height-20">
                    {single.selectProps.placeholder}
                </span>}
                <span className="e-montserrat-medium color-rebecca-purple e-font-14 e-line-height-20">{children}</span>
            </div>
        </components.SingleValue>
    );

    return (
        <Fragment>
            <div className={`${props.className}  position-relative`}>
                {
                    props.label &&
                    <p className={`${props.label_class} e-montserrat-medium e-font-14 e-line-height-22 margin-6px-bottom`}>{props.label}
                        <span className="color-deep-carmine-pink">{props.postFix === true && "*"}</span>
                    </p>
                }
                {props.type === 2 ?
                    <Select isSearchable={false}
                        isClearable={props.isClearable}
                        className={`e-select-box`}
                        isDisabled={props.isDisabled}
                        options={props.options}
                        value={props.value ? props.value : ""}
                        components={{
                            DropdownIndicator,
                            IndicatorSeparator: null,
                        }}
                        // menuIsOpen={true}
                        styles={CUSTOM_STYLES_DROPDOWN}
                        onChange={(e) => { _onSelectChange(e) }}
                    />
                    :
                    <Select value={value}
                        styles={customStyles}
                        isSearchable={false}
                        isDisabled={props.isDisabled}
                        options={props.options}
                        placeholder={props.placeholder}
                        // menuIsOpen={true}
                        components={{
                            DropdownIndicator,
                            IndicatorSeparator: null,
                            Option: CustomOption,
                            SingleValue: CustomSingleValue,
                        }}
                        onChange={(value) => {
                            _onSelectChange(value)
                        }}
                    />
                }
                {
                    props.error &&
                    <div className='position-absolute d-flex align-items-center margin-6px-top w-max-content'>
                        {/* <Icon icon="info-circle"
                        size={16}
                        className="me-1" /> */}
                        <p className='color-deep-carmine-pink  e-montserrat-medium e-font-12  e-line-height-16 mb-0 '>
                            {props.error}
                        </p>
                    </div>
                }
            </div>

        </Fragment>
    );
}

export default CustomSelectBox;