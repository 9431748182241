/*
*   File : nri.js
*   Author : https://evoqins.com
*   Description : Screen to show goal Investment for NRI
*   Version : 1.0.0
*/

// import packages
import { Fragment, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom/dist";
import { Modal } from "bootstrap";
import { toast } from "react-toastify";

// import components
import { Header } from "../../Components/Header";
import { Breadcrumb, Loader, StepProgress } from "../../Components/Other";
import { CustomAmountInput, EditableSlider, RadioGroup } from "../../Components/FormElements";
import { PrimaryButton } from "../../Components/Buttons";
import { GoalInformation, GoalInvest } from "./";
import { RiskKycConfirmationModal } from "../../Components/Modal";

// Services, helper
import { _getGoal } from "../../Helper/api";
import APIService from "../../Services/api-service";

const RADIO_OPTION = [
    {
        name: "Yes",
        id: 1
    },
    {
        name: "No",
        id: 2
    }
];

const NRI = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const moneyRequiredRef = useRef(null);
    const existingAmountRef = useRef(null);

    const [goalDetail, setGoalDetail] = useState({});

    const [age, setAge] = useState(10);

    const [cost, setCost] = useState("");
    const [costError, setCostError] = useState("");

    const [inflation, setInflation] = useState(6);
    const [moneySaved, setMoneySaved] = useState(RADIO_OPTION[1].id)

    const [amount, setAmount] = useState("");
    const [amountError, setAmountError] = useState("");

    const [configurationStep, setConfigurationStep] = useState(1);
    const [screenLoader, setScreenLoader] = useState(true);
    const [apiLoader, setApiLoader] = useState(false);
    const [investedReturn, setInvestedReturn] = useState(10);
    const [locationData, setLocationData] = useState(null);
    const [riskKycCheck, setRiskKycCheck] = useState(false);

    useEffect(() => {
        if (location.state !== null) {
            _goalDetail(location.state.goal_id);
            setConfigurationStep(location.state.step);
            if (location.state.step === 3) {
                setLocationData(location.state);
            }
        }
    }, [location]);

    useEffect(() => {
        if (riskKycCheck === true) {
            const modal = new Modal(document.getElementById("risk-kyc-check"));
            modal.show();
        }
    }, [riskKycCheck]);

    // API - get goal detail
    const _goalDetail = (id) => {
        _getGoal(id)
            .then((response) => {
                if (response.is_configured === false) {
                    setAge(response.admin_configured.meta_data.sip_tenure.default);
                    setInflation(response.admin_configured.meta_data.inflation_rate.default);
                    setInvestedReturn(response.admin_configured.meta_data.pre_expected_return.default);
                    setCost(response.admin_configured.meta_data.default_target);
                    setLocationData(null);
                } else {
                    setAge(response.user_configured.tenure);
                    setInflation(response.user_configured.inflation_rate);
                    if (response.user_configured.additional_data !== null) {
                        if (response.user_configured.additional_data.existing_investment > 0) {
                            setMoneySaved(1);
                            setAmount(response.user_configured.additional_data.existing_investment)
                        }
                        if (response.user_configured.additional_data.target_value) {
                            setCost(response.user_configured.additional_data.target_value);
                        }
                        if (response.user_configured?.additional_data?.pre_expected_return) {
                            setInvestedReturn(response.user_configured.additional_data.pre_expected_return);
                        }
                    }
                }
                setGoalDetail(response);
                setScreenLoader(false);
            }).catch((error) => {
                toast.dismiss();
                toast.error(error, {
                    type: "error",
                });
            });
    }


    // navigate to home screen
    function _handleParentNavigate() {
        navigate("/");
    }

    // navigate to parent screen
    function _handleCurrentNavigate() {
        navigate("/milestone");
    }

    // handle age for dream home
    function _handleAge(slider_value) {
        setAge(slider_value);
    }

    // handle cost
    function _handleCost(slider_value) {
        setCost(slider_value);
        setCostError("");
    }

    // handle inflation
    function _handleInflation(slider_value) {
        setInflation(slider_value);
    }

    // handle current investment return
    function _handleReturnInvestment(slider_value) {
        setInvestedReturn(slider_value);
    }

    // handle amount
    function _handleAmount(input_value) {
        setAmount(input_value);
        setAmountError("");
    }

    function _confirmRiskKyc(status) {
        setRiskKycCheck(status)
    }

    function _handleNavigateToScreen() {
        navigate("/risk-profile")
    }

    // handle submit
    function _submitInvestment() {
        let valid = true;
        let firstErrorRef = null;
        toast.dismiss();
        if (cost === "") {
            setCostError("Enter the amount");
            if (!firstErrorRef) {
                firstErrorRef = moneyRequiredRef;
            }
            valid = false;
        }
        if (age < goalDetail.admin_configured.meta_data.sip_tenure.min) {
            toast.error(`Tenure can't be less than ${goalDetail.admin_configured.meta_data.sip_tenure.min} year`, {
                type: "error",
            });
            valid = false;
        }
        if (moneySaved === 1) {
            if (amount === "" || amount === 0) {
                setAmountError("Enter the invested amount");
                if (!firstErrorRef) {
                    firstErrorRef = existingAmountRef;
                }
                valid = false;
            }
        }

        if (firstErrorRef) {
            firstErrorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            firstErrorRef.current.querySelector('input').focus();
        }

        if (valid === true) {
            setApiLoader(true);
            _setNRIGoal();
        }
    }

    // handle radio group
    function _handleRadioGroup(radio) {
        setMoneySaved(radio);
    }

    const _setNRIGoal = () => {
        const url = "/goal/set";
        const request = {
            goal_id: location.state.goal_id,
            tenure: age,
            target_value: cost,
            inflation: inflation,
            additional_data: {
                existing_investment_id: moneySaved,
                existing_investment: moneySaved === 1 ? amount : 0,
                pre_expected_return: moneySaved === 1 ? investedReturn : 0,
                target_value: cost,

            }
        };

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setConfigurationStep(2);
                navigate(location.pathname, {
                    state: {
                        goal_id: location.state.goal_id,
                        step: 2,
                        request_data: response.data
                    }
                });

            } else {
                if (response.data && response.data.risk_not_done === true) {
                    _confirmRiskKyc(true);
                } else {
                    toast.dismiss();
                    toast.error(response.message, {
                        type: "error",
                    });
                }
            }

            setApiLoader(false);
        })
    }

    // handle to go back previous step
    function _handleConfigurationStep(step) {
        navigate(location.pathname, {
            state: {
                goal_id: location.state.goal_id,
                step: step,
                request_data: location.state.request_data
            }
        })
    }

    return (

        screenLoader === true ?
            <Loader height="h-80vh" />
            :
            <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
                <div className="row ">
                    <div className="col-12 padding-144px-bottom">
                        <Header type={2} />
                        <div className="px-4 ">
                            <Breadcrumb mainPage="Home"
                                currentPage="Milestones"
                                childPage={goalDetail.admin_configured.name}
                                handleParentNavigate={_handleParentNavigate}
                                handleCurrentNavigate={_handleCurrentNavigate}
                                type={2} />


                            <div className="row pt-4 d-flex justify-content-center">
                                <div className="col-lg-6">
                                    {/* Step progress */}
                                    <StepProgress stepCompleted={configurationStep}
                                        gotToStep={_handleConfigurationStep} />

                                </div>

                            </div>
                            {
                                configurationStep === 1 ?
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-lg-6">
                                            <div className="mt-4 e-goal-bg border-radius-16px d-flex align-items-center p-3">
                                                <img src={goalDetail.admin_configured.image}
                                                    alt="goals"
                                                    width={65}
                                                    height={54} />
                                                <div className="padding-12px-left">
                                                    <h6 className="mb-1 color-eerie-black e-font-16 e-line-height-22 e-montserrat-semi-bold">{goalDetail.admin_configured.name}</h6>
                                                    <p className="mb-0 color-dim-gray e-font-14 e-line-height-20 e-montserrat-medium">{goalDetail.admin_configured.description}</p>
                                                </div>
                                            </div>

                                            <EditableSlider label="When are you planning to settle back in India?"
                                                className="mt-4"
                                                value={age}
                                                edit={true}
                                                right={true}
                                                handleChange={_handleAge}
                                                min={goalDetail.admin_configured.meta_data.sip_tenure.min}
                                                max={goalDetail.admin_configured.meta_data.sip_tenure.max}
                                                step={goalDetail.admin_configured.meta_data.sip_tenure.step}
                                                suffix={age === 1 ? "Yr" : "Yrs"}
                                                singularSuffix={goalDetail.admin_configured.meta_data.sip_tenure.min === 1 ? "Yr" : "Yrs"}
                                                pluralSuffix="Yrs" />
                                            <div ref={moneyRequiredRef}>
                                                <CustomAmountInput
                                                    value={cost}
                                                    error={costError}
                                                    postfix="*"
                                                    className="mt-4"
                                                    label="How much money do you think you'll need?"
                                                    placeholder="10,00,000"
                                                    handleChange={_handleCost} />
                                            </div>

                                            <EditableSlider label="What is the expected inflation rate to be considered?"
                                                className="mt-4"
                                                value={inflation}
                                                handleChange={_handleInflation}
                                                min={goalDetail.admin_configured.meta_data.inflation_rate.min}
                                                max={goalDetail.admin_configured.meta_data.inflation_rate.max}
                                                step={goalDetail.admin_configured.meta_data.inflation_rate.step}
                                                suffix="%"
                                                singularSuffix="%"
                                                pluralSuffix="%" />
                                            <div className="mt-4">
                                                <p className="mb-md-3 mb-0 color-dark-charcoal e-font-14 e-line-height-24 e-montserrat-medium">Have you set aside any money?</p>
                                                <RadioGroup data={RADIO_OPTION}
                                                    selected={moneySaved}
                                                    handleSelect={_handleRadioGroup} />
                                            </div>
                                            {
                                                moneySaved === 1 &&
                                                <Fragment>
                                                    <div className="mt-md-4 mt3 pb-1" ref={existingAmountRef}>
                                                        <CustomAmountInput
                                                            value={amount}
                                                            error={amountError}
                                                            postfix="*"
                                                            label="How much you have currently saved for this purpose?"
                                                            placeholder="10,00,000"
                                                            handleChange={_handleAmount} />
                                                    </div>
                                                    <EditableSlider label="What returns are you currently getting on this investment?"
                                                        className="mt-4"
                                                        value={investedReturn}
                                                        handleChange={_handleReturnInvestment}
                                                        min={goalDetail.admin_configured.meta_data.pre_expected_return.min}
                                                        max={goalDetail.admin_configured.meta_data.pre_expected_return.max}
                                                        step={goalDetail.admin_configured.meta_data.pre_expected_return.step}
                                                        suffix="%"
                                                        singularSuffix="%"
                                                        pluralSuffix="%" />
                                                </Fragment>
                                            }

                                            <div className="mt-5 border-top-1px border-bright-gray mb-4"></div>
                                            <div className="d-flex justify-content-center">
                                                <div className="col-lg-6 col-md-4 col-6">
                                                    <PrimaryButton label="Calculate" className="padding-10px-tb w-100"
                                                        showLoader={apiLoader}
                                                        disabled={apiLoader}
                                                        onPress={_submitInvestment}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    configurationStep === 2 ?
                                        <div className="row d-flex justify-content-center">
                                            <div className="col-lg-6">
                                                <GoalInformation short_description={"Recommended Corpus:"}
                                                    text1="When"
                                                    locationData={locationData}
                                                    text2="you'll need a corpus of"
                                                />
                                            </div>
                                        </div>
                                        :
                                        <div className="row ">
                                            <div className="col-xl-10 col-lg-11">
                                                <GoalInvest />
                                            </div>
                                        </div>
                            }
                        </div>

                    </div>
                </div>
                {
                    riskKycCheck === true && <RiskKycConfirmationModal
                        title="Complete Risk Profile"
                        content="Please complete your Risk Profile to start with an Investment."
                        closeModal={() => _confirmRiskKyc(false)}
                        handleConfirm={_handleNavigateToScreen} />
                }
            </div>

    )
}

export default NRI;