/*
*   File : surplus-fund.js
*   Author : https://evoqins.com
*   Description : Screen to show goal Investment for SurplusFund
*   Version : 1.0.0
*/

// import packages
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom/dist";
import { Modal } from "bootstrap";
import { toast } from "react-toastify";

// import components
import { Header } from "../../Components/Header";
import { Breadcrumb, Loader, StepProgress } from "../../Components/Other";
import { CustomAmountInput, EditableSlider } from "../../Components/FormElements";
import { PrimaryButton } from "../../Components/Buttons";
import { GoalInformation, GoalInvest } from "./";
import { RiskKycConfirmationModal } from "../../Components/Modal";

// Service,helper
import { _getGoal } from "../../Helper/api";
import APIService from "../../Services/api-service";
import { Icon } from "../../Components/Icon";

// const RADIO_OPTION = [
//     {
//         name: "Yes",
//         id: 1
//     },
//     {
//         name: "No",
//         id: 2
//     }
// ];

const SurplusFund = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const targetAmountRef = useRef(null);
    const savedAmountRef = useRef(null);

    const [goalDetail, setGoalDetail] = useState({});

    const [tenure, setTenure] = useState(12);

    const [targetAmount, setTargetAmount] = useState("");
    const [targetAmountError, setTargetAmountError] = useState("");

    // const [expectedReturns, setExpectedReturns] = useState(0);

    // const [isEmergency, setIsEmergency] = useState(RADIO_OPTION[0].id)
    // const [isHigherFund, setIsHigherFund] = useState(RADIO_OPTION[1].id);
    // const [isMoneySaved, setIsMoneySaved] = useState(RADIO_OPTION[1].id)

    const [savedAmount, setSavedAmount] = useState("");
    const [savedAmountError, setSavedAmountError] = useState("");

    const [configurationStep, setConfigurationStep] = useState(1);
    const [screenLoader, setScreenLoader] = useState(true);
    const [apiLoader, setApiLoader] = useState(false);

    const [locationData, setLocationData] = useState(null);
    const [riskKycCheck, setRiskKycCheck] = useState(false);

    // const [investedReturn, setInvestedReturn] = useState(0);

    useEffect(() => {
        if (location.state !== null) {
            _goalDetail(location.state.goal_id);
            setConfigurationStep(location.state.step);
            if (location.state.step === 3) {
                setLocationData(location.state);
            }
        }
    }, [location]);

    useEffect(() => {
        if (riskKycCheck === true) {
            const modal = new Modal(document.getElementById("risk-kyc-check"));
            modal.show();
        }
    }, [riskKycCheck]);

    // API - get goal detail
    const _goalDetail = (id) => {
        _getGoal(id)
            .then((response) => {
                if (response.is_configured === false) {
                    setTenure(response.admin_configured.meta_data.months_of_expenses.default);
                    setTargetAmount(response.admin_configured.meta_data.monthly_expenses.default);
                    // setIsEmergency(response.admin_configured.is_emergency === true ? 1 : 2);
                    setLocationData(null);
                    // setIsHigherFund(response.admin_configured.not_for_high_returns === true ? 1 : 2);
                    // setExpectedReturns(response.admin_configured.meta_data.returns.default);
                    setSavedAmount(response.admin_configured.meta_data.saved_amount.default);
                } else {
                    if (response.user_configured.additional_data.monthly_expenses) {
                        setTenure(response.user_configured.additional_data.months_of_expenses);
                        setTargetAmount(response.user_configured.additional_data.monthly_expenses);
                    }
                    // setIsEmergency(response.user_configured.additional_data.is_emergency === true ? 1 : 2);
                    // setIsHigherFund(response.user_configured.additional_data.not_for_high_returns === true ? 1 : 2);
                    // setExpectedReturns(response.user_configured.additional_data.return_value);
                    // setIsMoneySaved(response.user_configured.additional_data.is_saved === true ? 1 : 2);
                    if (response.user_configured.additional_data.saved_amount) {
                        setSavedAmount(response.user_configured.additional_data.saved_amount);
                    }
                }
                // if (response.user_configured?.additional_data?.pre_expected_return) {
                //     setInvestedReturn(response.user_configured.additional_data.pre_expected_return);
                // }
                // else {
                //     setInvestedReturn(response.admin_configured.meta_data.pre_expected_return.default)
                // }
                setGoalDetail(response);
                setScreenLoader(false);
            }).catch((error) => {
                toast.dismiss();
                toast.error(error, {
                    type: "error",
                });
            });
    }


    // navigate to home screen
    function _handleParentNavigate() {
        navigate("/");
    }

    // navigate to parent screen
    function _handleCurrentNavigate() {
        navigate("/milestone");
    }

    // handle tenure for dream home
    function _handleTenure(slider_value) {
        setTenure(slider_value);
    }

    // handle targetAmount
    function _handleTargetAmount(slider_value) {
        setTargetAmount(slider_value);
        setTargetAmountError("");
    }

    // // handle expectedReturns
    // function _handleExpectedReturn(slider_value) {
    //     setExpectedReturns(slider_value);
    // }

    // // handle savedAmount
    function _handleSavedAmount(input_value) {
        setSavedAmount(input_value);
        setSavedAmountError("");
    }

    // // handle current investment return
    // function _handleReturnInvestment(slider_value) {
    //     setInvestedReturn(slider_value);
    // }

    // handle submit
    function _submitInvestment() {
        let valid = true;
        let firstErrorRef = null;
        toast.dismiss();
        if (targetAmount === "" || targetAmount === 0) {
            setTargetAmountError("Enter the required amount");
            if (!firstErrorRef) {
                firstErrorRef = targetAmountRef;
            }
            valid = false;
        }

        if (tenure < goalDetail.admin_configured.meta_data.months_of_expenses.min) {
            toast.dismiss();
            toast.error("Months of expenses should be greater than minimum year", {
                type: "error"
            })
            valid = false;
        }

        if (savedAmount === "" || savedAmount === 0) {
            setSavedAmountError("Enter the required amount");
            if (!firstErrorRef) {
                firstErrorRef = savedAmountRef;
            }
            valid = false;
        }

        // if (isEmergency === 1) {
        //     if (isHigherFund === 1) {
        //         if (isMoneySaved === 1) {
        //             if (savedAmount === "" || savedAmount === 0) {
        //                 setSavedAmountError("Amount is required");
        //                 if (!firstErrorRef) {
        //                     firstErrorRef = savedAmountRef;
        //                 }
        //                 valid = false;
        //             }
        //         }
        //     }
        // }

        if (firstErrorRef) {
            firstErrorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            firstErrorRef.current.querySelector('input').focus();
        }

        if (valid === true) {
            setApiLoader(true);
            _setEmergencyGoal();
        }
    }


    function _confirmRiskKyc(status) {
        setRiskKycCheck(status)
    }

    function _handleNavigateToScreen() {
        navigate("/risk-profile")
    }

    // handle radio group
    // function _handleIsEmergency(radio) {
    //     setIsEmergency(radio);
    // }

    // function _handleIsHighFund(value) {
    //     setIsHigherFund(value);
    // }

    // function _handleSavedMoney(value) {
    //     setIsMoneySaved(value);
    // }

    const _setEmergencyGoal = () => {
        const url = "/goal/set";
        const request = {
            goal_id: location.state.goal_id,
            tenure: Number(tenure),
            inflation: null,
            target_value: null,
            additional_data: {
                // is_emergency: (isEmergency === 1) ? true : false,
                // not_for_high_returns: isHigherFund === 1 ? true : false,
                months_of_expenses: Number(tenure),
                monthly_expenses: targetAmount,
                saved_amount: savedAmount,
                // is_saved: (isMoneySaved === 1) ? true : false,
                // return_value: 0,
                // pre_expected_return: investedReturn,
                // target_value: targetAmount,
            }
        };


        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setConfigurationStep(2);
                navigate(location.pathname, {
                    state: {
                        goal_id: location.state.goal_id,
                        step: 2,
                        request_data: response.data
                    }
                });

            } else {
                if (response.data && response.data.risk_not_done === true) {
                    _confirmRiskKyc(true);
                } else {
                    toast.dismiss();
                    toast.error(response.message, {
                        type: "error",
                    });
                }
            }

            setApiLoader(false);
        })
    }

    // handle to go back previous step
    function _handleConfigurationStep(step) {
        navigate(location.pathname, {
            state: {
                goal_id: location.state.goal_id,
                step: step,
                request_data: location.state.request_data
            }
        })
    }

    return (

        screenLoader === true ?
            <Loader height="h-80vh" />
            :
            <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
                <div className="row ">
                    <div className="col-12 padding-144px-bottom">
                        <Header type={2} />
                        <div className="px-4 ">
                            <Breadcrumb mainPage="Home"
                                currentPage="Milestones"
                                childPage={goalDetail.admin_configured.name}
                                handleParentNavigate={_handleParentNavigate}
                                handleCurrentNavigate={_handleCurrentNavigate}
                                type={2} />


                            <div className="row pt-4 d-flex justify-content-center">
                                <div className="col-lg-6">
                                    {/* Step progress */}
                                    <StepProgress stepCompleted={configurationStep}
                                        gotToStep={_handleConfigurationStep} />

                                </div>

                            </div>
                            {
                                configurationStep === 1 ?
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-lg-6">
                                            <div className="mt-4 e-goal-bg border-radius-16px d-flex align-items-center p-3">
                                                <img src={goalDetail.admin_configured.image}
                                                    alt="goals"
                                                    width={65}
                                                    height={54} />
                                                <div className="padding-12px-left">
                                                    <h6 className="mb-1 color-eerie-black e-font-16 e-line-height-22 e-montserrat-semi-bold">{goalDetail.admin_configured.name}</h6>
                                                    <p className="mb-0 color-dim-gray e-font-14 e-line-height-20 e-montserrat-medium">{goalDetail.admin_configured.description}</p>
                                                </div>
                                            </div>

                                            <div ref={targetAmountRef}>
                                                <CustomAmountInput
                                                    value={targetAmount}
                                                    error={targetAmountError}
                                                    postfix="*"
                                                    className="mt-4"
                                                    label="What are your average monthly living expenses (including rent, utilities, groceries, and transportation)?"
                                                    placeholder="10,00,000"
                                                    handleChange={_handleTargetAmount} />
                                            </div>

                                            <EditableSlider label="How many months of expenses would you feel comfortable having saved for emergencies?"
                                                className="mt-4"
                                                value={tenure}
                                                edit={false}
                                                handleChange={_handleTenure}
                                                min={goalDetail.admin_configured.meta_data.months_of_expenses.min}
                                                max={goalDetail.admin_configured.meta_data.months_of_expenses.max}
                                                step={1}
                                                suffix={""}
                                                singularSuffix={goalDetail.admin_configured.meta_data.months_of_expenses.min === 1 ? "Month" : "Months"}
                                                pluralSuffix="Months" />
                                            <div className="mt-4 pb-1" ref={savedAmountRef}>
                                                <CustomAmountInput
                                                    value={savedAmount}
                                                    error={savedAmountError}
                                                    is_zero={true}
                                                    postfix="*"
                                                    label=" Do you have any existing savings or investments that could be used in case of an emergency?"
                                                    placeholder="10,00,000"
                                                    handleChange={_handleSavedAmount} />
                                            </div>

                                            {/* <p className={`e-montserrat-medium color-dark-charcoal e-font-14 e-line-height-18 cursor-text mb-2 bg-transparent mt-4`}>
                                                Do you have any existing savings or investments that could be used in case of an emergency?
                                                <span className='color-deep-carmine-pink'>*</span>
                                            </p>
                                            <RadioGroup data={RADIO_OPTION}
                                                selected={isEmergency}
                                                handleSelect={_handleIsEmergency} /> */}
                                            {/* {
                                                isEmergency === 1 &&
                                                <Fragment>
                                                    <p className={`e-montserrat-medium color-dark-charcoal e-font-14 e-line-height-18 cursor-text mb-2 bg-transparent mt-4`}>
                                                        So, do you understand these funds aren't meant for high returns?
                                                        <span className='color-deep-carmine-pink'>*</span>
                                                    </p>
                                                    <RadioGroup data={RADIO_OPTION}
                                                        selected={isHigherFund}
                                                        handleSelect={_handleIsHighFund} />

                                                    {
                                                        isHigherFund === 2 ?
                                                            <EditableSlider label="So, what returns do you expect?"
                                                                className="mt-4"
                                                                value={expectedReturns}
                                                                handleChange={_handleExpectedReturn}
                                                                min={goalDetail.admin_configured.meta_data.returns.min}
                                                                max={goalDetail.admin_configured.meta_data.returns.max}
                                                                step={goalDetail.admin_configured.meta_data.returns.step}
                                                                suffix="%"
                                                                singularSuffix="%"
                                                                pluralSuffix="%" />
                                                            :
                                                            <Fragment>
                                                                <p className={`e-montserrat-medium color-dark-charcoal e-font-14 e-line-height-18 cursor-text mb-2 bg-transparent mt-4`}>
                                                                    Have you saved any money for this?
                                                                    <span className='color-deep-carmine-pink'>*</span>
                                                                </p>
                                                                <RadioGroup data={RADIO_OPTION}
                                                                    selected={isMoneySaved}
                                                                    handleSelect={_handleSavedMoney} />
                                                                {
                                                                    isMoneySaved === 1 &&
                                                                    <Fragment>
                                                                        <div className="mt-4 pb-1" ref={savedAmountRef}>
                                                                            <CustomAmountInput
                                                                                value={savedAmount}
                                                                                error={savedAmountError}
                                                                                postfix="*"
                                                                                label="How much you have saved?"
                                                                                placeholder="10,00,000"
                                                                                handleChange={_handleSavedAmount} />
                                                                        </div>
                                                                        <EditableSlider label="What returns are you currently getting on this investment?"
                                                                            className="mt-4"
                                                                            value={investedReturn}
                                                                            handleChange={_handleReturnInvestment}
                                                                            min={goalDetail.admin_configured.meta_data.pre_expected_return.min}
                                                                            max={goalDetail.admin_configured.meta_data.pre_expected_return.max}
                                                                            step={goalDetail.admin_configured.meta_data.pre_expected_return.step}
                                                                            suffix="%"
                                                                            singularSuffix="%"
                                                                            pluralSuffix="%" />
                                                                    </Fragment>
                                                                }
                                                            </Fragment>
                                                    }
                                                </Fragment>
                                            } */}

                                            <div className="d-flex align-items-center padding-12px-all mt-4 e-bg-ivory-bridge border-radius-12px  color-dark-golden e-font-12 e-line-height-20 gap-8px e-montserrat-medium">
                                                <Icon icon="info-circle-yellow"
                                                    size={16}
                                                    color="#C75802" />
                                                Ideal cushion should be of 12 months of expense
                                            </div>
                                            <div className="mt-5 border-top-1px border-bright-gray mb-4"></div>


                                            <div className="d-flex justify-content-center">
                                                <div className="col-lg-6 col-md-4 col-6">
                                                    <PrimaryButton label="Calculate" className="padding-10px-tb w-100"
                                                        showLoader={apiLoader}
                                                        disabled={apiLoader}
                                                        onPress={_submitInvestment}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    configurationStep === 2 ?
                                        <div className="row d-flex justify-content-center">
                                            <div className="col-lg-6">
                                                <GoalInformation short_description={"Recommended Corpus:"}
                                                    locationData={locationData}
                                                    text1="To make your"
                                                    text2="a reality, you'll need a corpus of" />
                                            </div>
                                        </div>
                                        :
                                        <div className="row ">
                                            <div className="col-xl-10 col-lg-11">
                                                <GoalInvest />
                                            </div>
                                        </div>
                            }
                        </div>

                    </div>
                </div>
                {
                    riskKycCheck === true && <RiskKycConfirmationModal
                        title="Complete Risk Profile"
                        content="Please complete your Risk Profile to start with an Investment."
                        closeModal={() => _confirmRiskKyc(false)}
                        handleConfirm={_handleNavigateToScreen} />
                }
            </div>
    )
}

export default SurplusFund;