/*
 *   File : file-input.js
 *   Author : https://evoqins.com
 *   Description : Input component
 *   Integrations : null
 *   Version : 1
*/

import React, { Fragment } from "react"
import { Icon } from "../Icon"

import style from '../../Styles/Components/text-box.module.scss';
import Color from '../../Styles/color.module.scss';
import { toast } from "react-toastify";


const CustomFileInput = React.memo((props) => {

    function _handleFile(e) {
        const file = e.target.files[0]
        const file_type = file.type.split("/")[1]
        if (props.accept.includes(file_type)) {
            props.onFileChange(e.target.files[0]);
            e.target.value = '';
        } else {
            toast.dismiss();
            toast.error(`Please upload (${props.type}) file type`, {
                type: "error"
            })
        }
    }

    function _clear() {
        props.clear("")
    }
    return (
        <Fragment>
            <div className={`${props.className} position-relative w-100`}>
                <p className={`e-montserrat-medium color-dark-charcoal e-font-14 e-line-height-18 cursor-text margin-6px-bottom bg-transparent`}>
                    {props.label}
                    <span className='color-deep-carmine-pink'>
                        {props.postfix}
                    </span>
                </p>
                <div className="position-relative">
                    <label htmlFor={props.id} className="w-100">
                        <input type="file"
                            id={props.id}
                            className="d-none"
                            accept={props.accept}
                            onChange={_handleFile} />
                        <div className={`${style.e_file_input} e-bg-light-lavender w-100 d-flex gap-8px justify-content-center align-items-center padding-14px-tb cursor-pointer color-graphite e-font-12 e-line-height-14 e-montserrat-regular `} >

                            {
                                props.loader ?
                                    <img src={require("../../Assets/Images/Loader/page-loader.gif")}
                                        alt={"loader"}
                                        height={20}
                                    />
                                    :
                                    <Fragment>
                                        {
                                            props.file ?
                                                <span className="padding-2px-top pb-1 w-max-content text-truncate px-5">
                                                    {props.file}
                                                </span>
                                                :
                                                <Fragment>
                                                    <Icon icon="upload"
                                                        size={20} />
                                                    Click here to upload ({props.type})
                                                </Fragment>
                                        }
                                    </Fragment>
                            }
                        </div>
                    </label>
                    {
                        props.file &&
                        <Icon icon="close-btn"
                            size={20}
                            color={Color.eerie_black}
                            className="position-absolute top-12px right-12px cursor-pointer"
                            onClick={_clear} />
                    }
                </div>
                {/* Error text */}
                {
                    props.error &&
                    <div className='position-absolute d-flex align-items-center margin-6px-top w-max-content'>

                        <p className='color-deep-carmine-pink  e-montserrat-medium e-font-12  e-line-height-16 mb-0 '>
                            {props.error}
                        </p>
                    </div>
                }
            </div>
        </Fragment>
    )
})

CustomFileInput.defaultProps = {
    onFileChange: () => { },
    type: "PNG or JPG"
}

export default CustomFileInput