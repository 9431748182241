/*
*   File : forgot-pin.js
*   Author : https://evoqins.com
*   Description : ForgotPIN Container
*   Version : 1.0.0
*/
// import packages
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom/dist";
import { toast } from "react-toastify";

// import components
import { CustomHeader } from "../../Components/Navigation";
import { CustomPINInput, CustomPhoneInput } from "../../Components/FormElements";
import { AuthSlider } from "../../Components/Slider";
import { PrimaryButton } from "../../Components/Buttons";
import { Icon } from "../../Components/Icon";

// import helper
import { useCountdown } from "../../Helper/helper";
import APIService from "../../Services/api-service";

const ForgotPIN = () => {

    const navigate = useNavigate();
    const { seconds, resetCountdown } = useCountdown(30);

    const [phone, setPhone] = useState("");
    const [phoneError, setPhoneError] = useState("");

    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");

    const [apiLoader, setApiLoader] = useState(false);

    const [loginStep, setLoginStep] = useState(1);

    useEffect(() => {
        if (loginStep === 2) {
            resetCountdown();
        }
        // eslint-disable-next-line
    }, [loginStep]);



    function _handlePhoneNumber(input_value) {
        setPhone(input_value);
        setPhoneError("");
    }

    function _handleOTP(input_value) {
        if (input_value.length <= 4) {
            setOtp(input_value);
        }
        setOtpError("");
    }

    async function _handleLogin() {
        let valid = true;
        if (phone === "") {
            setPhoneError("Phone number is required");
            valid = false;
        }
        if (phone !== "" && phone.length !== 10) {
            setPhoneError("Invalid phone number");
            valid = false;
        }
        if (valid === true) {
            _forgotPIN();
        }
    }


    function _submitOTP() {
        let valid = true;
        if (otp === "") {
            setOtpError("OTP is required");
            valid = false;
        }
        if (otp !== "" && otp.length !== 4) {
            setOtpError("Invalid OTP");
            valid = false;
        }
        if (valid === true) {
            _verifyOTP();
        }
    }


    function _handleResend() {
        resetCountdown();
        setOtp("");
        _forgotPIN();
    }


    // API -Forgot PIN
    const _forgotPIN = async () => {
        setApiLoader(true);
        const url = "/auth/forgot-pin"
        const request = {
            "country_code": "+91",
            "phone_number": phone,
        }

        await APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                toast.success("OTP sent successfully", {
                    type: "success",
                });
                setLoginStep(2);
            } else {
                toast.error(response.message, {
                    type: "error",
                });
            }
            setApiLoader(false);
        })
    }

    // API -verify forgot pin otp
    const _verifyOTP = async () => {
        setApiLoader(true);
        const url = "/auth/verify-phone-otp"
        const request = {
            "country_code": "+91",
            "phone_number": phone,
            "otp": otp
        }
        await APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                toast.success("OTP verified successfully", {
                    type: "success",
                });
                navigate("/configure-pin", {
                    state: {
                        temp_token: response.data.temp_token
                    }
                }, { replace: true })
            } else {
                toast.error(response.message, {
                    type: "error",
                });
                setTimeout(() => {
                    setOtp("");
                }, 500);
            }
            setApiLoader(false);
        })
    }


    return (
        <Fragment>
            <CustomHeader />
            <div className="padding-96px-top d-flex flex-column align-items-center justify-content-end">
                <div className="e-login-wrapper border-radius-32px">
                    <div className="row">
                        <div className="col-lg-5 col-md-5">
                            <AuthSlider />
                        </div>
                        <div className="col-lg-1 col-md-1">
                            <div className="e-login-seperator position-relative"></div>
                        </div>
                        <div className="col-lg-6 col-md-6 padding-66px-top">
                            {
                                loginStep === 1 ?
                                    <Fragment>
                                        <h3 className="color-dark-jungle-green mb-1 e-font-24 e-line-height-36 e-montserrat-semi-bold letter-spacing-minus2em">Forgot PIN</h3>
                                        <p className="color-blue-gray e-font-14 e-line-height-24 e-montserrat-regular letter-spacing-minus2em mb-3">Enter your phone number to reset PIN.</p>
                                        <CustomPhoneInput label="Phone number"
                                            postfix="*"
                                            placeholder="Enter phone number"
                                            value={phone}
                                            maxLength={10}
                                            error={phoneError}
                                            onSubmit={_handleLogin}
                                            handleChange={_handlePhoneNumber} />
                                        <PrimaryButton className="w-100 margin-32px-top padding-10px-tb"
                                            label="Get OTP"
                                            disabled={apiLoader}
                                            onPress={_handleLogin} />


                                    </Fragment>
                                    :
                                    <Fragment>
                                        <h3 className="color-dark-jungle-green mb-1 e-font-24 e-line-height-36 e-montserrat-semi-bold letter-spacing-minus2em">Verify OTP</h3>
                                        <p className="color-blue-gray e-font-14 e-line-height-24 e-montserrat-regular letter-spacing-minus2em mb-3">
                                            OTP Sent to <span className="color-charleston-green e-montserrat-semi-bold">+91 - {`${phone.slice(0, -3).replace(/\d/g, 'X')}${phone.slice(-3)}`}</span>
                                            <Icon icon="edit" size={20} className="ms-2"
                                                onClick={() => setLoginStep(1)} />
                                        </p>

                                        <CustomPINInput id="otp-input"
                                            pinString={otp}
                                            autoFocus={true}
                                            error={otpError}
                                            onSubmit={_submitOTP}
                                            handleChange={_handleOTP} />

                                        <p className="color-charcoal-blue e-montserrat-medium e-font-14 e-line-height-20 mt-4">Didn’t get OTP? <span className={`no-decoration color-rebecca-purple e-link ${seconds === 0 ? 'cursor-pointer' : 'disabled'}`} onClick={_handleResend}>{seconds === 0 ? 'Resend OTP' : ` Resend OTP in ${seconds < 10 ? `0${seconds}` : seconds}s`}</span></p>

                                        <PrimaryButton className="w-100 margin-32px-top padding-10px-tb"
                                            label="Verify & Proceed"
                                            disabled={apiLoader}
                                            onPress={_submitOTP} />
                                    </Fragment>

                            }

                        </div>
                    </div>
                </div>
                <div className="d-flex flex-wrap align-items-center mt-4 gap-10px padding-32px-bottom">
                    <p className="color-charcoal-gray e-font-16 e-line-height-28 e-montserrat-semi-bold mb-0">Download App</p>
                    <div className="d-flex align-items-center padding-10px-all gap-10px">
                        <Icon icon='ios-store'
                            size={28} />
                        <p className="color-charleston-green e-font-16 e-line-height-20 e-montserrat-semi-bold mb-0">AppStore</p>
                    </div>
                    <div className="d-flex align-items-center padding-10px-all ps-0 gap-10px">
                        <Icon icon='play-store'
                            size={28} />
                        <p className="color-charleston-green e-font-16 e-line-height-20 e-montserrat-semi-bold mb-0">PlayStore</p>
                    </div>

                </div>
            </div>

        </Fragment>
    )
}

export default ForgotPIN