/*
*   File : wealth.js
*   Author : https://evoqins.com
*   Description : Screen to show goal Investment for wealth
*   Version : 1.0.0
*/

// import packages
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom/dist";
import { toast } from "react-toastify";
import { Modal } from "bootstrap";

// import components
import { Header } from "../../Components/Header";
import { Breadcrumb, Loader, StepProgress } from "../../Components/Other";
import { CustomAmountInput, EditableSlider } from "../../Components/FormElements";
import { PrimaryButton } from "../../Components/Buttons";
import { GoalInformation, GoalInvest } from "./";
import { RiskKycConfirmationModal } from "../../Components/Modal";

// Services, helper
import { _getGoal } from "../../Helper/api";
import APIService from "../../Services/api-service";

const Wealth = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const moneyRequiredRef = useRef(null);

    const [goalDetail, setGoalDetail] = useState({});

    const [age, setAge] = useState(10);

    const [cost, setCost] = useState("");
    const [costError, setCostError] = useState("");

    const [returns, setReturns] = useState(10);

    const [configurationStep, setConfigurationStep] = useState(1);
    const [screenLoader, setScreenLoader] = useState(true);
    const [apiLoader, setApiLoader] = useState(false);
    const [locationData, setLocationData] = useState(null);
    const [riskKycCheck, setRiskKycCheck] = useState(false);

    useEffect(() => {
        if (location.state !== null) {
            _goalDetail(location.state.goal_id);
            setConfigurationStep(location.state.step);
            if (location.state.step === 3) {
                setLocationData(location.state);
            }
        }
    }, [location]);

    useEffect(() => {
        if (riskKycCheck === true) {
            const modal = new Modal(document.getElementById("risk-kyc-check"));
            modal.show();
        }
    }, [riskKycCheck]);

    // API - get goal detail
    const _goalDetail = (id) => {
        _getGoal(id)
            .then((response) => {
                if (response.is_configured === false) {
                    setAge(response.admin_configured.meta_data.sip_tenure.default);
                    setReturns(response.admin_configured.meta_data.returns.default);
                    setCost(response.admin_configured.meta_data.default_target);
                    setLocationData(null);
                } else {
                    if (response.user_configured.additional_data.tenure) {
                        setAge(response.user_configured.additional_data.tenure);
                    } else {
                        setAge(response.user_configured.tenure);
                    }
                    setReturns(response.user_configured.additional_data.returns);
                    if (response.user_configured.additional_data.target_value) {
                        setCost(response.user_configured.additional_data.target_value);
                    }
                }
                setGoalDetail(response);
                setScreenLoader(false);
            }).catch((error) => {
                toast.dismiss();
                toast.error(error, {
                    type: "error",
                });
            });
    }


    // navigate to home screen
    function _handleParentNavigate() {
        navigate("/");
    }

    // navigate to parent screen
    function _handleCurrentNavigate() {
        navigate("/milestone");
    }

    // handle age for dream home
    function _handleYear(slider_value) {
        setAge(slider_value);
    }

    // handle cost
    function _handleAccumulate(slider_value) {
        setCost(slider_value);
        setCostError("");
    }

    // handle returns
    function _handleReturns(slider_value) {
        setReturns(slider_value);
    }

    function _confirmRiskKyc(status) {
        setRiskKycCheck(status)
    }

    function _handleNavigateToScreen() {
        navigate("/risk-profile")
    }

    // handle submit
    function _submitInvestment() {
        let valid = true;
        let firstErrorRef = null;
        toast.dismiss();
        if (cost === "" || cost === 0) {
            setCostError("Enter the required amount");
            if (!firstErrorRef) {
                firstErrorRef = moneyRequiredRef;
            }
            valid = false;
        }
        if (age < goalDetail.admin_configured.meta_data.sip_tenure.min) {
            toast.error(`Target year should be greater than or equal to ${goalDetail.admin_configured.meta_data.sip_tenure.min} year`, {
                type: "error",
            });
            valid = false;
        }

        if (firstErrorRef) {
            firstErrorRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            firstErrorRef.current.querySelector('input').focus();
        }

        if (valid === true) {
            setApiLoader(true);
            _setWealth();
        }
    }

    const _setWealth = () => {
        const url = "/goal/set";
        const request = {
            goal_id: location.state.goal_id,
            tenure: age,
            target_value: cost,
            inflation: 0,
            additional_data: {
                returns: returns,
                target_value: cost,
                tenure: age,
            }
        };

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setConfigurationStep(2);
                navigate(location.pathname, {
                    state: {
                        goal_id: location.state.goal_id,
                        step: 2,
                        request_data: response.data
                    }
                });

            } else {
                if (response.data && response.data.risk_not_done === true) {
                    _confirmRiskKyc(true);
                } else {
                    toast.dismiss();
                    toast.error(response.message, {
                        type: "error",
                    });
                }
            }

            setApiLoader(false);
        })
    }

    // handle to go back previous step
    function _handleConfigurationStep(step) {
        navigate(location.pathname, {
            state: {
                goal_id: location.state.goal_id,
                step: step,
                request_data: location.state.request_data
            }
        })
    }

    return (

        screenLoader === true ?
            <Loader height="h-80vh" />
            :
            <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
                <div className="row ">
                    <div className="col-12 padding-144px-bottom">
                        <Header type={2} />
                        <div className="px-4 ">
                            <Breadcrumb mainPage="Home"
                                currentPage="Milestones"
                                childPage={goalDetail.admin_configured.name}
                                handleParentNavigate={_handleParentNavigate}
                                handleCurrentNavigate={_handleCurrentNavigate}
                                type={2} />


                            <div className="row pt-4 d-flex justify-content-center">
                                <div className="col-lg-6">
                                    {/* Step progress */}
                                    <StepProgress stepCompleted={configurationStep}
                                        gotToStep={_handleConfigurationStep} />

                                </div>

                            </div>
                            {
                                configurationStep === 1 ?
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-lg-6">
                                            <div className="mt-4 e-goal-bg border-radius-16px d-flex align-items-center p-3">
                                                <img src={goalDetail.admin_configured.image}
                                                    alt="goals"
                                                    width={65}
                                                    height={54} />
                                                <div className="padding-12px-left">
                                                    <h6 className="mb-1 color-eerie-black e-font-16 e-line-height-22 e-montserrat-semi-bold">{goalDetail.admin_configured.name}</h6>
                                                    <p className="mb-0 color-dim-gray e-font-14 e-line-height-20 e-montserrat-medium">{goalDetail.admin_configured.description}</p>
                                                </div>
                                            </div>

                                            <div ref={moneyRequiredRef}>
                                                <CustomAmountInput
                                                    value={cost}
                                                    error={costError}
                                                    postfix="*"
                                                    className="mt-4"
                                                    label="How much money do you want to accumulate?"
                                                    placeholder="5,00,000"
                                                    handleChange={_handleAccumulate} />
                                            </div>

                                            <EditableSlider label="When do you want to reach this goal?"
                                                className="mt-4"
                                                value={age}
                                                edit={true}
                                                right={true}
                                                handleChange={_handleYear}
                                                min={goalDetail.admin_configured.meta_data.sip_tenure.min}
                                                max={goalDetail.admin_configured.meta_data.sip_tenure.max}
                                                step={goalDetail.admin_configured.meta_data.sip_tenure.step}
                                                suffix={age === 1 ? "Yr" : "Yrs"}
                                                singularSuffix={goalDetail.admin_configured.meta_data.sip_tenure.min === 1 ? "Yr" : "Yrs"}
                                                pluralSuffix="Yrs" />


                                            <EditableSlider label="What returns do you expect?"
                                                className="mt-4"
                                                value={returns}
                                                edit={true}
                                                right={true}
                                                handleChange={_handleReturns}
                                                min={goalDetail.admin_configured.meta_data.returns.min}
                                                max={goalDetail.admin_configured.meta_data.returns.max}
                                                step={goalDetail.admin_configured.meta_data.returns.step}
                                                suffix="%"
                                                singularSuffix="%"
                                                pluralSuffix="%" />


                                            <div className="mt-5 border-top-1px border-bright-gray mb-4"></div>
                                            <div className="d-flex justify-content-center">
                                                <div className="col-lg-6 col-md-4 col-6">
                                                    <PrimaryButton label="Calculate" className="padding-10px-tb w-100"
                                                        showLoader={apiLoader}
                                                        disabled={apiLoader}
                                                        onPress={_submitInvestment}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    configurationStep === 2 ?
                                        <div className="row d-flex justify-content-center">
                                            <div className="col-lg-6">
                                                <GoalInformation short_description={"Recommended Corpus:"}
                                                    locationData={locationData}
                                                    text1="To make your dream of achievable"
                                                    text2="a reality, you'll need a corpus of" />
                                            </div>
                                        </div>
                                        :
                                        <div className="row ">
                                            <div className="col-xl-10 col-lg-11">
                                                <GoalInvest />
                                            </div>
                                        </div>
                            }
                        </div>

                    </div>
                </div>
                {
                    riskKycCheck === true && <RiskKycConfirmationModal
                        title="Complete Risk Profile"
                        content="Please complete your Risk Profile to start with an Investment."
                        closeModal={() => _confirmRiskKyc(false)}
                        handleConfirm={_handleNavigateToScreen} />
                }
            </div>
    )
}

export default Wealth;