/*
 *   File : phone-input.js
 *   Author : https://evoqins.com
 *   Description : Phone Input component
 *   Integrations : null
 *   Version : 1
*/

// import packages
import React, { useCallback, useRef } from 'react';

// import styles
import style from '../../Styles/Components/phone.module.scss';

const CustomPhoneInput = React.memo((props) => {

    const inputRef = useRef(null);

    const _handleChange = useCallback((event) => {
        const input_value = event.target.value;
        const indian_phone_regexr = /^[6-9]\d*$/;
        if (props.maxLength) {
            if (input_value.length <= props.maxLength) {
                if (indian_phone_regexr.test(input_value)) {
                    props.handleChange(input_value);
                } else {
                    if (input_value.length <= 1) {
                        props.handleChange("")
                    } else {
                        event.preventDefault();
                    }
                }
            }
        }
        //eslint-disable-next-line
    }, [props.handleChange, props.maxLength, props.pan, props.personName]);

    // Preventing non numeric characters if type is number 
    // Validating because Firefox and Safari don't support type number 
    const _validateNumber = (e) => {
        const key_code = e.keyCode || e.which;
        const is_ctrl_pressed = e.ctrlKey || e.metaKey; // Check if Ctrl key is pressed
        const allowed_key_codes = [9, 8, ...Array.from({ length: 10 }, (_, i) => 48 + i), ...Array.from({ length: 10 }, (_, i) => 96 + i), ...Array.from({ length: 4 }, (_, i) => 37 + i)];

        if (is_ctrl_pressed && [65, 67, 88, 86].includes(key_code)) {
            // Ctrl+A, Ctrl+C, Ctrl+X, or Ctrl+V
            return null;
        }

        if (!allowed_key_codes.includes(key_code)) {
            e.preventDefault();
        }
    };

    const _onKeyDown = (e) => {
        if (props.type === 'number') {
            _validateNumber(e);
            // Disable arrow up and down keys
            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                e.preventDefault();
            }
        }
        if (e.keyCode === 13) {
            props.onSubmit()
        }
    }

    function _handleWheel(event) {
        document.activeElement.blur();
    }


    return (
        <div className={`${props.className} ${style.e_input_wrapper} position-relative w-100`}>
            {/* label */}
            <label className={`e-montserrat-medium color-charcoal-blue e-font-14 e-line-height-20 cursor-text margin-6px-bottom`}
                htmlFor={props.label}>
                {props.label}
                <span className='color-deep-carmine-pink'>
                    {props.postfix}
                </span>
            </label>
            {/* Input box */}
            <div className='position-relative'>
                <input ref={inputRef}
                    value={props.value ? parseInt(props.value) : ''}
                    placeholder={props.placeholder}
                    id={props.id}
                    disabled={props.readOnly}
                    autoComplete={props.autoComplete}
                    type={props.type ? props.type : 'text'}
                    className={`${style.e_phone_input} ${props.inputClass} color-charleston-green e-montserrat-medium e-font-16 w-100 no-outline`}
                    onKeyDown={_onKeyDown}
                    onChange={_handleChange}
                    onWheel={_handleWheel}
                    required />
                <span className='position-absolute top-0 bottom-0 m-auto h-max-content left-14px py-1 border-right-1px border-silver-sand padding-10px-right color-eerie-black e-montserrat-medium e-font-14 e-line-height-20'>+91</span>
            </div>

            {/* Error text */}
            {
                props.error &&
                <div className='position-absolute d-flex align-items-center margin-6px-top '>
                    {/* <Icon icon="info-circle"
                        size={16}
                        className="me-1" /> */}
                    <p className='color-deep-carmine-pink  e-montserrat-medium e-font-12 e-line-height-16 mb-0 '>
                        {props.error}
                    </p>
                </div>
            }
        </div>
    )
})

CustomPhoneInput.defaultProps = {
    value: '',
    handleChange: () => { },
    className: '',
    error: null,
    inputClass: '',
    autoComplete: "off",
    onSubmit: () => { }
};

export default CustomPhoneInput;