/*
 *   File : search-funds.js
 *   Author URI : https://evoqins.com
 *   Description :Modal to  search and select funds
 *   Integrations : null
 *   Version : v1.1
 */

import { Fragment, useEffect, useState } from "react";


import { Icon } from "../Icon";
import { PrimaryButton } from "../Buttons";
import { EmptyScreen, Loader } from "../Other";


import style from '../../Styles/Components/select-fund.module.scss';
import Color from '../../Styles/color.module.scss';

import APIService from "../../Services/api-service";

// Search component
const SearchComponent = (props) => {

    function _onFocus() {
        if (props.onFocus) {
            props.onFocus();
        }
    }

    return (
        <div className="position-relative" onClick={_onFocus}>
            {
                props.prefix && <Icon icon="Search"
                    size={props.size}
                    className="position-absolute e-search-icon" />
            }

            <input value={props.inputState}
                placeholder={props.placeholder}
                type='text'
                readOnly={props.disabled}
                className={`color-black e-montserrat-medium e-font-14 e-line-height-20 w-100 no-outline  e-search-fund border-radius-12px  border-0 ${props.class}`}
                onChange={props.onChange}
                onBlur={props.onBlur ? props.onBlur : null} />

            {
                props.suffix && <Icon icon="right-arrow"
                    size={props.size}
                    color={Color.rebecca_purple}
                    className="position-absolute right-16px top-16px rotate-90deg" />
            }
            {
                props.close === true && props.inputState.length > 0 &&
                <Icon icon="close-btn"
                    size={20}
                    color={Color.black}
                    className="position-absolute  top-0 right-8px bottom-0 my-auto cursor-pointer"
                    onClick={props.clearSearch} />
            }
            {
                props.error &&
                <div className='position-absolute d-flex align-items-center margin-6px-top w-max-content'>
                    {/* <Icon icon="info-circle"
                        size={16}
                        className="me-1" /> */}
                    <p className='color-deep-carmine-pink  e-montserrat-medium e-font-12  e-line-height-16 mb-0 '>
                        {props.error}
                    </p>
                </div>
            }

        </div>
    );
}

const SearchFunds = (props) => {

    const [search, setSearch] = useState("");
    const [mfList, setMfList] = useState([]);
    const [fundLoader, setFundLoader] = useState(false);

    const [transferredFund, setTransferredFund] = useState(props.fund);

    const [returnsText, setReturnsText] = useState("");

    useEffect(() => {
        var my_modal = document.getElementById("search-funds");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.handleCloseModal();
        });
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        _searchFunds("");
        // eslint-disable-next-line
    }, []);

    // handle search 
    function _handleSearch(search_value) {
        setSearch(search_value);
        _searchFunds(search_value)
    }
    // API - search switch funds
    const _searchFunds = (query) => {
        setFundLoader(true)
        const url = props.url;

        const request = {
            mf_id: props.id,
            query: (query.length === 0) ? null : query,
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setMfList(response.data.funds);
                setReturnsText(response.data.returns_text);
            } else {
                setMfList([]);
            }
            setFundLoader(false);
        })
    }

    // handle chose selected fund to be switched
    function _handleChoseFund() {
        props.handleChooseFund({
            fund_name: transferredFund.name,
            id: transferredFund.mf_id,
        })
        _handleCloseModal();

    }

    // select fund to be transferred
    function _handleSelectFund(id) {
        setTransferredFund(id)
    }

    const _handleCloseModal = () => {
        document.getElementById("fund-select").dispatchEvent(new Event("click"));
        if (props.backdrop) {
            document.getElementById("search-funds").classList.remove("show");
            const backdrop = document.querySelector(".modal-backdrop.fade.show");
            if (backdrop) {
                backdrop.parentNode.removeChild(backdrop);
            }
            document.body.removeAttribute('style')
            document.body.removeAttribute('class')
            props.handleCloseDateModal();
        }
        else {
            props.handleCloseModal();
        }

    };
    return (
        <Fragment>
            <div className={`modal fade ${style.e_select_fund}`}
                id="search-funds"
                tabIndex="-1"
                aria-labelledby="search-funds"
                aria-hidden="true"
                data-bs-backdrop={props.backdrop}
                data-bs-keyboard="false">
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content pb-4 ${style.e_content} `}>
                        <div className="d-flex justify-content-between align-items-center pt-sm-4 pt-3 pb-3 px-sm-4 px-2">
                            <h5 className='e-font-16 mb-0 color-eerie-black e-montserrat-semi-bold e-line-height-24'>
                                {props.title}
                            </h5>
                            <Icon icon="close-btn"
                                size={24}
                                color={Color.eerie_black}
                                id="fund-select"
                                className="cursor-pointer"
                                onClick={_handleCloseModal} />
                        </div>
                        <Fragment>
                            <div className="">
                                <div className="px-sm-4 px-2">
                                    <SearchComponent placeholder="Search with fund name"
                                        size={16}
                                        prefix={true}
                                        disabled={false}
                                        close={true}
                                        inputState={search}
                                        class="padding-14px-tb padding-42px-lr"
                                        onChange={(e) => _handleSearch(e.target.value)}
                                        clearSearch={() => _handleSearch("")} />
                                </div>
                                <div className="border-bottom-1px border-chinese-white mt-2 px-sm-4 px-2">
                                    <div className="row gx-2 padding-12px-tb">
                                        <div className="col-9">
                                            <h6 className="e-font-14 e-xs-font-12 e-line-height-24 color-eerie-black e-montserrat-semi-bold mb-0">Fund name</h6>
                                        </div>
                                        <div className="col-3">
                                            <h6 className="e-font-14 e-xs-font-12 e-line-height-24 color-eerie-black e-montserrat-semi-bold mb-0">{returnsText}</h6>
                                        </div>
                                    </div>
                                </div>

                                {
                                    fundLoader === true ?
                                        <div className="h-286px d-flex justify-content-center align-items-center">
                                            <Loader size={40} />
                                        </div>

                                        :
                                        mfList.length === 0 ?
                                            <div className="h-286px d-flex flex-column">
                                                <EmptyScreen className="mx-4 my-auto h-auto"
                                                    title="No funds found"
                                                    type={1}
                                                    description="" />
                                            </div>
                                            :
                                            <div className={style.e_fund}>
                                                <div className="px-sm-4 ps-2 pe-0">
                                                    {
                                                        mfList.map((item, index) => {
                                                            return (
                                                                <div key={index} className={index > 0 ? "border-top-1px border-onyx" : ""} onClick={() => _handleSelectFund(item)}>
                                                                    <div className="row gx-2 align-items-sm-center align-items-start padding-18px-tb cursor-pointer">
                                                                        <div className="col-9">
                                                                            <div className="d-flex align-items-sm-center align-items-start gap-8px">
                                                                                <Icon icon={item.mf_id === transferredFund.mf_id ? "radio-checked" : "radio-unchecked"} size={20}
                                                                                    className="min-w-20px" />
                                                                                <div className="d-flex align-items-sm-center align-items-start gap-8px">

                                                                                    <img src={item.icon}
                                                                                        alt="goal"
                                                                                        width={36}
                                                                                        height={36}
                                                                                        className="box-shadow-black border-radius-6px d-sm-block d-none" />
                                                                                    <img src={item.icon}
                                                                                        alt="goal"
                                                                                        width={28}
                                                                                        height={28}
                                                                                        className="box-shadow-black border-radius-6px d-sm-none d-block" />

                                                                                    <div className="d-block">
                                                                                        <p className="e-montserrat-medium e-font-14 e-line-height-24 color-eerie-black mb-0">{item.name}</p>
                                                                                        <p className="e-montserrat-medium e-font-12 e-line-height-20 mb-0 color-outer-space">
                                                                                            <span class="e-type position-relative me-3">{item.asset_class}</span>{item.category}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>


                                                                            </div>

                                                                        </div>
                                                                        <div className="col-3 text-center">
                                                                            <span className={`e-font-14 e-line-height-20 e-montserrat-semi-bold ${item.returns_percentage > 0 ? "color-go-green" : item.returns_percentage < 0 ? "color-deep-carmine-pink" : "color-eerie-black"}`}> {item.returns_percentage_text}</span>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                }

                            </div>
                        </Fragment>
                        {
                            mfList.length > 0 ?
                                <div className="row justify-content-center px-sm-4 px-3">
                                    <div className="col-6">
                                        <PrimaryButton label="Confirm"
                                            className="w-100 mt-3 mx-auto"
                                            onPress={_handleChoseFund} />
                                    </div>
                                </div>
                                :
                                <div className="h-66px"></div>
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default SearchFunds