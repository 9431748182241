
/*
 *   File : risk-kyc-confirm.js
 *   Author URI : https://evoqins.com
 *   Description :Risk and Kyc Confirm modal 
 *   Integrations : null
 *   Version : v1.1
 */

// Packages
import { Fragment, memo, useEffect } from "react";

// Custom components
import { PrimaryButton, SecondaryButton } from "../Buttons";

// Custom styles
import style from "../../Styles/Components/fund-allocation.module.scss";


const RiskKycConfirmationModal = (props) => {

    useEffect(() => {
        var my_modal = document.getElementById("risk-kyc-check");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });
        //eslint-disable-next-line
    }, []);


    return (
        <Fragment>
            <div className={`modal fade ${style.e_confirm_modal}`}
                id="risk-kyc-check"
                tabIndex="-1"
                aria-labelledby="risk-kyc-check"
                aria-hidden="true"
                data-bs-backdrop="static"
                data-bs-keyboard="false" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content text-center padding-20px-lr py-3 ${style.e_content} `}>

                        <h5 className="e-font-18 e-line-height-28 e-montserrat-semi-bold color-eerie-black my-3">
                            {props.title}
                        </h5>

                        <p className="e-montserrat-medium e-font-16 e-line-height-24 color-charcoal-gray mb-0 ">
                            {props.content}
                        </p>

                        <div className="row pb-2">
                            <div className="col-6">
                                <SecondaryButton label="Cancel"
                                    cancel="modal"
                                    className="w-100 padding-12px-tb mt-4" />
                            </div>
                            <div className="col-6">
                                <PrimaryButton label={"Continue"}
                                    cancel="modal"
                                    showLoader={false}
                                    className="w-100 padding-12px-tb mt-4"
                                    onPress={props.handleConfirm} />
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </Fragment>
    )
}

RiskKycConfirmationModal.defaultProps = {
    closeModal: () => { },
    handleConfirm: () => { },
}

export default memo(RiskKycConfirmationModal)