
/*
 *   File : video-preview.js
 *   Author URI : https://evoqins.com
 *   Description : Modal to play video
 *   Integrations : null
 *   Version : v1.1
 */

// Packages
import React, { Fragment, useEffect } from "react";

// Custom components

// Custom styles
import style from "../../Styles/Components/fund-allocation.module.scss";

// services
import { Icon } from "../Icon";


const VideoPreviewModal = React.memo((props) => {

    useEffect(() => {
        var my_modal = document.getElementById("video-preview");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });
        //eslint-disable-next-line
    }, []);

    const generateNonce = () => {
        const array = new Uint32Array(10);
        window.crypto.getRandomValues(array);
        return array.join("");
    };

    const nonce = generateNonce();

    return (
        <Fragment>
            <div className={`modal fade ${style.e_preview_modal}`}
                id="video-preview"
                tabIndex="-1"
                aria-labelledby="video-preview"
                aria-hidden="true"
                data-bs-backdrop="static"
                data-bs-keyboard="false" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog} `}>
                    <div className={`modal-content text-center padding-12px-lr overflow-hidden  ${style.e_content}`}>
                        <Icon icon="close"
                            size={24}
                            id="close-modal"
                            data-bs-dismiss="modal"
                            className='cursor-pointer position-absolute right-16px top-16px' />
                        <Fragment>
                            <video src={props.url}
                                autoPlay
                                alt="success"
                                controls
                                muted
                                draggable={false}
                                disablePictureInPicture
                                width={props.size}
                                height={props.height}
                                data-nonce={nonce}
                                className="mx-auto py-5 object-fit-contain w-100" />
                        </Fragment>
                    </div>
                </div>
            </div>
        </Fragment>
    )
})

VideoPreviewModal.defaultProps = {
    closeModal: () => { },
    handleNavigate: () => { }
}

export default VideoPreviewModal