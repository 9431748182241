/*
*   File : view-tat.js
*   Author : https://evoqins.com
*   Description : View turn around time
*   Version : 1.0.0
*/

import { Fragment } from "react"
import { Breadcrumb } from "../../Components/Other";
import { Header } from "../../Components/Header";
import { useNavigate } from "react-router-dom";


const INVEST_DATA = [
    {
        label: "Equity Funds",
        value: "T+2"
    },
    {
        label: "Debt & Liquid funds",
        value: "T+1"
    },
    {
        label: "FoF & International",
        value: "T+2"
    }
]

const REDEEM_DATA = [
    {
        label: "Equity Funds",
        value: "T+2"
    },
    {
        label: "Debt & Liquid funds",
        value: "T+1"
    },
    {
        label: "FoF",
        value: "T+3"
    },
    {
        label: "International",
        value: "T+5"
    }
]

const ViewTAT = () => {
    const navigate = useNavigate();

    function _handleNavigate() {
        navigate("/");
    }


    return (
        <Fragment>
            <div className="bg-white border-radius-tl-40px border-radius-bl-40px">
                <Header type={2} />
                <div className="row ">
                    <div className="col-lg-11 pe-lg-0 padding-60px-bottom">
                        <div className="px-4">
                            <Breadcrumb mainPage="Home"
                                currentPage="Mutual Fund Category TAT"
                                handleNavigate={_handleNavigate}
                                type={1} />


                            <div className="mt-4 pt-md-3 pt-0">

                                <div className="row">
                                    <div className="col-lg-8">
                                        <h6 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-eerie-black mb-sm-4 mb-3">Purchase, Add. Purchase, STP, Switch </h6>
                                        <div className="border-radius-12px border-light-periwinkle border-1px overflow-hidden">
                                            <div className="e-bg-light-lavender px-4">
                                                <div className="row">
                                                    <div className="col-8">
                                                        <h6 className="e-font-12 e-line-height-18 e-montserrat-semi-bold color-rebecca-purple padding-12px-tb mb-0"> Fund category</h6>
                                                    </div>
                                                    <div className="col-4">
                                                        <h6 className="e-font-12 e-line-height-18 e-montserrat-semi-bold color-rebecca-purple  padding-12px-tb mb-0"> TAT</h6>
                                                    </div>
                                                </div>

                                            </div>
                                            {
                                                INVEST_DATA.map((item, index) => {
                                                    return (
                                                        <div key={index} className="bg-white px-4 border-top-1px border-light-periwinkle">
                                                            <div className="row">
                                                                <div className="col-8">
                                                                    <h6 className="e-font-16 e-line-height-20 e-montserrat-medium color-dark-jungle-green border-right-1px border-light-periwinkle  py-sm-4 py-3 mb-0">
                                                                        {item.label}
                                                                    </h6>
                                                                </div>
                                                                <div className="col-4">
                                                                    <h6 className="e-font-16 e-line-height-20 e-montserrat-medium color-dark-jungle-green py-sm-4 py-3 mb-0">
                                                                        {item.value}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )
                                                })
                                            }
                                        </div>

                                        <h6 className="e-font-16 e-line-height-24 e-montserrat-semi-bold color-eerie-black mt-sm-4 mt-3 mb-sm-4 mb-3">Redemption, SWP</h6>
                                        <div className="border-radius-12px border-light-periwinkle border-1px overflow-hidden">
                                            <div className="e-bg-light-lavender px-4">
                                                <div className="row">
                                                    <div className="col-8">
                                                        <h6 className="e-font-12 e-line-height-18 e-montserrat-semi-bold color-rebecca-purple padding-12px-tb mb-0"> Fund category</h6>
                                                    </div>
                                                    <div className="col-4">
                                                        <h6 className="e-font-12 e-line-height-18 e-montserrat-semi-bold color-rebecca-purple  padding-12px-tb mb-0"> TAT</h6>
                                                    </div>
                                                </div>

                                            </div>
                                            {
                                                REDEEM_DATA.map((item, index) => {
                                                    return (
                                                        <div key={index} className="bg-white px-4 border-top-1px border-light-periwinkle">
                                                            <div className="row">
                                                                <div className="col-8">
                                                                    <h6 className="e-font-16 e-line-height-20 e-montserrat-medium color-dark-jungle-green border-right-1px border-light-periwinkle  py-sm-4 py-3 mb-0">
                                                                        {item.label}
                                                                    </h6>
                                                                </div>
                                                                <div className="col-4">
                                                                    <h6 className="e-font-16 e-line-height-20 e-montserrat-medium color-dark-jungle-green py-sm-4 py-3 mb-0">
                                                                        {item.value}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ViewTAT