
/*
 *   File : risk-score
 *   Author URI : https://evoqins.com
 *   Description : Modal to submit risk assessment
 *   Integrations : null
 *   Version : v1.1
 */

// Packages
import React, { Fragment, useEffect } from "react";

// Custom styles
import style from "../../Styles/Components/risk-score.module.scss";

const RiskScoreModal = React.memo((props) => {

    useEffect(() => {
        var my_modal = document.getElementById("risk-score");
        my_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal();
        });
        //eslint-disable-next-line
    }, []);

    return (
        <Fragment>
            <div className={`modal fade ${style.e_risk_score}`}
                id="risk-score"
                tabIndex="-1"
                aria-labelledby="risk-score"
                aria-hidden="true"
                data-bs-backdrop="static"
                data-bs-keyboard="false" >
                <div className={`modal-dialog position-relative modal-dialog-centered ${style.e_dialog}`}>
                    <div className={`modal-content text-center padding-12px-lr ${style.e_content} overflow-hidden`}>
                        <span id="close-modal"
                            className="d-none"
                            data-bs-dismiss="modal" ></span>
                        <Fragment>
                            <img src={require("../../Assets/Images/risk-assessment/riskscore_loader.gif")}
                                alt="success"
                                draggable={false}
                                width={300}
                                className="m-auto object-fit-contain" />
                        </Fragment>
                    </div>
                </div>
            </div>
        </Fragment>
    )
})

RiskScoreModal.defaultProps = {
    closeModal: () => { },
    handleNavigate: () => { }
}

export default RiskScoreModal